import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Logotipo from "./../../static/images/Logotipo.svg";
import logout from "./../../static/images/logout.svg";
import Modulos from "./Modulos";
import { Squash as Hamburger } from "hamburger-react";
import Slide from "react-reveal/Slide";
import { baseURL } from "../../App";
import { Zoom } from "react-reveal";

export default function Navbar({ user, setModule, module, toggle, setToggle, setUser, socket }) {
  const [menu, setMenu] = useState(user.rol.menu[0] ? user.rol.menu[0] : []);
  useEffect(() => setToggle(true), [menu]);
  return (
    <>
      <nav className="navbar">
        <div className="menu">
          <div
            className="row hcenter"
            style={{ width: 70, marginTop: 15, marginBottom: 10 }}
            onClick={() => {
              setToggle(false);
              setModule(null);
            }}
          >
            <img
              src={Logotipo}
              alt="inicio"
              width={40}
              className={"pointier"}
            />
          </div>

          {user.rol.menu.map((_menu) => (
            <div
              key={_menu.name}
              className={`row hcenter vcenter name_item_menu pointier ${
                _menu == menu ? "active" : ""
              }`}
              onClick={() => {
                setToggle(true);
                setMenu(_menu);
              }}
            >
              <div
                className={`item_menu row hcenter vcenter pointier ${
                  _menu == menu ? "active" : ""
                }`}
              >
                <img
                  src={`${baseURL}${_menu.icon}`}
                  alt="icon"
                  className="white"
                  style={{ width: 30 }}
                />
              </div>

              <div className={`name_menu f_small ${toggle ? "active" : ""}`}>
                {toggle ? _menu.name : ""}{" "}
              </div>
            </div>
          ))}
          <div
            className="row hcenter absolute"
            style={{ width: 70, marginTop: 15, marginBottom: 15, bottom:0 }}
            onClick={() => {
              setToggle(false);
              setModule(null);
              setUser(null)
              //socket.close()
            }}
          >
            <img
              src={logout}
              alt="salir"
              width={40}
              className={"pointier"}
            />
          </div>
        </div>

        <Slide left when={toggle}>
          <Modulos
            menu={menu}
            setModule={setModule}
            module={module}
            toggle={toggle}
          />
        </Slide>
      </nav>
      <Slide right when={toggle} collapse>
        <div className="block pointier" onClick={() => setToggle(false)}></div>
      </Slide>
    </>
  );
}
