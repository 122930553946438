import Header from "./Organism/Header";
import { useState, useEffect } from "react";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import metas from "./../static/icons/MetaIcon.svg";
import files from "./../static/icons/files.svg";
import certificaciones from "./../static/icons/certificadoICON.svg";
import equipos2 from "./../static/icons/equipoICON.svg";
import dasboard from "./../static/icons/dashICON.svg";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-step-progress/dist/index.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-step-progress/dist/index.css";
import { pdfjs } from 'react-pdf';
import Bienvenido from "./Atoms/Bienvenido";
import Dashboard from "./Modules/Conocimiento/Dashboard/inedex";
import Menu from "./Organism/Menu"
import AppDescription from "./Organism/AppDescription";
import Certificaciones from "./Modules/Conocimiento/Certificaciones";
import Metas from "./Modules/Conocimiento/Metas";
import Equipo from "./Modules/Conocimiento/Equipo";
import Procesos from "./Modules/Conocimiento/Procesos/index"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default function AdminConcimientos({ setUser, user, Post }) {
  const menus = [
    {
      menu: "Equipo",
      name: "Equipos colaborativos de conocimiento",
      svg: equipos2,
      component: () => {return(<Equipo />)},
      description:
        "A continuación se muestra una tabla con la lista completa de colaboradores, su información de contacto y el status de su evaluación. Haga clic en el en un colaborador para consultar su evaluación de desempeño y su plan de acción en curso. También puede realizar una búsqueda filtrada por nombre y equipo en el bloque izquierdo.",
    },
    {
      menu: "Certificaciones",
      name: "Certificaciones",
      svg: certificaciones,
      component: () => { return (<Certificaciones />) },
      description:
        "La siguiente tabla muestra una recopilación de las certificaciones que están desarrollando los colaboradores. Utilice el bloque de la izquierda para filtrar las certificaciones por categoría. Gestione el detalle de colaboradores asignados haciendo clic en el número de personas asignadas.",
    },
    {
      menu: "Dashboard",
      name: "Dashboard",
      svg: dasboard,
      component: () => { return (<Dashboard />) },
      description:
        "En esta sección encontrarás un resumen detallado de toda la plataforma, desde el desempeño por equipos y colaboradores hasta las metas programadas por el departamento de conocimiento y su estado actual. Navegue a través de las siguientes pestañas y realice búsquedas históricas con el filtro que aprece en cada pestaña.",
    },
    {
      menu: "Metas",
      name: "Metas",
      svg: metas,
      component: () => { return (<Metas />) },
      description:
        "La siguiente tabla muestra una recopilación de las certificaciones que están desarrollando los colaboradores. Utilice el bloque de la izquierda para filtrar las certificaciones por categoría. Gestione el detalle de colaboradores asignados haciendo clic en el número de personas asignadas",
    },
    {
      menu: "Procesos",
      name: "Procesos",
      svg: files,
      component: () => { return (<Procesos />) },
      description:"La siguiente tabla muestra a los grupos de acceso al contenido de los procesos."
    },
  ];
  const [app, setApp] = useState(menus[0]);

  useEffect(() => {
  }, [app]);


  return (

    <div className="container">
      <div className="w100">
        <Header setUser={setUser} />
        <div className="row pad20 wrap">
          <Bienvenido user={user} />
          <Menu setApp={setApp} menus={menus} currentMenu={app} />
          <AppDescription app={app} />
          {app.component()}
        </div>
      </div>
    </div>
  );
}
