import Logotipo from "./../../static/images/Logotipo.svg";
import { Icon } from "@iconify/react";
export default function Header({ setUser }) {
    return (
        <div className="row w100 pad10 bar hcenter vcenter  ">
            <div className="mxw1000px row vcenter hspace">
                <img
                    src={Logotipo}
                    alt="inicio"
                    width={40}
                    className={"pointier"}
                />
                <Icon
                    icon={"material-symbols:logout-rounded"}
                    style={{ fontSize: 40, color: "#fff" }}
                    className={"pointier"}
                    onClick={() => setUser(null)}
                />
            </div>
        </div>
    )
}