
export default function AppDescription({ app }) {
    return (
        <>
            <div className="row w100 hcenter mtop25">
                <div className="mxw1000px row vcenter">
                    <h3 className="fontSizeMd ">{app.name}</h3>
                </div>
            </div>

            <div className="row w100 hcenter mtop25">
                <div className="mxw1000px row vcenter">
                    <p className="mxw800px">{app.description}</p>
                </div>
            </div>
        </>
    );
}
