import Container from "../../../../Atoms/Container"
import Chart from "react-apexcharts";
import { Card } from 'primereact/card';
import { useState } from "react";
import { ServicesDashboard } from "../Services";
import { useEffect } from "react";
import Loader from "../../../../Atoms/Loader";
export default function DashColaborador() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const [state1, setState1] = useState({})
    const [state2, setState2] = useState({
        options: {
            chart: {
                id: "basic-bar",
                events: {
                    dataPointSelection: function (e, chart, opts) {
                        console.log(e, chart, opts);
                    }
                },
            },
            xaxis: {
                categories: []
            },

            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '75%',
                    borderRadius: 5,
                }
            },
            colors: []
        },

        series: []
    })

    const [selectedSeries, setSelectedSeries] = useState();
    const [listSeries, setListSeries] = useState([])
    useEffect(() => {
        const isSelected = listSeries.find((e) => {
            return e.uuid == selectedSeries.uuid
        });
        if (isSelected) {
            var ueva = listSeries.filter((e) => e.uuid != selectedSeries.uuid)
            console.log(ueva);
            setListSeries(ueva);
        } else if (selectedSeries) {
            var ueva2 = [...listSeries, selectedSeries]
            console.log(ueva2);
            setListSeries(ueva2);
        }
    }, [selectedSeries])

    useEffect(() => {
      console.log(state2);
    }, [state2])

    useEffect(() => {
        if(listSeries.length>0)
        setState2({
            options: {
                chart: {
                    id: "basic-bar",
                    events: {
                        dataPointSelection: function (e, chart, opts) {
                            console.log(e, chart, opts);
                        }
                    },
                },
                xaxis: {
                    categories: Object.keys(data[0].calificaciones)
                },

                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '75%',
                        borderRadius: 5,
                    }
                },
                colors: listSeries.map((equipo) => equipo.color)
            },

            series: listSeries.map((equipo) => {
                return {
                    name: equipo?.nombre,
                    data: Object.values(equipo?.calificaciones)
                }
            })
        })
    }, [listSeries])

    const handleSelectSerie = (equipo) => {
        setSelectedSeries(equipo)
    };

    useEffect(() => {
        var series = data.map((equipo) => {
            return ({
                'name': equipo.nombre,
                'data': [equipo.total]
            })
        })
        if (data.length > 0) {
            setState1({
                options: {
                    chart: {
                        id: "basic-bar",
                        events: {
                            dataPointSelection: function (e, chart, opts) {
                                const equipo = data[opts.seriesIndex];
                                handleSelectSerie({ ...equipo });
                            }
                        },
                    },
                    xaxis: {
                        categories: ['Promedio']
                    },
                    dataLabels: {
                        enabled: true,
                       
                        style: {
                          colors: ['#fff']
                        },
                        formatter: function (val, opt) {
                           
                          return data[opt.seriesIndex].nombre + ":  " + val
                        },
                        offsetX: 0,
                        dropShadow: {
                          enabled: true
                        }
                      },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '100%',
                            borderRadius: 5,
                           
                        }
                    },
                    colors: data.map((equipo) => equipo.color),

                },

                series: series
            })
            
            setLoading(false)
        }

    }, [data])

    useEffect(() => {
        console.log("sdsd");
        setLoading(true)
        ServicesDashboard.getColaboradorDashboard((response) => {
            response.then((data) => {
                console.log(data);
                setData(data);
                
            });
        })
    }, [])

    return (
        <Container>
            <div className="row w100 hcenter wrap">
                {loading ? <Loader /> : <> <Card style={{margin:5}}>
                    <div className="row w100 hcenter">
                        Colaboradores
                    </div>
                    <Chart
                        height={'800'}
                        options={state1.options}
                        series={state1.series}
                        type="bar"
                        width="650"
               
                    />
                </Card>
                    <Card style={{margin:5}}>
                    <div className="row w100 hcenter">
                        Calificación por colaborador
                    </div>
                        <Chart
                            options={state2?.options}
                            series={state2?.series}
                            type="bar"
                            width="650"
                        />
                    </Card></>}

            </div>
        </Container>
    )
}