import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Actions from "../../../Organism/Actions";
import ModalForm from "../../../Organism/ModalForm";
import { Services } from "../Services";
import { ServicesCertificaciones } from "./Services";
import CertificacionesContent from "./Componets/Certificaciones";

export default function Certificaciones() {
    const [dinamicValues, setDibamicValues] = useState({'integrantesUpdate':[]})
    const opciones = [
        {
            name: "Certificaciones",
            path: "Certificaciones",
            onClick: (opcion) => {
                setCurrentOption(opcion)
            },
            print: "Certificaciones",
            component: ()=><CertificacionesContent />,
            tipo: "tabla",
            seccion: "1",
            modales:[
                {
                    name: "Certificaciones",
                    path: "Certificaciones",
                    onClick: (opcion) => {
                        setModalesAbiertos([...modalesAbiertos, opcion.name]);
                    },
                    print: "Agregar certificación",
                    add: true,
                    isModal: true,
                    component: null,
                    formData: {
                        body: {
                            clave:"",
                            categoria:"",
                            nombre:"",
                            linkDeCurso:"",
                            linkDeExamen:"",
                            isCurso:"",
                            meses_duracion:0,
                           
                        },
                        endpoint: '',
                        fields: [
                            {
                                type: 'textField',
                                name: 'clave',
                                text: 'Clave',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'textField',
                                name: 'nombre',
                                text: 'Nombre',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'select',
                                name: 'categoria',
                                text: 'Categoría',
                                value: [],
                                method: '/CategoriaDeCertificaciones/getCategorias/',
                                getByEndPont: true,
                                onClick: () => { }
                            },
                            {
                                type: 'textField',
                                name: 'linkDeCurso',
                                text: 'Link del curso',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'textField',
                                name: 'linkDeExamen',
                                text: 'Link del examen',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'switchField',
                                name: 'isCurso',
                                text: 'Es un curso',
                                value: false,
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'numberField',
                                name: 'meses_duracion',
                                text: 'Meses duración',
                                showButtons:true,
                                buttonLayout:'horizontal',
                                step:1,
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'button',
                                name: 'submit',
                                text: 'Crear certificación',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: (body) => {
                                    ServicesCertificaciones.create_certificacion(body, (response) => {
                                        toast.success("Recurso creado")
                                        setModalesAbiertos(modalesAbiertos.filter((modal_a) => modal_a !== "Certificaciones"));
                                    }
                                    )
                                }
                            }
                        ],
                    },
                    tipo: "tabla",
                    seccion: "1",
                }
            ]
        }
    ]
    const [currentOption, setCurrentOption] = useState(opciones[0]);
    const [modalesAbiertos, setModalesAbiertos] = useState([]);
    
    useEffect(()=>{
        for(const modal_no_abierto of currentOption.modales.filter((modal)=>!modalesAbiertos.includes(modal.name))){
            for(const field of modal_no_abierto.formData.fields){
                var dinamicCP = dinamicValues
                if(dinamicValues[field.name]){
                    dinamicCP[field.name] = field.value
                }
                setDibamicValues(dinamicCP)
            }
        }
    },[modalesAbiertos])
    useEffect(() => {}, [currentOption])
    return (
        <>
            <Actions currentOption={currentOption} opciones={opciones} modales={currentOption.modales} />
            {currentOption.component()}
            {currentOption.modales.map((modal) => (
                <Dialog
                    key={modal.name}
                    header={`${modal.add ? "Agregar " : ""}${modal.name}`}
                    visible={modalesAbiertos.includes(modal.name)}
                    onHide={() => { setModalesAbiertos(modalesAbiertos.filter((modal_a) => modal_a !== modal.name));}}
                    style={{ width: "40vw" }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                >
                    <ModalForm modal={modal} dinamicValues={dinamicValues} />
                </Dialog>
            ))}
        </>
    )
}