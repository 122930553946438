import { useEffect, useState } from "react";
import useFiltroDatos from "../../../../../Hooks/useFiltroDatos";
import { Services } from "../../Services";
import SearchData from "../../../../Organism/Search";
import Reload from "../../../../Atoms/Reload";
import { ThreeCircles } from "react-loader-spinner";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import Estadisticas from "./Estadisticas";
import { Checkbox } from "primereact/checkbox";
import ListasEquipos from "../Dialogs/ListaEquipos";
import PlanAccion from "./PlanAccion";
const RenderComparar = ({ row, setUsuariosSeleccionados, usuariosS }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (checked) setUsuariosSeleccionados([...usuariosS, row]);
    else
      setUsuariosSeleccionados(usuariosS.filter((usuario) => usuario != row));
  }, [checked]);
  return (
    <td className="column" key={row.uuid}>
      <Checkbox
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
      ></Checkbox>
    </td>
  );
};
export default function EquipoComponent() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [empleadoDetalle, setEmpleadoDetalle] = useState(null);
  const [usuariosSeleccionados, setusuariosSeleccionados] = useState([]);
  const [calificacion, setCalificacion] = useState(null);
  const { datosFiltrados, handleChangeFiltro, setDatosIniciales } =
    useFiltroDatos(data);
  const [showListas, setShhowListas] = useState(false);
  useEffect(() => {
    setDatosIniciales(data);
    if (empleadoDetalle)
      setEmpleadoDetalle(
        data.find((empleados) => empleados.uuid === empleadoDetalle.uuid)
      );
    if (usuariosSeleccionados.length > 0)
      setusuariosSeleccionados(
        data.filter((empleados) => {
          return usuariosSeleccionados.find(
            (usuario) => usuario.uuid === empleados.uuid
          );
        })
      );
  }, [data]);

  useEffect(() => { }, [usuariosSeleccionados]);

  const getData = () => {
    setLoader(true);
    Services.getAllMiembros((response) => {
      response.then((data) => {
        setData(data);
        setLoader(false);
      });
    });
  }

  useEffect(getData, []);

  const renderColumn = (key, content) => (
    <td className="column" key={key}>
      {content}
    </td>
  );

  const renderProyectosColumn = (row) =>
    renderColumn(
      "numProyectos",
      row.numProyectos !== 0 ? (
        <Button
          onClick={() => {
            setEmpleadoDetalle(row);
            setShhowListas(true);
          }}
          label={row.numProyectos}
          className="btn_calificar"
          severity="help"
          text
          raised
        />
      ) : (
        <Button
          label="S/E"
          className="btn_calificar"
          severity="danger"
          outlined
        />
      )
    );

  const renderCertificacionesColumn = (row) =>
    renderColumn(
      "numCertificaciones",
      row.numCertificaciones !== 0 ? (
        <Button
          label={row.numCertificaciones}
          onClick={() => {
            setEmpleadoDetalle(row);
            setShhowListas(true);
          }}
          className="btn_calificar"
          severity="info"
          text
          raised
        />
      ) : (
        <Button
          label="S/C"
          className="btn_calificar"
          severity="danger"
          outlined
        />
      )
    );

  const renderEvaluacionColumn = (row) =>
    renderColumn(
      "evaluacion",
      row.evaluado ? (
        <ProgressBar
          aria-label="Status"
          style={{ color: "#2B2B2B" }}
          onClick={() => setCalificacion(row)}
          value={row.evaluacion}
        />
      ) : (
        <button className="btn_calificar" onClick={() => setCalificacion(row)}>
          Calificar
        </button>
      )
    );

  const renderTableHeader = () => (
    <thead className="table_header encab2">
      <tr className="table_header white bold hspace vcenter fontSizeSm">
        <th className="column"></th>
        <th className="column">Nombre</th>
        <th className="column">Correo</th>
        <th className="column">Proy.</th>
        <th className="column">Cert.</th>
        <th className="column">Evaluacion</th>
      </tr>
    </thead>
  );

  const renderTableRow = (row, index) => (
    <tr
      className="row_table pointier"
      key={index}
      onClick={() => {
        setEmpleadoDetalle(row);
      }}
    >
      <RenderComparar
        row={row}
        setUsuariosSeleccionados={setusuariosSeleccionados}
        usuariosS={usuariosSeleccionados}
      />
      {renderColumn("nombre", row.nombre)}
      {renderColumn("correo", row.correo)}
      {renderProyectosColumn(row)}
      {renderCertificacionesColumn(row)}
      {renderEvaluacionColumn(row)}
    </tr>
  );

  const renderTableBody = (datosFiltrados) => (
    <tbody className="tbodyTablaSeleccion">
      {datosFiltrados.map(renderTableRow)}
    </tbody>
  );

  const renderLoader = () => (
    <div className="row w100 hcenter pad15 vcenter">
      <ThreeCircles
        height="43"
        width="43"
        color="#0061FF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );

  const renderTableContainer = (datosFiltrados) => (
    <div className="mxw1000px row vcenter hspace menTam">
      <div className="table-container">
        <table className="w100" cellPadding={0} cellSpacing={0}>
          {renderTableHeader()}
          {renderTableBody(datosFiltrados)}
        </table>
      </div>
    </div>
  );

  return (
    <div className="w100 row hcenter">
      <div className="mxw1000px row hcenter wrap">
        <div className="row w100 mtop15">
          <SearchData handleChangeFiltro={handleChangeFiltro} />
          <Reload onClick={getData} />
        </div>
        {loader ? renderLoader() : renderTableContainer(datosFiltrados)}
        {empleadoDetalle && (
          <>
            <Estadisticas
              userDetalle={empleadoDetalle}
              usuariosSeleccionados={usuariosSeleccionados}
              data={data}
              setData={setData}
            />
            <PlanAccion empleadoDetalle={empleadoDetalle} setData={setData} />
          </>
        )}
        <ListasEquipos
          show={showListas}
          onHide={() => {
            setShhowListas(false);
          }}
          empleadoDetalle={empleadoDetalle}
        />
      </div>
    </div>
  );
}
