import { useState, useEffect } from "react";
import { axios_instance, baseURL, Post } from "../../App";
import { Icon } from "@iconify/react";
import { ThreeCircles } from "react-loader-spinner";
import { Zoom } from "react-reveal";

import { Tag } from 'primereact/tag';
        

import { Button } from "primereact/button";

export default function ModalHistorialDisp({ setToggleModal, uuid, setUUIDDocumento,entidad, setToggleModalDocumento, user }) {
  const [rowsHistorial, setRowsHistorial] = useState(null);
  const [dataCompu, setDataCompu] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    params.append("uuid", uuid);
    Post(
      `/${entidad.path_historial}/get_by_uuid/`,
      (response) => {
        setDataCompu(response.data);
        setLoader(false);
        setRowsHistorial(response.data.historial);
      },
      (error) => {
        setLoader(false);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [uuid]);

  const getProductSeverity = (rowData) => {
        switch (rowData) {
            case 'Asignado':
                return 'success';

            case 'Sin asignar':
                return 'danger';

            case 'Asignado antiguo':
                  return 'warning';

            default:
                return null;
        }
    };

const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData} severity={getProductSeverity(rowData)}></Tag>;
};

  //Mauricio
  function redirigirALink(uuid) {
    window.open (`${baseURL}/historial-computadoras/mostrar-html/${uuid}`);
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier">
            <Icon
              icon={"ic:twotone-close"}
              onClick={() => setToggleModal(false)}
            />
          </div>
          <div
            className="row hcenter w100 pad20 vcenter"
            style={{ background: "#000", color: "#fff" }}
          >
            <h2 className="row vcenter">Historial</h2>
          </div>
        </div>
        {loader ? (
          <div className="box_c w100 absolute">
            <div className="table_header pad15 row fontSizeSmr">
              <span className="white w100 row">
                <Icon icon={"ph:dots-three-outline-fill"} />
              </span>
              <span className="white w100 row">
                <Icon icon={"ph:dots-three-outline-fill"} />
              </span>
              <span className="white w100 row">
                <Icon icon={"ph:dots-three-outline-fill"} />
              </span>
              <span className="white w100 row">
                <Icon icon={"ph:dots-three-outline-fill"} />
              </span>
            </div>
            <div className="row w100 hcenter pad15 vcenter">
              <ThreeCircles
                height="43"
                width="43"
                color="#0061FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            </div>
          </div>
        ) : (rowsHistorial != null) & (rowsHistorial.length > 0) ? (
        <div className="row w100 wrap hcenter fontSizeSm">
            <div className="container_modal w100 row pad20 wrap vcenter">
            <div
            className="row w100 wrap hcenter vcenter"
            style={{ maxHeight: 500, overflowY: "scroll" }}
            >
            <span className="p-float-label">
              <div className="row w100 hcenter mtop25">
                <div className="mxw1000px row vcenter">
                  <h3 className="fontSizeMd ">Marca</h3>
                </div>
              </div>
              <div className="row w100 hcenter ">
                <div className="mxw1000px row vcenter">
                  <p className="mxw800px">{dataCompu.marca}</p>
                </div>
              </div>
            </span>
            <span className="p-float-label">
              <div className="row w100 hcenter mtop25">
                <div className="mxw1000px row vcenter">
                  <h3 className="fontSizeMd ">Modelo</h3>
                </div>
              </div>
              <div className="row w100 hcenter ">
                <div className="mxw1000px row vcenter">
                  <p className="mxw800px">{dataCompu.modelo}</p>
                </div>
              </div>
            </span>
            <span className="p-float-label">
              <div className="row w100 hcenter mtop25">
                <div className="mxw1000px row vcenter">
                  <h3 className="fontSizeMd ">Numero de serie</h3>
                </div>
              </div>
              <div className="row w100 hcenter ">
                <div className="mxw1000px row vcenter">
                  <p className="mxw800px">{dataCompu.numero_serie}</p>
                </div>
              </div>
            </span>
            <table
              className="box_c w100 "
              cellPadding={0}
              cellSpacing={0}
            >
              <thead className="table_header pad15">
                <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                  {Object.keys(rowsHistorial[0]).map(
                    (key) =>
                      key != "uuid" &&
                      key != "Responsiva" &&
                      key != "options" &&
                      key != "ready" && (
                        <td className="column" key={key}>
                          {key}
                        </td>
                      )
                  )}
                  <td className="column"></td>
                </tr>
              </thead>
              <Zoom cascade>
                <tbody>
                  {rowsHistorial.map((row) => (
                    <tr className="row_table">
                      {Object.keys(row).map(
                        (key) =>
                          key != "uuid" &&
                          key != "Responsiva" &&
                          key != "options" &&
                          key != "ready" &&
                          (key == "Estatus de asignacion" ? (
                            // <td className={`column`}>
                            //   <span
                            //     className={`${row[key].toLowerCase()}`}
                            //     style={{
                            //       maxWidth: 100,
                            //       margin: 0,
                            //       margin: "auto",
                            //     }}
                            //   >
                            //     {row[key]}
                            //   </span>{" "}
                            // </td>

                            <td className={`column`}>{statusBodyTemplate(row[key])} </td>
                          ) : key == "Estado" ? (
                            <td className={`column row`}>
                              <span className={`${row[key].toLowerCase()}`}>
                                {row[key]}
                              </span>{" "}
                            </td>
                          ) : key == "Contrato" ? (
                            <td className={`column`}>
                              {row[key] == "Sin contrato" ? (
                                row[key]
                              ) : (
                                <a
                                  href={`https://portal.eks-solutions.com:8000${row[key]}`}
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                >
                                  Abrir
                                </a>
                              )}
                            </td>
                          ) : (
                            <td className={`column`}>{row[key]} </td>
                          ))
                      )}
                      <td className="column">
                        <div className="row">
                          {row["Responsiva"] != "" && user.rol.name != "Tecnico" ? (
                            <Button
                              icon="pi pi-external-link"
                              rounded
                              text
                              aria-label="Filter"
                              // onClick={()=>{redirigirALink(row.uuid)}}
                              onClick={()=>{
                                setUUIDDocumento(row.uuid);
                                setToggleModalDocumento(true)
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Zoom>
            </table>
            </div>
            </div>
          </div>
        ) : (
          <div className="table_header pad15 row vcenter hcenter white bold fontSizeSm w100">
            Sin registros
          </div>
        )}
      </div>
    </>
  );
}
