import { useEffect } from "react";
import { useState } from "react";
import "./../../static/scss/input.scss";


export default function SearchData({handleChangeFiltro}) {
    const [search,setSearch] = useState('')
    useEffect(()=>{
        handleChangeFiltro(search)
    },[search])
    return (
        <div className="row w100 hcenter">
            <div className="row w100">
                <div className="row col_2">
                    <input
                        type="text"
                        className="input field search mxw600px"
                        name="search_row"
                        id="search_row"
                        placeholder="Buscando"
                        value={search}
                        style={{ height: 32 }}
                        onChange={(e) => { setSearch(e.target.value) 
                        }}
                    />
                </div>
            </div>
        </div>
    );
}