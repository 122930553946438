import { Icon } from "@iconify/react";
import { baseURL } from "../../App";
import Logotipo from "./../../static/images/Logotipo.svg"
export default function Modulos({ menu, setModule, module, toggle }) {
  return (
    <div className={`submenu ${toggle?"active":""}`}>
      <h4 className="white lighter f_small">{menu.name} </h4>
      <div className="serach mtop15 btn row vcenter">
        <label htmlFor="serach_module" className="row hcenter vcenter">
          <Icon icon="akar-icons:search" style={{ marginRight: 7 }} />
        </label>
        <input
          type="text"
          placeholder="Buscar"
          id="serach_module"
          name="serach_module"
          className="input white"
        />
      </div>
      <div className="modules_container mtop15">
        {menu.modules.map((_module) => (
          <div key={_module.name} className={`module pointier f_small f_dark row vcenter ${_module==module?"active":""}`} onClick={()=>(setModule(_module))} ><img src={`${baseURL}${_module.icon}`} alt="" style={{width:16, marginRight:5}} /> {_module.name} </div>
        ))}
      </div>
    </div>
  );
}
