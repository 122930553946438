import { Icon } from "@iconify/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect } from "react";
import { baseURL } from "../../../../App";
import { toast } from "react-toastify";

export default function ModalCreate({ setShowModalCreate }) {
  const [client_name, setClientName] = useState('');
  const [client_phone, setClientPhone] = useState('');
  const [client_company, setClientCompany] = useState('');
  const [client_email, setClientEmail] = useState('');
  const [employee, setEmployeeState] = useState('');
  const [employee_phone, setEmployeePhone] = useState('');
  const [employee_email, setEmployeeEmail] = useState('');
  const [priority, setPriority] = useState(null);
  const [incidence_category, setIncidenceCategory] = useState(null);
  const [incidence_description, setIncidenceDecription] = useState('');
  const [incidence_impact, setIncidenceImpact] = useState('');

  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [auth, setAuth] = useState([]);
  
  const priorities = [
    { value: 1, label: "Alta"}, 
    { value: 2, label: "Media"}, 
    { value: 3, label: "Baja"},
  ];
  const incidence_categories = [
    { value: 1, label: "Disponibilidad"}, 
    { value: 2, label: "Profesionalismo"}, 
    { value: 3, label: "Calidad de trabajo"},
    { value: 4, label: "Atrasos"},
  ];

  useEffect(() => {
    fetch(`${baseURL}/users/get/employees`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setEmployees(data);
    });
  },[]);

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");

    fetch(`${baseURL}/auth/`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json' }
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setAuth(data["user"])
      setClientName(data["user"]["nombre"])
      setClientPhone(data["user"]["phone"])
      setClientEmail(data["user"]["email"])
    });
  },[]);

  useEffect(() => {
    fetch(`${baseURL}/cliente/index`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setCompanies(data)
    });
  },[]);

  function setEmployee(value)
  {
    setEmployeeState(value);
    setEmployeePhone(value.phone);
    setEmployeeEmail(value.email);
  }

  function create()
  {
    fetch(`${baseURL}/incidents/create/`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_name : client_name,
        client_phone : client_phone,
        client_company: client_company.uuid,
        client_email : client_email,
        user : employee.uuid,
        category: incidence_category,
        priority: priority,
        description: incidence_description,
        impact: incidence_impact
      })
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      if(data.code == 1){
        toast.success("Se guardo la incidencia con éxito", { toastId: "incident-success", theme: "dark", position: toast.POSITION.TOP_RIGHT, });
        setShowModalCreate(false);
      }else{
        toast.warning("Verifica que tu formulario este completo", { toastId: "incident-warning", theme: "dark", position: toast.POSITION.TOP_RIGHT, });
      }
    });
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier">
            <Icon icon={"ic:twotone-close"} onClick={() => setShowModalCreate(false)} />
          </div>
          <div className="row hcenter w100 pad20 vcenter" style={{ background: "#000", color: "#fff" }} >
            <h2 className="row vcenter">Registrar Incidencia</h2>
          </div>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Información del cliente </p>
          <div className="line w25 white vcenter row"></div>
        </div>
        
        <div className="row w100 wrap fontSizeSm mb-5px">
          <span className="p-float-label flex-1" key={"group-client-name"}>
            <InputText id={"client_name"} value={client_name} onChange={(e) => setClientName(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Nombre del Cliente"}>{"Nombre del Cliente"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-client-phone"}>
            <InputText id={"client_phone"} value={client_phone} onChange={(e) => setClientPhone(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Teléfono"}>{"Teléfono"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-client-company-name"}>
            <Dropdown id={"client_company"} value={client_company} onChange={(e) => setClientCompany(e.value)} options={companies} 
            optionLabel="name" filter  placeholder="Selecciona una empresa" style={{ width: "100%", height: 40 }}/>
            <label htmlFor={"Empresa"}>{"Empresa"}</label>
          </span>
          
          <div class="flex-break"></div>

          <span className="p-float-label flex-1" key={"group-client-email"}>
            <InputText id={"client_email"} keyfilter="email" value={client_email} onChange={(e) => setClientEmail(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Correo Electrónico"}>{"Correo Electrónico"}</label>
          </span>

          <div class="p-float-label flex-1"></div>
          <div class="p-float-label flex-1"></div>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Información del empleado </p>
          <div className="line w25 white vcenter row"></div>
        </div>
        
        <div className="row w100 wrap fontSizeSm mb-5px">
          <span className="p-float-label flex-1" key={"group-employee-name"}>
            <Dropdown id={"employee"} value={employee} onChange={(e) => setEmployee(e.value)} options={employees} 
              optionLabel="fullname" placeholder="Selecciona un empleado" filter style={{ width: "100%", height: 40 }}/>
            <label htmlFor={"Nombre del empleado"}>{"Nombre del empleado"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-employee-phone"}>
            <InputText id={"employee_phone"} value={employee_phone} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Teléfono"}>{"Teléfono"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-employee-email"} style={ { marginLeft: "auto" } }>
            <InputText id={"employee_email"} value={employee_email} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Correo Electrónico"}>{"Correo Electrónico"}</label>
          </span>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Detalle de la incidencia </p>
          <div className="line w25 white vcenter row"></div>
        </div>

        <div className="row w100 wrap fontSizeSm">
          <span className="p-float-label h40px flex-1" key={"group-category-incidence"}>
            <Dropdown value={incidence_category} onChange={(e) => setIncidenceCategory(e.value)} options={incidence_categories} optionLabel="label" 
              placeholder="Select a City" className="w-full" style={{ width: "100%", height: 40 }}/>
            <label htmlFor={"Categoría de la Incidencia"}>{"Categoría de la Incidencia"}</label>
          </span>
          
          <span className="p-float-label flex-1" key={"group-incidence-description"}>
            <InputTextarea autoResize id={"incidence_description"} value={incidence_description} style={{ width: "100%", height: 40 }} rows={5} cols={30} onChange={(e) => setIncidenceDecription(e.target.value)} />
            <label htmlFor={"Descripción de la Incidencia"}>{"Descripción de la Incidencia"}</label>
          </span>

          <div class="p-float-label flex-1"></div>

          <div class="flex-break"></div>

          <span className="p-float-label h40px flex-1" key={"group-priority"}>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorities} optionLabel="label" 
              placeholder="Select a City" className="w-full" style={{ width: "100%", height: 40 }}/>
            <label htmlFor={"Prioridad"}>{"Prioridad"}</label>
          </span>          


          <span className="p-float-label flex-1" key={"group-incidence-impact"}>
            <InputTextarea autoResize id={"incidence_impact"} value={incidence_impact} style={{ width: "100%", height: 40 }} rows={5} cols={30} onChange={(e) => setIncidenceImpact(e.target.value)} />
            <label htmlFor={"Impacto de la Incidencia"}>{"Impacto de la Incidencia"}</label>
          </span>

          <div class="p-float-label flex-1"></div>
        </div>

        <div className="row hcenter w100 pad20 vcenter">
          <Button label="Abrir incidencia" severity="success" size="large" className="pad10" onClick={(e) => create()}/>
        </div>
      </div>
    </>
  );
}
