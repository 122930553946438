export function tieneValoresNulosOVacios(objeto) {
    for (const propiedad in objeto) {
        if (objeto.hasOwnProperty(propiedad)) {
            const valor = objeto[propiedad];

            // Verificar si el valor es nulo o vacío
            if (valor === null || valor === undefined || valor === '') {
                return true; // Se encontró un valor nulo o vacío
            }

            // Verificar recursivamente si el valor es un objeto
            if (typeof valor === 'object' && tieneValoresNulosOVacios(valor)) {
                return true; // Se encontró un valor nulo o vacío en el objeto anidado
            }
        }
    }
    return false; // No se encontraron valores nulos o vacíos
}

export function toUTCDate(date) {
    if (!date)
        return date;
    if (typeof date === "string")
        date = new Date(date);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function toStringDate(date) {
    if (!date || typeof date === "string")
        return date;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return(formattedDate); // Output: 2024-01-01
}