import React, { useState } from 'react';
import { useEffect } from 'react';

const useFiltroDatos = () => {
    const [datosiniciales, setDatosIniciales] = useState([])
    const [datosFiltrados, setDatosFiltrados] = useState([]);
    function filter(filtro) {
        if(filtro.length==0)
            return datosiniciales
        const filtroNormalizado = filtro.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        const f = [];
        for (const row of datosiniciales) {
            for (const value of Object.values(row)) {
                if (typeof value === 'string') {
                    const valueNormalizado = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    if (valueNormalizado.includes(filtroNormalizado)  && !f.includes(row))
                        f.push(row);
                }
            }
        }
        return f;
    }

    useEffect(() => {
        setDatosFiltrados(datosiniciales);
    }, [datosiniciales])

    const handleChangeFiltro = (value) => {
        setDatosFiltrados(filter(value));
    };

    return {
        datosFiltrados,
        setDatosIniciales,
        handleChangeFiltro,
    };
};

export default useFiltroDatos;
