import { Icon } from "@iconify/react";
import { useState, useEffect,useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import RowPeriodo from "../../snippets/Formulario/RowPeriodo";
import RowPeriodoInterno from "../../snippets/Formulario/RowPeriodoInterno";
import { ToastContainer, toast } from "react-toastify";
import { Post , baseURL } from "../../../App";
import Period from "./Period";

export default function ModalActivities({toggleModalReview,setToggleModalReview,uuid,Post,user}) {
    
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    setLoader(true);
    loadData();
  }, [uuid]);

  function loadData()
  {
    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    params.append("uuid", uuid);
    Post(`hoja_actividades/get/pmo`, (response) => {
        setLoader(false);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
      },
      false,
      "Obteniendo datos",
      params
    );
  }
  function excelDownload()
  {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    fetch(`${baseURL}/hoja_actividades/pmo/excel/download`, { method: 'POST', headers: headers, body: JSON.stringify({"uuid" : uuid}) })
    .then(async res => ({
        filename: uuid,
        blob: await res.blob()
    }))
    .then(resObj => {
      const newBlob = new Blob([resObj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, resObj.filename);
      } else {
          const objUrl = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = objUrl;
          link.download = data.employee_name.replace(/\s+/g,"_")+'-'+data.start.split('-')[0]+"_"+data.start.split('-')[1];
          link.click()
          setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
      }
    })
    .catch((error) => {
        console.log('DOWNLOAD ERROR', error);
    });
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier"  >
            <Icon icon={"ic:outline-file-download"} onClick={() => excelDownload()} />
            <Icon icon={"ic:twotone-close"} onClick={() => setToggleModalReview(false)}/>
          </div>
          <div className="row hcenter w100 pad20 vcenter" style={{ background: "#000", color: "#fff" }}>
            <h2 className="row vcenter">Revisión</h2>
          </div>
        </div>
        {loader ? (
          <div className="row w100 hcenter pad15 vcenter">
            <ThreeCircles
              height="43"
              width="43"
              color="#0061FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="row w100 wrap pad20 hcenter fontSizeSm">
            <div className="row col_2 pad10 fontSizeMd">
              <span className="bold">Nombre:&nbsp;</span> {data.employee_name}
            </div>
            <div className="row col_2 pad10 fontSizeMd">
              <span className="bold">Cliente:&nbsp;</span> {data.client_name}
            </div>
            <div className="row col_2 pad10 fontSizeMd">
              <span className="bold">Asignación:&nbsp;</span> {data.assignament_name}
            </div>
            <div className="row col_2 pad10 fontSizeMd">
              <span className="bold">Fecha Inicio:&nbsp;</span> {data.start} <br />
              <span className="bold">Fecha Corte:&nbsp;</span> {data.end}
            </div>

            <div className="pad20 row w100 hcenter" style={{ maxHeight: 500 }}>
              <div className="row hspace wrap w100" style={{ overflow: "scroll" }} >
                <div className="row w100 wrap"> 

                  {data.periods.map((p) => (
                    <Period period={p} loadData={loadData}/>
                  ))}

                </div>
              </div>
            </div>
            
          </div>
        )}
      </div>
    </>
  );
}
