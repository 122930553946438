import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { toast } from "react-toastify";
import { baseURL } from "../../../App";
import { InputTextarea } from "primereact/inputtextarea";
import {default as ModalShow} from "../Support/ModalShow";

export default function Period({period, loadData}){
  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({isExpanded});
  const [typeForm, setTypeForm] = useState(1); // 1 - Activities, 2 - findings
  const [discovery, setDiscovery] = useState(period.discovery);

  const [showModalSupport, setShowModalSupport] = useState(false);
  const [support_uui, setSupportUuid] = useState(null);

  const [hours, setHours] = useState(0);


  useEffect(() => {
    var h = period.activities.reduce((n, {hours}) => n + hours, 0)
    setHours(h);
  }, [period]);

  function updateActivities(uuid, status, period = null)
  {
    var activities_uuids = [uuid];
    if(period != null){
      activities_uuids = period.activities.map((a) => a.uuid)
    }

    fetch(`${baseURL}/hoja_actividades/pmo/activities/update`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'activities' : activities_uuids, 'status' : status }),
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      loadData();
      toast.success("Actividad actualizada", { theme: 'dark'});
    });
    
  }

  function updateDiscovery()
  {
    fetch(`${baseURL}/hoja_actividades/pmo/period/discovery_update`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'period' : period.uuid, 'discovery' : discovery }),
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      loadData();
      toast.success("Hallazgo actualizado", { theme: 'dark'});
    });
  }

  return (
    <>
    <div className={`relative ${showModalSupport ? "" : "hidden"}`} style={{width: '100%'}}>
    { showModalSupport && ( <ModalShow setShowModalShow={setShowModalSupport} uuid={support_uui} extras={'modal-mini'}/> )}
    </div>

    <div className="row w100 hcenter mtop_7px">
      <div className="w100  row vcenter hspace">
        <div className="box_c w100">

          <div className={`date_row w100 white hspace vcenter pointier ${isExpanded ? "active" : ""}`}
            { ...getToggleProps({ style: { display: "flex" }, onClick: () => { setExpanded((x) => !x);}}) } >
            <div className="text-center">
              <span className="bold">Semana {period.number}</span>
            </div>
            <div className="text-center">
              <span className="bold">Fase de Revisión: <br />{period.review_phase}</span>
            </div>
            <div className="text-center">
              <span className="bold">Porcentaje validación: {period.validation_percentage}%</span>
            </div>
            <div className="text-center">
              <span className="bold">Entrega Semanal: {period.week_status}</span>
            </div>
            <div className="text-center hover_green">
              <Icon className="row center dropw fontSizeLg" icon="mdi:file-find" onClick={() => { setTypeForm((x) => x == 1 ? 2 : 1); setExpanded((x) => false)} } />
            </div>
            <div className="text-center fontSizeMd">
              {hours}
              <Icon icon="tabler:clock-hour-9" />
            </div>
            <div className={`icon_box row vcenter ${isExpanded ? "active" : ""}`}>
              <Icon className="row center dropw fontSizeLg" icon={"ri:arrow-drop-down-line"} />
            </div>
          </div>

          <div className="pad10 w100" {...getCollapseProps()}>
            <div className="row w100 hcenter">
              <table className={`w100  ${typeForm == 1 ? "" : "hidden"}`} cellPadding={0} cellSpacing={0}>
                <tr className="bold">
                  <td className="column ">Tipo</td>
                  <td className="column ">Subtipo</td>
                  <td className="column ">Actividad</td>
                  {/* {type == 'project' ? (<td className="column ">Módulo</td>) : null} */}
                  <td className="column ">Horas</td>
                  <td className="column ">Tipo de hora</td>
                  <td className="column " style={{ textAlign: 'center' }}>
                    Acciones
                    <br />
                    <Icon icon="material-symbols:check-box" className={`pointier hover_green icon-color-second`} 
                        style={{ fontSize: 25 }} onClick={() => updateActivities(null, 1, period)}/>
                    <Icon icon="mdi:cross-circle" className={`pointier hover_green icon-color-second`} 
                      style={{ fontSize: 25 }} onClick={() => updateActivities(null, 2, period)}/>
                  </td>
                </tr>

                {period.activities.map((activity) => (
                  <tr className="row_table">
                    <td className="column-5">{activity.type}</td>
                    <td className="column-5">{activity.category}</td>
                    <td className="column-5">{activity.activity}</td>
                    <td className="column-5">{activity.hours}</td>
                    <td className="column-5">{activity.type_hour}</td>
                    <td className="column-5" style={{ textAlign: 'center' }}>
                      { activity.support_uuid && (<Icon icon={"material-symbols:description-outline"} className="fontSizeLg hover_green pointier mright10"
                        onClick={() => { setSupportUuid(activity.support_uuid); setShowModalSupport(true); }} />) }
                      <Icon icon="material-symbols:check-box" className={`pointier hover_green icon-color-second ${activity.status == 1 ? "active":""} `} 
                        style={{ fontSize: 30 }} onClick={() => updateActivities(activity.uuid, 1)}/>
                      <Icon icon="mdi:cross-circle" className={`pointier hover_green icon-color-second ${activity.status == 2 ? "active":""} `} 
                        style={{ fontSize: 30 }} onClick={() => updateActivities(activity.uuid, 2)}/>
                    </td>
                  </tr>
                ))}
              </table>
              <div className={`w100 ${typeForm == 2 ? "" : "hidden"}`}>
                <label htmlFor={"discoveries"} className="bold">Hallazgos</label>
                <InputTextarea id="discoveries" value={discovery} 
                  onChange={(e) => setDiscovery(e.target.value)}rows={5} cols={30} style={{ width: "100%"}} />

                <div className="row w100 pad10 hcenter">
                  <div className="btn green active pointier bg_green fontSizeSm bold" onClick={() => updateDiscovery()} >
                    Guardar
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    </>
  )
}