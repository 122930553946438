import { Icon } from "@iconify/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useState, useEffect, useRef } from "react";
import { baseURL } from "../../../App";

export default function ModalUpdate({ setShowModalShow, uuid, extras }) {
  const [id2, setId2] = useState('');
  const [module, setModule] = useState('');
  const [origin, setOrigin] = useState('');
  const [typeError, setTypeError] = useState('');
  const [error, setError] = useState('');
  const [solution, setSolution] = useState('');
  const [previous, setPrevious] = useState('');
  const [solution_description, setSolutionDescription] = useState({value:null, label:""});

  useEffect(() => {
    if(!uuid) return;

    fetch(`${baseURL}/supports/${uuid}`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setSupport(data)
    });
  },[uuid]);

  function setSupport(value)
  {
    setId2(value.id2);
    setModule(value.module);
    setOrigin(value.origin);
    setTypeError(value.typeError);
    setError(value.error);
    setSolution(value.solution);
    setPrevious(value.previous);
    setSolutionDescription(value.solution_description);
  }

  async function pdfDownload()
  {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    fetch(`${baseURL}/supports/${uuid}/pdf/download/`, { method: 'POST', headers: headers, })
    .then(async res => ({
        filename: id2,
        blob: await res.blob()
    }))
    .then(resObj => {
      console.log(resObj);
      // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
      const newBlob = new Blob([resObj.blob], { type: 'application/pdf',  });
      // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, resObj.filename);
      } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click()
          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
      }
    })
    .catch((error) => {
        console.log('DOWNLOAD ERROR', error);
    });
  }

  return (
    <>
      <div className={"modal-background"}></div>

        <div className={`modal-large ${extras ? extras : ""}`}id={"modalIncidentEdit"}>
          <div className="relative">
            <div className="close absolute pointier">
              <Icon icon={"ic:outline-file-download"} onClick={() => pdfDownload()} />
              <Icon icon={"ic:twotone-close"} onClick={() => setShowModalShow(false)} />
            </div>
            <div className="row hcenter w100 pad20 vcenter" style={{ background: "#000", color: "#fff" }} >
              <h2 className="row vcenter">Bitácora Soporte {id2}</h2>
            </div>
          </div>

          <div className="row w100 wrap fontSizeSm pad10 mtop25">
            <div className="row col_2 pad10 fontSizeMd m-0">
              <span className="p-float-label flex-1" key={"module"}>
                <InputText id={"module"} value={module} onChange={(e) => setModule(e.target.value)} style={{ width: "100%", height: "40px" }} />
                <label>Módulo</label>
              </span>
            </div>

            <div className="row col_2 pad10 fontSizeMd m-0">
              <span className="p-float-label flex-1" key={"origin"}>
                <InputText id="origin" value={origin} onChange={(e) => setOrigin(e.target.value)} style={{ width: "100%", height: "40px" }} />
                <label>Origen</label>
              </span>
            </div>

            <div className="row support-col-text pad10 fontSizeMd m-0">
              <span className="my-auto">Tipo de Error:</span>
            </div>
            <div className="row support-col-input pad10 fontSizeMd m-0">
              <InputTextarea autoResize id="type_error" value={typeError} onChange={(e) => setTypeError(e.target.value)} style={{ width: "100%", height: "60px" }} rows={5} cols={30}/>
            </div>

            <div className="row support-col-text pad10 fontSizeMd m-0">
              <span className="my-auto">Error:</span>
            </div>
            <div className="row support-col-input pad10 fontSizeMd m-0">
              <InputTextarea autoResize id="error" value={error} onChange={(e) => setError(e.target.value)} style={{ width: "100%", height: "60px" }} rows={5} cols={30} />
            </div>

            <div className="row support-col-text pad10 fontSizeMd m-0">
              <span className="my-auto">Solución:</span>
            </div>
            <div className="row support-col-input pad10 fontSizeMd m-0">
              <InputTextarea autoResize id="solution_description" value={solution_description} style={{ width: "100%", height: "60px" }} rows={5} cols={30} onChange={(e) => setSolutionDescription(e.target.value) }/>
            </div>

            <div className="row support-col-text pad10 fontSizeMd m-0">
              <span className="my-auto">Anterior Código / Configuración:</span>
            </div>
            <div className="row support-col-input pad10 fontSizeMd m-0">
              <InputTextarea autoResize id="previus" value={previous} style={{ width: "100%", height: "60px" }} rows={5} cols={30} onChange={(e) => setPrevious(e.target.value) }/>
            </div>

            <div className="row support-col-text pad10 fontSizeMd m-0">
              <span className="my-auto">Solución Código / Configuración:</span>
            </div>
            <div className="row support-col-input pad10 fontSizeMd m-0">
              <InputTextarea autoResize id="solution" value={solution} style={{ width: "100%", height: "60px" }} rows={5} cols={30} onChange={(e) => setSolution(e.target.value) }/>
            </div>
        </div>
      </div>
    </>
  );
}