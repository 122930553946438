import { useEffect, useState } from "react";
import useFiltroDatos from "../../../../../Hooks/useFiltroDatos";
import { Services } from "../Services";
import SearchData from "../../../../Organism/Search";
import Reload from "../../../../Atoms/Reload";
import { ThreeCircles } from "react-loader-spinner";
import Loader from "../../../../Atoms/Loader";
import GrupoDetail from "../Dialogs/GrupoDetail";
export default function Grupo() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [grupo,setGrupo]=useState()
    const [showDetalles, setShowDetalles] = useState(false);
    const { datosFiltrados, handleChangeFiltro, setDatosIniciales } =
        useFiltroDatos(data);
    useEffect(() => {
        setDatosIniciales(data);
    }, [data]);
    const getData = () => {
        setLoader(true);
        Services.post({}, (response) => {
            response.then((data) => {
                setData(data);
                setLoader(false);
            });
        }, "/accessGroup/getAll/");
    }
    useEffect(getData, []);

    const renderAction = (key, content) => (
        <td className="column hcenter vcenter text-centrado" key={key}>
            <div
                onClick={() => {
                    setGrupo(content);
                    setShowDetalles(true);
                }}
                style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
            >
                <i className="pi pi-eye" style={{ fontSize: "2rem" }}></i>
            </div>
        </td>
    );

    const renderColumn = (key, content) => (
        <td className="column text-centrado" key={key}>
            {content}
        </td>
    );

    const renderTableHeader = () => (
        <thead className="table_header encab2">
            <tr className="table_header white bold hspace vcenter fontSizeSm">
                <th className="column">Nombre</th>
                <th className="column">N. Archivos</th>
                <th className="column">N. Integrantes</th>
                <th className="column"></th>
            </tr>
        </thead>
    );

    const renderTableRow = (row, index) => (
        <tr
            className="row_table pointier"
            key={index}
        >
            {renderColumn("name", row.name)}
            {renderColumn("nArchivos", row.files.length)}
            {renderColumn("nIntegrantes", row.integrantes.length)}
            {renderAction("actions", row)}
        </tr>
    );

    const renderTableBody = (datosFiltrados) => (
        <tbody className="tbodyTablaSeleccion">
            {datosFiltrados.map(renderTableRow)}
        </tbody>
    );

    const renderTableContainer = (datosFiltrados) => (
        <div className="mxw1000px row vcenter hspace menTam">
            <div className="table-container">
                <table className="w100" cellPadding={0} cellSpacing={0}>
                    {renderTableHeader()}
                    {renderTableBody(datosFiltrados)}
                </table>
            </div>
        </div>
    );

    return (
        <div className="w100 row hcenter">
            <div className="mxw1000px row hcenter wrap">
                <div className="row w100 mtop15">
                    <SearchData handleChangeFiltro={handleChangeFiltro} />
                    <Reload onClick={getData} />
                </div>
                {loader ? <Loader /> : renderTableContainer(datosFiltrados)}
            </div>
            <GrupoDetail grupo={grupo} show={showDetalles}  onHide={() => {
                    setShowDetalles(false);
                }}  />
        </div>
    );
}