import { Icon } from "@iconify/react";
import ModalCreate from "./Components/ModalCreate";
import { useState, useEffect } from "react";
import ModalEdit from "../../snippets/ModalEdit";
import { baseURL } from "../../../App";
import ModalUpdate from "./Components/ModalEdit";
import Actions from "../../snippets/Module/Actions";

export default function Index() {
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [editUuid, setEditUuid] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsOrigin, setItemsOrigin] = useState([]);
  const [search, setSearch] = useState("");

  const [sId, setSId] = useState("");
  const [sDate, setSDate] = useState("");
  const [sClient, setSClient] = useState("");
  const [sEmployee, setSEmployee] = useState("");
  const [sStatus, setSStatus] = useState("");
  const [sPriority, setSPriority] = useState("");
  const [sRequester, setSRequester] = useState("");

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!showModalCreate) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
      loadItems()
    }else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [showModalCreate]);
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!showModalUpdate) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
      loadItems()
    }else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [showModalUpdate]);
  useEffect(() => {
    loadItems();
  },[]);
  useEffect(() => {
    filter()
  },[sId,sDate,sClient,sEmployee,sStatus,sPriority,sRequester]);

  useEffect(() => {
    if (search.length > 0) filter();
    else setItems(itemsOrigin);
  }, [search]);

  function filter() {
    var arraySe = [];
    var rowm = itemsOrigin
    for (let row in rowm) {
      // typeof(Object.values(rowm[row])[value]) == "string" &&
      let values = Object.values(rowm[row]);
      if (
        values[1].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sId.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[2].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sDate.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[3].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sClient.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[4].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sEmployee.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[6].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sStatus.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[7].label.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sPriority.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[8].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(sRequester.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase())
      ){
        if (!arraySe.includes(rowm[row])) arraySe.push(rowm[row]);
      }
    }
    setItems(arraySe);
  }

  function loadItems()
  {
    fetch(`${baseURL}/incidents/`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setItems(data);
      setItemsOrigin(data);
    });
  }
  return (
    <div className="w100" style={{ marginBottom: "20px" }}>
      <div className="row w100 hcenter">
        <div className="mxw1000px row vcenter mtop25">
          <div className="row col_2">
            <button className="btn_2 row vcenter pointier relative " onClick={ () => setShowModalCreate(true) } style={{ margin: 0, marginLeft: 5, marginBottom: "1rem", height:32 }}> 
              <Icon icon={"material-symbols:add-circle"} className="fontSizeMd c_color" style={{ marginRight: 5 }}/>
            </button>
          </div>
        </div>
      </div>
      <div className="row w100 hcenter">
        <div className="row mxw1000px relative ">
          <table className="box_c w100 absolute" cellPadding={0} cellSpacing={0} >
            <thead className="table_header pad15">
              <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                <td className="column">ID</td>
                <td className="column">Fecha</td>
                <td className="column">Cliente</td>
                <td className="column">Empleado</td>
                <td className="column">Estado</td>
                <td className="column">Prioridad</td>
                <td className="column">Solicitante</td>
                <td className="column">Acciones</td>
              </tr>
              <tr className="table_header w100 white">
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSId(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSDate(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSClient(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSEmployee(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSStatus(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSPriority(e.target.value) }}/></td>
                <td className="column"><input type="text" class="w100" onChange={(e) => { setSRequester(e.target.value) }}/></td>
                <td className="column"></td>
              </tr>
            </thead>
            <tbody>
            {items.map((row) => {
              return (
                <tr className="row_table">
                  <td className="column">{row["id2"]}</td>
                  <td className="column">{row["created"]}</td>
                  <td className="column">{row["client_name"]}</td>
                  <td className="column">{row["employee"]}</td>
                  <td className="column">{row["status_label"]}</td>
                  <td className="column">{row["priority"]["label"]}</td>
                  <td className="column">{row["requester"]}</td>
                  <td className="column">
                    { row["status_value"] == 1 && ( <button className="btn_accept2 pad5 pointier row vcenter" onClick={ () => {setEditUuid(row["uuid"]); setShowModalUpdate(true); } }>Editar</button>) }
                    { row["status_value"] == 2 && ( <button className="btn_accept2 pad5 pointier row vcenter" onClick={ () => {setEditUuid(row["uuid"]); setShowModalUpdate(true); } }>Ver</button>) }
                    </td>
                </tr>
              );}
            )}
              
            </tbody>
          </table>
        </div>
      </div>

      { showModalCreate && ( <ModalCreate setShowModalCreate={setShowModalCreate} /> ) }
      { showModalUpdate && ( <ModalUpdate setShowModalUpdate={setShowModalUpdate} uuid={editUuid}/> )}
      
    </div>
  );
}