import { Icon } from "@iconify/react";
import useCollapse from "react-collapsed";
import { Zoom } from "react-reveal";
import { InputNumber } from "primereact/inputnumber";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from "primereact/inputtextarea";
import ModalSupport from "./../../Modules/Assignment/ModalSupport";

function pass() { }
function Actividad({info, type, actividad, actividades, setActividades, inputRef, setInputFocus, id, activityTypes, categoriasList, categorias, setCategorias, activityHourTypes, projects }) {
  var t_horas = 0;
  for (let i in actividades) { t_horas += actividades[i].n_horas; }
  var a = 8 - (t_horas - actividad.n_horas);

  const [edit, setEdit] = useState(actividad.edit);
  const [editRow, setEditRow] = useState({});
  const [max, setMax] = useState(a);
  const [click, setClick] = useState(false)
  const [hand, setHand] = useState(false)
  
  const [showModalSupport, setShowModalSupport] = useState(false)

  const [newRow, setNewRow] = useState({ project: "", project_label: "", type: "", categoria: "", actividad: "", activityMinLength: 60, fase: "", modulo: "", n_horas: 0, type_hour: "", type_hour_label: "" });
  useEffect(() => {
    var t_horas = 0;
    for (let i in actividades) {
      t_horas += actividades[i].n_horas;
    }
    var a = 8 - (t_horas - actividad.n_horas);
    console.log(categoriasList);
    if (newRow.type == "support") {
      delete categoriasList[3];
      delete categoriasList[5];
      delete categoriasList[7];
    } else {
      categoriasList[3] = { value: "DISEÑO", label: "DISEÑO" };
      categoriasList[5] = { value: "PRUEBAS", label: "PRUEBAS" };
      categoriasList[7] = { value: "SOPORTE", label: "SOPORTE" };
    }
  }, [newRow]);

  useEffect(() => {
    addActividad()
  }, [click]);

  useEffect(() => {
    var t_horas = 0;
    for (let i in actividades) {
      t_horas += actividades[i].n_horas;
    }
    var a = 8 - (t_horas - actividad.n_horas);
    setMax(a);
  }, [edit]);

  useEffect(() => {
    pass(editRow);
    //if from support
    if(editRow.hasOwnProperty('support')){
      let obj = editRow;
      let actividadesCP = [...actividades];
      for (let i in actividadesCP) {
        if (actividad == actividadesCP[i]) {
          actividadesCP[i] = obj;
          break;
        }
      }
      setActividades(actividadesCP);
    }
  }, [editRow]);

  useEffect(() => {
    pass(max);
  }, [max]);

  function enterNew(e) {
    if (e.code == "Enter") {
      if (newRow.actividad.trim().length > 0 && newRow.n_horas >= 0 && newRow.categoria != "") {
        if (!categorias.includes(newRow.categoria.toUpperCase()) && newRow.categoria != "")
          setCategorias([...categorias, newRow.categoria])

        let obj = newRow;
        let actividadesCP = [...actividades];
        actividadesCP.push(obj);
        setActividades(actividadesCP);
        setNewRow({ project: "", type: "", categoria: "", actividad: "", activityLenght: 0, activityMinLength: 60, fase: "", modulo: "", n_horas: 0, type_hour: "" });

        document.getElementById(id).focus();
      }
    }
  }

  function enterUpdate(e) {
    if (e.code == "Enter") {
      if (editRow.actividad.trim().length > 0 && editRow.n_horas >= 0) {
        let obj = editRow;
        let actividadesCP = [...actividades];
        for (let i in actividadesCP) {
          pass(actividadesCP[i]);
          if (actividad == actividadesCP[i]) {
            actividadesCP[i] = obj;
            break;
          }
        }
        setActividades(actividadesCP);
        setEdit(false);
      }
    }
  }

  function addActividad() {
    let activityMinLength = (type == 'project' && newRow.categoria == 'REUNIÓN') ? 10 : 60;
    if (newRow.categoria != "" && (newRow.actividad.trim().length > 0 && newRow.actividad.trim().length >= activityMinLength) && newRow.n_horas >= 0 && newRow.type_hour) {
      if (!categorias.includes(newRow.categoria.toUpperCase()) && newRow.categoria != "")
        setCategorias([...categorias, newRow.categoria.toUpperCase()])
      let obj = newRow;
      let actividadesCP = [...actividades];
      actividadesCP.push(obj);
      setActividades(actividadesCP);
      setNewRow({ project: "", type: "", categoria: "", actividad: "", activityMinLength: 60, fase: "", modulo: "", n_horas: 0, type_hour: "" });

      document.getElementById(id).focus();
    }
    else {
      if (!(newRow.actividad.trim().length > 0 && newRow.actividad.trim().length >= activityMinLength) && click == true) {
        toast.error(`La actividad debe tener como mínimo ${activityMinLength} caracteres.`, { theme: "dark", toastId: "activityMinLength" });
      }
      else if ((newRow.actividad.length > 0 || newRow.fase.length > 0 || newRow.modulo.length > 0 || newRow.n_horas < 0 || newRow.categoria == "" || newRow.type_hour == "") && click == true) {
        toast.error(`Verifica que los campos esten completos.`, { theme: "dark", toastId: "llenar" });
      }
    }
  }
  useEffect(() => {
    if (id)
      document.getElementById("root").addEventListener("click", (event) => {
        if (event.target?.tagName != "svg" & event.target?.tagName != "INPUT" & !event.target?.className?.includes("p-button-icon") & !event.target?.className?.includes("p-clickable") & !event.target?.className?.includes("p-dropdown-label")) {
          setClick(!click)
        }
      })
  }, [newRow])

  if (edit)
    if (actividad.isAdd) {
      var t_horas = 0;
      for (let i in actividades) {
        t_horas += actividades[i].n_horas;
      }

      if (t_horas >= 8) return;
      return (
        <tr className="row_table">
          {type == 'project' ? (
            <td className="column-5">
              <Dropdown value={newRow.project} options={projects} optionLabel="label" placeholder="Tipo" className="w100 p-invalid md:w-14rem"
                onChange={(e) => {
                  let cpnewRow = { ...newRow };
                  cpnewRow.project = e.value;
                  cpnewRow.project_label = e.originalEvent.target.innerHTML;
                  cpnewRow.type = 'project'
                  setNewRow(cpnewRow);
                }} />
            </td>
          ) : (
            <td className="column-5">
              <Dropdown value={newRow.type} options={activityTypes} optionLabel="label" placeholder="Tipo" className="w100 p-invalid md:w-14rem"
                onChange={(e) => {
                  let cpnewRow = { ...newRow };
                  cpnewRow.type = e.value;
                  cpnewRow.type_label = e.originalEvent.target.innerHTML;
                  setNewRow(cpnewRow);
                }} />
            </td>
          )}
          <td className="column-5">
            <Dropdown value={newRow.categoria} options={categoriasList} optionLabel="label" placeholder="Categoría" className="w100 p-invalid md:w-14rem"
              onChange={(e) => {
                let cpnewRow = { ...newRow };
                cpnewRow.categoria = e.value;
                if(cpnewRow.categoria == 'REUNIÓN') cpnewRow.activityMinLength = 10;
                else cpnewRow.activityMinLength = 60;
                setNewRow(cpnewRow);
              }} disabled={!newRow.type ? true : false} />
          </td>
          <td className="column-5">
            <div className="row vcenter">
              <InputTextarea className="input_actividad w100 required" value={newRow.actividad} required
                placeholder="Actividad*" id={id} autoFocus style={{ height: 50 }}
                onChange={(e) => {
                  pass(e);
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...newRow };
                    cpnewRow.actividad = e.target.value;
                    setNewRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterNew(e);
                }}
              /> 
            </div>
            <small>{newRow.actividad.length} / {newRow.activityMinLength}</small>
          </td>
          {type == 'project' ? (
            <td className="column-5">
              <input
                className="input_actividad w100" value={newRow.modulo} type="text"
                onChange={(e) => {
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...newRow };
                    cpnewRow.modulo = e.target.value;
                    setNewRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterNew(e);
                }}
              />
            </td>
          ) : null}
          <td className="column-5" style={{ maxWidth: 150 }}>
            <InputNumber value={newRow.n_horas} style={{ maxWidth: 150 }} mode="decimal" useGrouping={false} className={"required md:w-8rem"} showButtons
              min={0} max={max} step={0.5} onKeyDown={(e) => { enterNew(e); }}
              onValueChange={(e) => {
                let cpnewRow = { ...newRow };
                var t_horas = 0;
                for (let i in actividades) {
                  t_horas += actividades[i].n_horas;
                }
                t_horas += e.value;
                t_horas -= actividad.n_horas;
                if (t_horas > 8) return;
                cpnewRow.n_horas = e.value;
                setNewRow(cpnewRow);
              }}
            />
          </td>
          <td className="column-5">
            <Dropdown value={newRow.type_hour} options={activityHourTypes} optionLabel="label" placeholder="Tipo de hora" className="w100 p-invalid md:w-14rem"
              onChange={(e) => {
                var valor = ""
                let cpnewRow = { ...newRow };
                cpnewRow.type_hour_label = e.originalEvent.target.innerHTML;
                cpnewRow.type_hour = e.value;
                setNewRow(cpnewRow);
              }} />
          </td>
          <td className="column-5">
            <div className="row vcenter hcenter"></div>
          </td>
        </tr>
      );
    } else { //for edit
      return (
        <tr className="row_table">
          {type == 'project' ? (
            <td className="column-5">
              <Dropdown value={editRow.project} options={projects} optionLabel="label" placeholder="Tipo" className="w100 p-invalid md:w-14rem"
                onChange={(e) => {
                  let cpnewRow = { ...editRow };
                  cpnewRow.project = e.value;
                  cpnewRow.project_label = e.originalEvent.target.innerHTML;
                  cpnewRow.type = 'project'
                  setEditRow(cpnewRow);
                }} />
            </td>
          ) : (
            <td className="column-5">
              <Dropdown value={editRow.type} options={activityTypes} optionLabel="label" placeholder="Tipo" className="w100 p-invalid md:w-14rem"
                onChange={(e) => {
                  let cpnewRow = { ...editRow };
                  cpnewRow.type = e.value;
                  setEditRow(cpnewRow);
                }} />
            </td>
          )}
          <td className="column-5">
            <Dropdown value={editRow.categoria} options={categoriasList} optionLabel="label" placeholder="Categoría" className="w100 p-invalid md:w-14rem"
              onChange={(e) => {
                let cpnewRow = { ...editRow };
                cpnewRow.categoria = e.value;
                setEditRow(cpnewRow);
              }} disabled={!editRow.type ? true : false} />
          </td>
          <td className="column">
            <div className="row vcenter">
              <InputTextarea className="input_actividad w100 required" value={editRow.actividad} required
                placeholder="Actividad*" id={id} autoFocus style={{ height: 50 }}
                onChange={(e) => {
                  pass(e);
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...editRow };
                    cpnewRow.actividad = e.target.value;
                    setEditRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterUpdate(e);
                }}
              />
            </div>
          </td>
          {type == 'project' ? (
            <td className="column">
              <input className="input_actividad w100" type="text"
                value={editRow.modulo}
                onChange={(e) => {
                  if (e.target.validity.valid || e.target.value == "") {
                    let cpnewRow = { ...editRow };
                    cpnewRow.modulo = e.target.value;
                    setEditRow(cpnewRow);
                  }
                }}
                onKeyDown={(e) => {
                  enterUpdate(e);
                }}
              />
            </td>
          ) : null}
          <td className="column" style={{ maxWidth: 150 }}>
            <InputNumber mode="decimal" useGrouping={false} showButtons className={"required"} style={{ maxWidth: 150 }}
              min={0} max={max} value={actividad.n_horas} step={0.5}
              onKeyDown={(e) => {
                if (
                  editRow.actividad.trim().length > 0 &&
                  editRow.n_horas >= 0
                ) {
                  let obj = editRow;
                  let actividadesCP = [...actividades];
                  for (let i in actividadesCP) {
                    pass(actividadesCP[i]);
                    if (actividad == actividadesCP[i]) {
                      actividadesCP[i] = obj;
                      break;
                    }
                  }
                  setActividades(actividadesCP);
                  setEdit(false);
                }
              }}
              onValueChange={(e) => {
                let cpnewRow = { ...editRow };
                var t_horas = 0;
                for (let i in actividades) {
                  t_horas += actividades[i].n_horas;
                }

                t_horas += e.value;

                t_horas -= actividad.n_horas;
                if (t_horas > 8) return;
                cpnewRow.n_horas = e.value;
                setEditRow(cpnewRow);
              }}

            />
          </td>
          <td className="column-5">
            <Dropdown value={editRow.type_hour} options={activityHourTypes} optionLabel="label" placeholder="Categoría" className="w100 p-invalid md:w-14rem"
              onChange={(e) => {
                var valor = ""
                let cpnewRow = { ...editRow };
                cpnewRow.type_hour_label = e.originalEvent.target.innerHTML;;
                cpnewRow.type_hour = e.value;
                setEditRow(cpnewRow);
              }} />
          </td>
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon icon={"material-symbols:edit-square-outline"} className="fontSizeMd hover_green pointier"
                onClick={() => {
                  if (
                    editRow.actividad.trim().length > 0 &&
                    editRow.n_horas >= 0 && editRow.categoria != ""
                  ) {
                    let obj = editRow;
                    let actividadesCP = [...actividades];
                    for (let i in actividadesCP) {
                      pass(actividadesCP[i]);
                      if (actividad == actividadesCP[i]) {
                        actividadesCP[i] = obj;
                        break;
                      }
                    }
                    setActividades(actividadesCP);
                    setEdit(false);
                  }
                }}
              />
            </div>
            <div className="row vcenter hcenter">
              <Icon
                icon={"bi:trash"}
                className="fontSizeMd hover_green pointier"
                onClick={() => setEdit(true)}
              />
            </div>
          </td>
        </tr>
      );
    }
  return (
    <>
    <tr className="row_table">
      <td className="column">{type == 'project' ? actividad.project_label : actividad.type_label}</td>
      <td className="column">{actividad.categoria}</td>
      <td className="column">{actividad.actividad}</td>
      {type == 'project' ? (<td className="column">{actividad.modulo}</td>) : null}
      <td className="column">{actividad.n_horas}</td>
      <td className="column">{actividad.type_hour_label}</td>
      <td className="column">
        <div className="row vcenter hcenter">
          { type == 'assignment' && actividad.type == 'support' ? 
          (
            <Icon
              icon={"material-symbols:description-outline"}
              className="fontSizeLg hover_green pointier mright10"
              onClick={() => { setShowModalSupport(true); }}
            />
          ) : null }
          <Icon
            icon={"material-symbols:edit-square-outline"}
            className="fontSizeLg hover_green pointier mright10"
            onClick={() => {
              setEdit(true);
              setEditRow(actividad);
            }}
          />
          <Icon
            icon={"bi:trash"}
            className="fontSizeLg hover_green pointier"
            onClick={() => {
              let cpArray = actividades.filter(
                (actividadO) => actividadO != actividad
              );
              setActividades(cpArray);
            }}
          />
        </div>
      </td>
    </tr> 
    { (type == 'assignment' && actividad.type == 'support' && showModalSupport) ? ( <ModalSupport setShowModalSupport={setShowModalSupport} data={info} actividad={actividad} setActividad={setEditRow} /> ) : null}
    </>
  );
}

export default function RowPeriodo({
  info,
  type,
  dia,
  setDias,
  dias,
  inputRef,
  setInputFocus,
  activityTypes,
  categoriasList,
  categorias,
  setCategorias,
  activityHourTypes,
  projects
}) {
  const [isExpanded, setExpanded] = useState(false);
  const [actividades, setActividades] = useState([...dia.actividades]);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });

  const [hours, setHours] = useState(0);

  useEffect(() => {
    let diasO = [...dias];
    for (let i in diasO) {
      if (diasO[i] == dia) {
        diasO[i].actividades = actividades ? actividades : [];
        setDias(diasO);
      }
    }

    var h = actividades.reduce((n, {n_horas}) => n + n_horas, 0)
    setHours(h);
  }, [actividades]);

  useEffect(() => { }, [isExpanded]);
  return (
    <div className="row w100 hcenter mtop_7px">
      <div className="w100 row vcenter hspace">
        <div className="box_c w100">
          <div
            className={`date_row w100 white hspace vcenter pointier ${isExpanded ? "active" : ""}`}
            {...getToggleProps({ style: { display: "flex" },
              onClick: () => {
                setExpanded((x) => !x);
              },
            })}
          >
            <div>
              <span className="bold">
                {dia.name} {dia.number}
              </span>
              &nbsp; De&nbsp; {dia.mes}&nbsp; del&nbsp; {dia.Año}
            </div>
            <div className={`icon_box row vcenter fontSizeMd`} style={{justifyContent:"flex-end", flexBasis:"100%"}}>
              {hours}/8 <Icon icon="tabler:clock-hour-9" className="ms-5px" />
            </div>
            <div className={`icon_box row vcenter ${isExpanded ? "active" : ""}`}>
              <Icon className="row center dropw fontSizeLg" icon={"ri:arrow-drop-down-line"} />
            </div>
          </div>

          <div className="pad20 w100" {...getCollapseProps()}>
            <div className="row w100 hcenter">
              <table className="w100" cellPadding={0} cellSpacing={0}>
                <tr className="bold">
                  <td className="column ">{type == 'project' ? 'Proyecto' : 'Tipo'}</td>
                  <td className="column ">Fase</td>
                  <td className="column ">Actividad</td>
                  {type == 'project' ? (<td className="column ">Módulo</td>) : null}
                  <td className="column ">Horas</td>
                  <td className="column ">Tipo de hora</td>
                  <td className="column ">Acciones</td>
                </tr>
                {actividades.map((actividad) => (
                  <Actividad
                    info={info}
                    type={type}
                    actividad={actividad}
                    actividades={actividades}
                    setActividades={setActividades}
                    inputRef={inputRef}
                    setInputFocus={setInputFocus}
                    activityTypes={activityTypes}
                    categoriasList={categoriasList}
                    categorias={categorias}
                    setCategorias={setCategorias}
                    activityHourTypes={activityHourTypes}
                    projects={projects}
                  />
                ))}

                <Actividad
                  info={info}
                  type={type}
                  actividad={{ modulo: "", fase: "", actividad: "", n_horas: 0, edit: true, isAdd: true, }}
                  id={dia.day}
                  actividades={actividades}
                  setActividades={setActividades}
                  inputRef={inputRef}
                  setInputFocus={setInputFocus}
                  activityTypes={activityTypes}
                  categoriasList={categoriasList}
                  categorias={categorias}
                  setCategorias={setCategorias}
                  activityHourTypes={activityHourTypes}
                  projects={projects}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
