import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Actions from "../../../Organism/Actions";
import ModalForm from "../../../Organism/ModalForm";
import { Services } from "../Services";
import EquipoComponent from "./Componets";

export default function Equipo() {
    const [dinamicValues, setDibamicValues] = useState({'integrantesUpdate':[]})
    const opciones = [
        {
            name: "Equipos",
            path: "Equipos",
            onClick: (opcion) => {
                setCurrentOption(opcion)
            },
            print: "Equipos",
            component: EquipoComponent,
            tipo: "tabla",
            seccion: "1",
            modales:[
                {
                    name: "Equipos",
                    path: "conocimiento",
                    onClick: (opcion) => {
                        setModalesAbiertos([...modalesAbiertos, opcion.name]);
                    },
                    print: "Agregar Equipo",
                    add: true,
                    isModal: true,
                    component: null,
                    formData: {
                        body: {
                            clave: '',
                            nombre: '',
                            integrantes: []
                        },
                        endpoint: '',
                        fields: [
                            {
                                type: 'textField',
                                name: 'clave',
                                text: 'Clave',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'textField',
                                name: 'nombre',
                                text: 'Nombre del equipo',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: () => {}
                            },
                            {
                                type: 'multiSelect',
                                name: 'integrantes',
                                text: 'Empleados',
                                value: [],
                                method: '/EquiposSeleccion/getEmpleados/',
                                getByEndPont: true,
                                onClick: () => { }
                            },
                            {
                                type: 'button',
                                name: 'submit',
                                text: 'Crear',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: (body) => {
                                    Services.create_equipo(body, () => {
                                        toast.success("Equipo creado")
                                        setModalesAbiertos(modalesAbiertos.filter((modal_a) => modal_a !== "Equipos"));
                                    }
                                    )
                                }
                            }
                        ],
                    },
                    tipo: "tabla",
                    seccion: "1",
                },
                {
                    name: "Miembros",
                    path: "conocimiento",
                    onClick: (opcion) => {
                        setModalesAbiertos([...modalesAbiertos, opcion.name]);
                    },
                    print: "Agregar Miembro",
                    add: true,
                    isModal: true,
                    component: null,
                    formData: {
                        body: {
                            claveUpdate: '',
                            integrantesUpdate: []
                        },
                        endpoint: '',
                        fields: [
                            {
                                type: 'select',
                                name: 'claveUpdate',
                                text: 'Equipo',
                                value: null,
                                method: '/Equipos/getEquipos/',
                                getByEndPont: true,
                                onClick: () => { },
                                onChange: (value) => {
                                    Services.getIntegrantesEquipo(value, (response) => {
                                        response.then(data=>{
                                            let a = {...dinamicValues}
                                            a['integrantesUpdate'] = data
                                            setDibamicValues(a)
                                        })
                                    })
                                }
                            },
                            {
                                type: 'multiSelect',
                                name: 'integrantesUpdate',
                                text: 'Empleados',
                                value: [],
                                method: `/EquiposSeleccion/getEmpleados/`,
                                getByEndPont: true,
                                onClick: () => { }
                            },
                            {
                                type: 'button',
                                name: 'submit',
                                text: 'Aceptar',
                                value: '',
                                method: '',
                                getByEndPont: false,
                                onClick: (body) => {
                                    Services.update_equipo(body, () => {
                                        toast.success("Equipo actualizado")
                                        setModalesAbiertos(modalesAbiertos.filter((modal_a) => modal_a !== "Miembros"));
                                    }
                                    )
                                },
                            }
                        ],
                    },
                    tipo: "tabla",
                    seccion: "1",
                }
            ]
        }
    ]
    const [currentOption, setCurrentOption] = useState(opciones[0]);
    const [modalesAbiertos, setModalesAbiertos] = useState([]);
    
    useEffect(()=>{
        for(const modal_no_abierto of currentOption.modales.filter((modal)=>!modalesAbiertos.includes(modal.name))){
            for(const field of modal_no_abierto.formData.fields){
                var dinamicCP = dinamicValues
                if(dinamicValues[field.name]){
                    dinamicCP[field.name] = field.value
                }
                setDibamicValues(dinamicCP)
            }
        }
    },[modalesAbiertos])
    useEffect(() => {}, [currentOption])
    return (
        <>
            <Actions currentOption={currentOption} opciones={opciones} modales={currentOption.modales} />
            {currentOption.component()}
            {currentOption.modales.map((modal) => (
                <Dialog
                    key={modal.name}
                    header={`${modal.add ? "Agregar " : ""}${modal.name}`}
                    visible={modalesAbiertos.includes(modal.name)}
                    onHide={() => { setModalesAbiertos(modalesAbiertos.filter((modal_a) => modal_a !== modal.name));}}
                    style={{ width: "30vw" }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
                    <ModalForm modal={modal} dinamicValues={dinamicValues} />
                </Dialog>
            ))}
        </>
    )
}