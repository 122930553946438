import { Zoom } from "react-reveal";
import makeAnimated from "react-select/animated";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useState } from "react";
import Bounce from "react-reveal/Bounce";
function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
function pass(){}
export default function Filter({
  setFilters,
  rows,
  filters,
  rowsSearch,
  setRowsSearch,
  filterss,
  search,
  setRowsF,
}) {
  
  const [activeC, setActiveC] = useState(false);
  const [click, setClick] = useState(null);
  const [change, setChange] = useState(0);
  useEffect(() => {
    pass(click);
    setActiveC(false);
  }, [click]);

  useEffect(() => {
    pass(change);
    f(filterss);
  }, [change]);
  var listFilters = {};
  var a = [];
  function filter(rowsS) {
    if (search.length > 0) {
      var arraySe = [];
      for (let row in rowsS) {
        for (let value in Object.values(rowsS[row])) {
          if (value > 0 && value < Object.values(rowsS[row]).length - 1) {
            var valorA = Object.values(rowsS[row])[value];
            if (
              valorA
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  search
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )
            )
              if (!arraySe.includes(rowsS[row])) arraySe.push(rowsS[row]);
          }
        }
      }
      setRowsF(arraySe);
      setRowsSearch(arraySe);
    } else {
      setRowsF(rowsS);
      setRowsSearch(rowsS);
    }
  }

  function filterK(rowsS) {
    if (search.length > 0) {
      var arraySe = [];
      for (let row in rowsS) {
        for (let value in Object.values(rowsS[row])) {
          if (value > 0 && value < Object.values(rowsS[row]).length - 1) {
            var valorA = Object.values(rowsS[row])[value];
            if (
              valorA
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  search
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                )
            )
              if (!arraySe.includes(rowsS[row])) arraySe.push(rowsS[row]);
          }
        }
      }

      return arraySe;
    } else {
      return rowsS;
    }
  }

  function checkEmpty(fff) {
    var empty = true;
    var a = [];
    for (var i in Object.keys(fff)) {
      var filtroA = Object.keys(fff)[i];
      if (fff[filtroA].length > 0) empty = false;
    }
    return empty;
  }

  function contains(fil, op) {
    var number = 0
    for (let i in Object.keys(filterss)) {
      if (fil == Object.keys(filterss)[i]) {
         var esta = false
          for (let j in filterss[Object.keys(filterss)[i]]) {
            if(filterss[Object.keys(filterss)[i]][j]==op){
              esta=true
            }
           
          }
          if(!esta){
            filterss[Object.keys(filterss)[i]].push(op);
            setChange(change+1)
            return true
          }
            
          else{
            let cpArray =filterss[Object.keys(filterss)[i]].filter(
              (actividadO) => actividadO != op
            );
            filterss[Object.keys(filterss)[i]]=cpArray;
            setChange(change-1);
            return false
          } 
          pass(filterss);
      }
    }
  }

  function contains2(fil, op) {
    for (let i in Object.keys(filterss)) {
      if (fil == Object.keys(filterss)[i]) {
        if (filterss[Object.keys(filterss)[i]].length > 0)
          for (let j in filterss[Object.keys(filterss)[i]]) {
            if (filterss[Object.keys(filterss)[i]][j] == op) {
              return true;
            }
          }
      }
    }
    if (!filterss[fil]) filterss[fil] = [];
    return false;
  }

  function f(fff) {
    if (checkEmpty(fff)) {
      filter(rows);
      return;
    }
    pass("llll");
    var rowsSearch2 = filterK(rows);
    var rowsClone = [];
    for (var rowF in rowsSearch2) {
      var cumple = false;
      for (var i in Object.keys(fff)) {
        var filtroA = Object.keys(fff)[i];
        if (fff[filtroA].length > 0) {
          var cumple2 = false;
          for (var fil in fff[filtroA]) {
            var valor = fff[filtroA][fil];
            var valor2 = rowsSearch2[rowF][filtroA];
            if (valor2 == valor) cumple2 = true;
          }
          if (cumple2) cumple = true;
          else {
            cumple = false;
            break;
          }
        }
      }
      if (cumple) rowsClone.push(rowsSearch2[rowF]);
    }
    pass(rowsClone);
    filter(rowsClone);
  }
  a.push();
  for (let i in filters) {
    listFilters[filters[i]] = [];
    for (let row in rows) {
      if (rows[row] != null)
        listFilters[filters[i]].push(rows[row][filters[i]]);
    }
    listFilters[filters[i]] = removeDuplicates(listFilters[filters[i]]);
  }
  return (
    <div className="row hcenter">
      {activeC ? (
        <div className="click_action " onClick={(e) => setClick(e)}></div>
      ) : (
        ""
      )}

      <div className="row">
        <div className="filtro row vcenter pointier relative" onClick={() => setActiveC(true)} >
          <span className="row vcenter" style={{ marginRight: 5 }}>
            {change > 0 ? (
              <div className="filter-status row hcenter vcenter">{change}</div>
            ) : (
              <Icon icon={"ic:baseline-filter-list"} />
            )}
          </span>
          Filtros
          <Bounce when={activeC} collapse>
            <div className="absolute filter-select">
              <div className="row hcenter">
                <Zoom cascade>
                  <div className="w100">
                    {filters.map((fil) => (
                      <div>
                        <span className="thead bold">{fil}</span>
                        {listFilters[fil].map((op) => (
                          <li
                            className={`filter-op ${
                              contains2(fil, op) ? "active" : ""
                            }`}
                            onClick={() => {
                              contains(fil, op);
                            }}
                          >
                            {op}
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </Zoom>
              </div>
            </div>
          </Bounce>
        </div>
        <Bounce when={change > 0}>
          <div
            className="filter-clear row hcenter vcenter"
            onClick={() => {
              setFilters({});
              setChange(0);
            }}
          >
            <Icon icon={"material-symbols:close"} />
          </div>
        </Bounce>
      </div>
    </div>
  );
}
