import { useState } from "react";
import "./../../static/scss/modal.scss";
import { Icon } from "@iconify/react";
export default function Modal({ toggleModal, setToggleModal, modal, title }) {
  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div
            className="close absolute pointier"
            onClick={() => setToggleModal(false)}
          >
            <Icon icon={"ic:twotone-close"} />
          </div>
          <div
            className="row hcenter w100 pad20 vcenter"
            style={{ background: "#000", color:"#fff" }}
          >
            <h2 className="row vcenter">{title}</h2>
          </div>
        </div>
        {modal}
      </div>
    </>
  );
}
