import "./../static/scss/login.scss";
import Logo from "./../static/images/Logo.png";
import logow from "./../static/images/logow.png";
import contraseña from "./../static/images/contraseña.png";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import { Fade } from "react-reveal";

function pass(){}
export default function Login({ toast, Post, user, setUser }) {
  const [username, setusername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  useEffect(() => {}, [username, password]);
  // Observa los cambios que hay en toggleModal y agrega clases al <body>
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
    if(toggleModal)setSubmit(false)
  }, [toggleModal]);

  function login(event) {
    event.preventDefault();
    if (password.length == 0 || username.length == 0)
      toast.error("Debes de llenar todos los campos", {
        toastId: "login",
        theme: "dark",
      });
    else {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);
      params.append(
        "public_key",
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
      );

      Post("/token",
        (response) => {
          pass(response);
          setUser(response.data.user);
          localStorage.setItem('user', JSON.stringify(response.data.user));
        },
        (error) => {
          pass(error);
        },
        true,
        "Validando credenciales",
        params
      );
    }
  }
  
  function check(e) {
    pass(e);
    if (email.length == 0) {
      toast.error("Ingresa un correo valido", {
        toastId: "login",
        theme: "dark",
      });
      e.preventDefault();
      return;
    }
    e.preventDefault();
    setSubmit(true);

    const params = new URLSearchParams();
    params.append("email", email);

    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );

    Post(
      "/restart_pass",
      (response) => {
        pass(response);
       
      },
      (error) => {
        pass(error);
      },
      true,
      "Enviando Correo",
      params
    );
  }

  if (user) return <Navigate to="/" />;
  return (
    <>
      <div className={"modal-background"}></div>
      <div className={"modal_contraseña"} id={"modal"}>
        <div className="relative">
          <div
            className="close_2 absolute pointier"
            onClick={() => setToggleModal(false)}
          >
            <Icon icon={"ic:twotone-close"} />
          </div>
        </div>
        <div className="row w100 h100 hcenter">
          <div className="row w100 vcenter pad20 wrap hcenter">
            <form onSubmit={check}>
              
                <h1 className="f_50 row w100">
                  {submit ? (<Fade bottom>¡Hecho! </Fade>) : "¿Olvidaste tu contraseña?"}
                </h1>
             
              <p className="mtop15">
                {submit
                  ?(<Fade bottom>Te enviaremos un correo electrónico con instrucciones para el restablecimiento de tu contraseña.</Fade>)  
                  : "Por favor, ingresa el correo electrónico asociado a tu cuenta para recuperar tu contraseña."}
              </p>
              {submit?"":<div className="row hcenter vcenter mtop15">
                  <span style={{ marginRight: 10 }} className={"bold"}>
                    @
                  </span>

                  <input
                    type="email"
                    id="email_r"
                    className="input_email"
                    placeholder="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>}
                
              
                 
              <div className="row hright mtop25">
              {submit?
              <div className="btn green_1 tblack bold pointier" onClick={()=>(setToggleModal(false))}>
              Página de inicio
            </div>
              :<button className="btn green_1 tblack bold pointier">
                  Enviar Correo
                </button>}
                
              </div>
            </form>
          </div>
          <div className="row w100 h100 hright vcenter">
            <img
              src={contraseña}
              alt=""
              style={{ width: 460, maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container bg_1 vcenter">
        <form className="mx537px" onSubmit={login}>
          <div className="row w100 hcenter">
            <img src={logow} alt="eks" width="212" />
          </div>
          <div className="row w100 hcenter">
           <Fade >
           <div className="form_login row hcenter wrap">
              <input
                type="text"
                className="input_login"
                placeholder="Usuario"
                onChange={(event) => {
                  setusername(event.target.value);
                }}
              />
              <input
                type="password"
                className="input_login"
                placeholder="Contraseña"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <div className="row hcenter w100">
                <button className="btn_login row hcenter vcenter">
                  <h2>Iniciar</h2>
                </button>
              </div>
            </div>
           </Fade>
          </div>
          <div className="row w100 hright white pointier m_15px">
            <span
              onClick={() => setToggleModal(true)}
              style={{ marginRight: 25 }}
            >
              ¿Olvidaste tu contraseña?
            </span>
          </div>
        </form>
      </div>
    </>
  );
}
