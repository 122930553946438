import { ThreeCircles } from "react-loader-spinner";
const Loader = () => (
  <div className="row w100 hcenter pad15 vcenter">
    <ThreeCircles
      height="43"
      width="43"
      color="#0061FF"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  </div>
);

export default Loader;
