import { baseURL } from "../../App"


export default function FileComponent({ file }) {
    return (
        <div className="fileComponent row wrap hcenter vcenter pointier" style={{ position: "relative", margin:5 }}>
            <a href={`${baseURL}${file.content}`} className="pi pi-cloud-download" style={{ fontSize: "1.4rem", color: "#1561ff", position: "absolute", right: 7, top: 7 }} download={true} target={"_blank"}></a>
            <i className="pi pi-file" style={{ fontSize: "5rem", color: "#1561ff" }}></i>
            <div className="row w100 hcenter">
                {file.name}
            </div>
            <div className="row w100 hcenter" style={{color:"rgba(78, 78, 78, 0.757)"}}>
                {file.created}
            </div>

        </div>
    )
}


