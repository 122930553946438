
import { FileUpload } from 'primereact/fileupload';
import { ToastContainer, toast } from "react-toastify";
import { axios_instance, baseURL } from "../../App";
import { Post } from '../../App';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
const getUser = () => JSON.parse(localStorage.getItem('user'));

export default function FirmaUpload({uuid,setChangeF,changeF,setUpdateFirma,updateFirma}) {
  const [user, setUser] = useState(getUser());
  const [rating_value, setRatingValue] = useState(null);
  const rating_status = [ { value: 1, label: "Excelente"}, 
    { value: 2, label: "Muy buena"}, 
    { value: 3, label: "Buena" }, 
    { value: 4, label: "Deficiente" },
    { value: 5, label: "Área de oportunidad" },
  ];

  useEffect(()=>{
    fetch(`${baseURL}/hoja_actividades/approver/${uuid}`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setRatingValue(data.rating)
    });
  },[]);

  function changeRating(rating)
  {
    fetch(`${baseURL}/hoja_actividades/approver/rating`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({"uuid" : uuid, "rating" : rating})
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      toast.success("Calificación guardada correctamente", { theme: "dark" });
      setRatingValue(data.rating)
    });
  }

  return (
    <>
      <div className="" style={{marginTop:20, fontSize: "15px"}}>
          { user.rol.name == "Aprobador" && (
            <div class="flex w100">
              <span className="p-float-label flex-1" key="group-rating_status" style={{marginTop:20, fontSize: "15px"}}>
                <Dropdown id="rating_status" value={rating_value} onChange={(e) => changeRating(e.value)} options={rating_status} 
                  optionLabel="label" placeholder="Selecciona una opción" style={{ width: "480px", height: "40px", fontSize: "15px" }}/>
                <label htmlFor="rating_status">¿Cómo clasificaría el llenado de esta Hoja de Actividades?</label>
              </span>
            </div>
          )}

        { (user.rol.name == "Empleado" || user.rol.name == "Aprobador") && (
          <FileUpload mode="basic" accept="image/*" maxFileSize={3000000} chooseLabel="Seleccionar firma" disabled={!rating_value && (user.rol.name == "Aprobador")}
            onUpload={()=>{}}
            onSelect={(e)=>{
              var data = new FormData();
              setUpdateFirma(true)
              data.append("firma", e.files[0]);
              data.append("uuid", uuid);
              
              data.append(
                "public_key",
                "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
              );
              data.append(
                "private_key",
                "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
              );
              Post(`hoja_actividades/upload_firma/`,(response)=>{setUpdateFirma(false);},(error)=>{setUpdateFirma(false);},true,"Subiendo firma",data,true)
            }}
          />
        )}
      </div>  
    </>
  )
}
        