import Logotipo from "./../static/images/Logotipo.svg";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import Filter from "./snippets/Module/Filter";
import { ThreeCircles } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import Actions from "./snippets/Module/Actions";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css"; //core
import "primeicons/primeicons.css"; //icons
import { InputSwitch } from "primereact/inputswitch";
import { Zoom } from "react-reveal";
import { TabView, TabPanel } from 'primereact/tabview';
import Actividades from "./../static/images/Actividades.svg";
import Asignaciones from "./../static/images/Asignaciones.svg";
import Empleadoss from "./../static/images/Empleadoss.svg";
import { ReactSVG } from "react-svg";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import Fade from "react-reveal/Fade";

import { Chart } from 'primereact/chart';
import ReactApexChart from "react-apexcharts";

import { Calendar } from "primereact/calendar";
import { baseURL } from "../App";
import ModalReporte from "./snippets/MoldaReporte";
import ModalEdit from "./snippets/ModalEdit";
import { default as Incidences } from "./Modules/Incidencias";
import { default as Support } from "./Modules/Support/index";
import ModalActivities from "./Modules/PMO/ModalActivities";

function pass() {}
export default function AdminV({ setUser, user, Post }) {
  const menus = [
    {
      menu: "Hoja de Actividades",
      name: "Hojas de Actividades",
      description:
        "En la siguiente lista encontrarás el historial general de hojas de aprobación emitidas por los colaboradores . Haz click en reporte para imprimir las hojas de actividades.",
      opciones: [
        {
          name: "Hoja de Actividades",
          path: "hoja_actividades",
          add: false,
          print: "",
          tipo: "tabla"
        },
        {
          name: "Graficos por categoria",
          path: "hoja_actividades/graficos-pruebas",
          add: false,
          print: "",
          tipo: "grafica"
          
        },
        {
          name: "Rep. Soporte",
          path: "supports",
          add: false,
          print: "",
          tipo: "module",
          module: Support
        },
        {
          name: "Incidencias",
          path: "incidencias",
          add: false,
          print: "",
          tipo: "module",
          module: Incidences
        }
        //
      ],
    },
    {
      menu: "Gestión de Asignaciones y Proyectos",
      name: "Gestión de Asignaciones y Proyectos ",
      description:
        "En la siguiente lista encontrarás las entradas a las asignaciones por cliente y entidad. Haz click en “Consultar” para modificar los recursos asignados o los datos de alta de la asignación.",
      opciones: [
        {
          name: "Asig. / Proy.",
          path: "asignacion",
          print: "Asig. / Proy.",
          add: true,
          tipo: "tabla"
        },
      ],
    },
    {
    },
  ];

  const [app, setApp] = useState(menus[0]);
  const [entidad, setEntidad] = useState(menus[0].opciones[0]);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [form, setForm] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [rowsSearch, setRowsSearch] = useState([]);
  const [uuidReport, setUUIDReport] = useState(null);
  const [uuidForm, setUUIDForm] = useState(null);
  const [filterss, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [menu, setMenu] = useState(menus[0].menu);
  const [rowsF, setRowsF] = useState([]);
  const [aplication, setAplicaicon] = useState(menus[0].opciones[0].path);
  //
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState("");
  const [dateGrafica, setDateGrafica] = useState(null);
  const [barChart, setBarChart] = useState(null);
  const [pieChart, setPieChart] = useState(null);
  const [radarChart, setRadarChart] = useState(null);
  //Martin Isai Zpt Rms
  const [module, setModule] = useState(null);
  const [week, setWeek] = useState(null);
  
  const [toggleModalR, setToggleModalR] = useState(false);
  const [toggleModalReview, setToggleModalReview] = useState(false);
  const [uuidReview, setUUIDReview] = useState(null);

  const [employee, setEmployee] = useState("");
  const [client, setClient] = useState("");
  const [assignment, setAssignment] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");

  const [itemsOrigin, setItemsOrigin] = useState(rows);
  
  useEffect(() => {
    filter()
  },[employee, client, assignment, date, status]);

  function filter() {
    if (user.rol.name != 'PMO'){
      return;
    }
    var arraySe = [];
    var rowm = [...rows]
    for (let row in rowm) {
      let values = Object.values(rowm[row]);
      if (
        values[1].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(employee.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[2].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(client.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[3].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(assignment.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[4].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(date.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[5].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(status.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase())){
        if (!arraySe.includes(rowm[row])){
          arraySe.push(rowm[row]);
        }
      }
    }
    setRowsSearch(arraySe);
  }

  const optionWeeks =[
    { "label" : "Semana 1", "value" : 1},
    { "label" : "Semana 2", "value" : 2},
    { "label" : "Semana 3", "value" : 3},
    { "label" : "Semana 4", "value" : 4},
    { "label" : "Semana 5", "value" : 5},
  ]
  
  const namesRol = [
    {"type" : "rol", "nombre": "Empleado", "tieneFiltro":true, "filtro":"Empleado"},
    {"type" : "rol", "nombre": "Personal Administrativo", "tieneFiltro":true, "filtro":"Personal Administrativo"},
    {"type" : "rol", "nombre": "Personal Contabilidad", "tieneFiltro":true, "filtro":"Personal Contabilidad"},
    {"type" : "module", "nombre": "Proyectos", "tieneFiltro":true, "filtro":"Proyectos"},
    {"type" : "module", "nombre": "Soporte", "tieneFiltro":true, "filtro":"Soporte"},
    {"type" : "module", "nombre": "Tipo de Hora", "tieneFiltro":true, "filtro":"Tipo de Hora"},
    {"type" : "module", "nombre": "Incidencias", "tieneFiltro":true, "filtro":"Incidencias"},
    {"type" : "module", "nombre": "Cump. Semanal", "tieneFiltro":true, "filtro":"Cump. Semanal"},
    {"type" : "module", "nombre": "Calidad Actividades", "tieneFiltro":true, "filtro":"Calidad Actividades"},
    {"type" : "module", "nombre": "Satisfacción Clte.", "tieneFiltro":true, "filtro":"Satisfacción Cliente"},
  ]
  const [rolGrafica, setRolGrafica] = useState(namesRol[0]); 

  //
  function checkEmpty(fff){
    var empty = true
    for(var i in Object.keys(fff)){
        var filtroA = Object.keys(fff)[i]
        if(fff[filtroA].length>0)
            empty=false
    }
    return empty
  }

  function Input({ field, modelo, setModelo }) {
    const [value, setValue] = useState(modelo ? modelo[field.name] : "");
    var v = [];
    if (field.type == "select" && !field.multiple) {
      for (let i in field.values) {
        if (field.values[i].value == value) {
          v = { name: field.values[i].label, code: field.values[i].value };
        }
      }
    }
    const [checked, setChecked] = useState(false);
    const [select, setSelect] = useState(v);
    useEffect(() => {
      var modelocp = { ...modelo };
      pass(value);
      if (value || value == "") {
        if (field.type == "select" && value == "") modelocp[field.name] = [];
        else modelocp[field.name] = value;
        setModelo(modelocp);
      }
    }, [value]);

    useEffect(() => {
      var modelocp = { ...modelo };
      if (field.type == "checkbox") {
        modelocp[field.name] = checked;
        setModelo(modelocp);
      }
    }, [checked]);

    useEffect(() => {
      pass(field.name);
    }, [select]);

    if (field.type == "text")
      return (
        <span className="p-float-label" key={field.name}>
          <InputText
            id={field.name}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "checkbox")
      return (
        <span
          className="p-float-label"
          key={field.name}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "number")
      return (
        <span className="p-float-label" key={field.name}>
          <InputNumber
            id={field.name}
            useGrouping={false}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.originalEvent.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "email")
      return (
        <span className="p-float-label" key={field.name}>
          <InputText
            id={field.name}
            value={modelo[field.name]}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );

    if (field.type == "file")
      return (
        <span className="p-float-label" key={field.name}>
          <FileUpload
            mode="basic"
            name={field.name}
            accept={field.accept}
            maxFileSize={1000000}
            chooseLabel={`Subir ${field.name}`}
          />
        </span>
      );

    if (field.type == "select") {
      var datos = [];
      for (let i in field.values) {
        datos.push({
          name: field.values[i].label,
          code: field.values[i].value,
        });
      }

      if (!field.multiple)
        return (
          <span className="p-float-label" key={field.name}>
            <Dropdown
              optionLabel="name"
              placeholder={field.print_name}
              className="w-full md:w-14rem"
              value={select}
              options={datos}
              onChange={(e) => {
                setValue(e.value.code);
                setSelect(e.value);
              }}
            />
            <label htmlFor={field.name}>{field.print_name}</label>
          </span>
        );

      return (
        <span className="p-float-label" key={field.name}>
          <MultiSelect
            value={select}
            onChange={(e) => {
              setSelect(e.value);
              pass(e.value);
              var datos = [];
              for (let i in e.value) {
                datos.push(e.value[i].code);
              }
              var modelocp = { ...modelo };
              modelocp[field.name] = datos;
              setModelo(modelocp);
            }}
            options={datos}
            optionLabel="name"
            display="chip"
            placeholder={`Selecciona ${field.print_name}`}
            className=""
            maxSelectedLabels={3}
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );
    }

    if (field.type == "date")
      return (
        <span className="p-float-label" key={field.name}>
          <Calendar
            value={value}
            onChange={(e) => setValue(e.value)}
            dateFormat="yy-mm-dd"
          />
          <label htmlFor={field.name}>{field.print_name}</label>
        </span>
      );
  }

  function AddRow({
    field,
    editO,
    dato,
    setDatos,
    datos,
    modelo,
    setModelo,
    modelO,
  }) {
    const [edit, setEdit] = useState(false);
    const [model, setModel] = useState(modelO);

    useEffect(() => {
      setModel(modelO);
    }, [edit]);

    useEffect(() => {
      pass(model);
    }, [model]);

    if (editO) {
      if (model)
        return (
          <tr className="row_table">
            {field.fields.map((fieldO) => {
              return (
                <td className="column">
                  <div className="row vcenter">
                    {fieldO == field.fields[0] && (
                      <Icon
                        icon={"material-symbols:add-circle"}
                        className="fontSizeMd  pointier c_color"
                        style={{ marginRight: 5 }}
                        onClick={() => {
                          if (model[fieldO.name].length > 0) {
                            setDatos([...modelo[field.id], model]);
                            var modelcp = {};

                            for (let i in field.fields) {
                              modelcp[field.fields[i].name] = "";
                            }
                            setModel(modelcp);
                          }
                        }}
                      />
                    )}

                    <input
                      className={`input_actividad w100 ${
                        fieldO == field.fields[0] ? "required" : ""
                      }`}
                      required
                      placeholder={fieldO.print_name}
                      id={"id"}
                      value={model[fieldO.name]}
                      type={fieldO.type}
                      onChange={(e) => {
                        pass(model);
                        var modelcp = { ...model };
                        modelcp[fieldO.name] = e.target.value;
                        setModel(modelcp);
                        pass(modelcp);
                      }}
                    />
                  </div>
                </td>
              );
            })}
            <td className="column"></td>
            <td className="column"></td>
          </tr>
        );
    }
    if (edit) {
      return (
        <tr className="row_table">
          {field.fields.map((fieldO) => {
            return (
              <td className="column">
                <input
                  className="input_actividad w100"
                  required
                  placeholder={fieldO.print_name}
                  id={"id"}
                  value={model[fieldO.name]}
                  type={fieldO.type}
                  onChange={(e) => {
                    var modelcp = { ...model };
                    modelcp[fieldO.name] = e.target.value;
                    setModel(modelcp);
                    pass(modelcp);
                  }}
                />
              </td>
            );
          })}
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"material-symbols:edit-square-outline"}
                className="fontSizeMd hover_green pointier"
                onClick={() => {
                  setEdit(false);
                  let obj = model;
                  let actividadesCP = [...datos];
                  for (let i in actividadesCP) {
                    pass(actividadesCP[i]);
                    pass(dato);
                    if (dato == actividadesCP[i]) {
                      actividadesCP[i] = obj;
                      setDatos(actividadesCP);
                      pass(actividadesCP);
                      break;
                    }
                  }
                }}
              />
            </div>
          </td>
          <td className="column">
            <div className="row vcenter hcenter">
              <Icon
                icon={"bi:trash"}
                className="fontSizeMd hover_green pointier"
                onClick={() => {
                  let cpArray = modelo[field.id].filter(
                    (actividadO) => actividadO != dato
                  );
                  setDatos(cpArray);
                }}
              />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr className="row_table">
        {Object.values(dato).map((value) => (
          <td className="column">
            <div className="row vcenter">{value}</div>
          </td>
        ))}

        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"material-symbols:edit-square-outline"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
        </td>
        <td className="column">
          <div className="row vcenter hcenter">
            <Icon
              icon={"bi:trash"}
              className="fontSizeMd hover_green pointier"
              onClick={() => {
                let cpArray = modelo[field.id].filter(
                  (actividadO) => actividadO != dato
                );
                setDatos(cpArray);
              }}
            />
          </div>
        </td>
      </tr>
    );
  }

  function Group({ data, i, model2, m, field }) {
    const [modelo, setModelo] = useState({ ...model2 });
    const [datos, setDatos] = useState([]);

    const RowA = () => {
      var modelcp = {};
      for (let i in field.fields) {
        if (field.fields[i].type == "select")
          modelcp[field.fields[i].name] = [];
        else modelcp[field.fields[i].name] = "";
        pass(modelcp);
      }

      return (
        <AddRow
          field={field}
          editO={true}
          setDatos={setDatos}
          datos={modelo[field.id]}
          modelo={modelo}
          setModelo={setModelo}
          modelO={modelcp}
        />
      );
    };

    useEffect(() => {
      var cpmodelo = { ...modelo };
      cpmodelo[field.id] = datos;
      setModelo(cpmodelo);
    }, [datos]);

    useEffect(() => {
      m[0] = modelo;
    }, [modelo]);
    if (field)
      if (!field.type)
        return (
          <div className="box_c w100 mtop25">
            <div className="row w100 hcenter"></div>
            <div className="table_header w100 white hspace vcenter row">
              <p className="fontSizeSm">
                {data.form_create
                  ? data.form_create.fields[i].text
                  : "Datos generales"}
              </p>
            </div>

            <div className="padlr20">
              <div className="row w100 hcenter pad20 wrap">
                <Zoom cascade>
                  <div className="row card p-fluid row wrap hcenter w100">
                    {data.form_create != null
                      ? data.form_create.fields[i].fields.map((field) => {
                          return (
                            <Input
                              field={field}
                              modelo={modelo}
                              setModelo={setModelo}
                            />
                          );
                        })
                      : data.form.fields.map((field) => {
                          return (
                            <Input
                              field={field}
                              modelo={modelo}
                              setModelo={setModelo}
                            />
                          );
                        })}
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        );

    return (
      <div className="row w100 hcenter box_c mtop25">
        {field && (
          <table className="w100 " cellPadding={0} cellSpacing={0}>
            <tr className="bold table_header white">
              {field.fields.map((label) => (
                <td className="column ">{label.print_name}</td>
              ))}
              <td className="column ">
                <div className="row hcenter">Editar</div>
              </td>
              <td className="column ">
                <div className="row hcenter">Borrar</div>
              </td>
            </tr>
            {modelo[field.id].map((dato) => {
              return (
                <AddRow
                  field={field}
                  editO={false}
                  dato={dato}
                  datos={datos}
                  setDatos={setDatos}
                  modelo={modelo}
                  setModelo={setModelo}
                  modelO={dato}
                />
              );
            })}
            <RowA />
          </table>
        )}
      </div>
    );
  }

  useEffect(() => {

    if (search.length > 0) filter();
    else if(!checkEmpty(filterss)) setRowsSearch(rowsF)
    else setRowsSearch(rows);
  }, [search]);

  useEffect(() => {
    setMenu(app.menu);
    setEntidad(app.opciones[0]);
  }, [app]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      setUUIDReport(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModal]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalR) {
      setUUIDForm(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalR]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalReview) {
      setUUIDReview(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalReview]);

  useEffect(() => {
    setAplicaicon(entidad.path);
    pass(entidad);
  }, [entidad]);

  useEffect(() => {
    setRowsSearch(rows);
  }, [rows]);

  useEffect(() => {
    if(aplication == 'supports' || aplication == 'incidencias' || aplication == 'hoja_actividades/graficos-pruebas'){
      if(aplication == 'hoja_actividades/graficos-pruebas'){
        setRolGrafica(namesRol[0]);
      }
      return;
    }
    setRows([]);
    setForm(false);
    setLoader(true);
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(`${aplication}/get/`, (response) => {
        console.log("REQUEST APP", aplication);
        setLoader(false);
        pass(response);
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [aplication]);

  // Mauricio
  useEffect(() => {
    console.log("ENTRO?");
    if(aplication != 'hoja_actividades/graficos-pruebas'){
      return;
    }
    setSelectedUsuario({name:"",code:""})
    if (dateGrafica instanceof Date && !isNaN(dateGrafica)) {
      var mes = dateGrafica.getMonth()
      var anio = dateGrafica.getFullYear()
      var fechaString = mes + "/" + anio
    } else {
      var fechaString = ""
    }

    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    params.append("tiene_filtro",rolGrafica.tieneFiltro);
    params.append("nombre_rol",rolGrafica.filtro);
    params.append("colaborador_id",selectedUsuario.code);
    params.append("fecha", fechaString);
    params.append("week", week);
    params.append("type", rolGrafica.type);

    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        setUsuarios(response.data.usuarios)
        setBarChart(response.data.chart_bar)
        setPieChart(response.data.chart_pie)
        setRadarChart(response.data.chart_radar)
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [rolGrafica]);

  // 

// Mauricio
useEffect(() => {
  if (selectedUsuario != ""){
    if (dateGrafica instanceof Date && !isNaN(dateGrafica)) {
      var mes = dateGrafica.getMonth()
      var anio = dateGrafica.getFullYear()
      var fechaString = mes + "/" + anio
    } else {
      var fechaString = ""
    }

    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    params.append("tiene_filtro",rolGrafica.tieneFiltro);
    params.append("nombre_rol",rolGrafica.filtro);
    params.append("colaborador_id",selectedUsuario.code);
    params.append("fecha", fechaString);
    params.append("week", week);
    params.append("type", rolGrafica.type);


    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        setUsuarios(response.data.usuarios)
        setBarChart(response.data.chart_bar)
        setPieChart(response.data.chart_pie)
        setRadarChart(response.data.chart_radar)
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );

  }
}, [selectedUsuario]);





useEffect(() => {
  if (selectedUsuario != ""){


    // Verificar si es una instancia de la clase Date
    if (dateGrafica instanceof Date && !isNaN(dateGrafica)) {
      var mes = dateGrafica.getMonth()
      var anio = dateGrafica.getFullYear()
      var fechaString = mes + "/" + anio
    } else {
      var fechaString = ""
    }

    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    params.append("tiene_filtro",rolGrafica.tieneFiltro);
    params.append("nombre_rol",rolGrafica.filtro);
    params.append("colaborador_id",selectedUsuario.code);
    params.append("fecha", fechaString);
    params.append("week", week);
    params.append("type", rolGrafica.type);

    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        setUsuarios(response.data.usuarios)
        setBarChart(response.data.chart_bar)
        setPieChart(response.data.chart_pie)
        setRadarChart(response.data.chart_radar)
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );

  }
}, [dateGrafica, week]);

// 

  // Mauricio
  // useEffect(() => {

  //   var params = new FormData();
  //   params.append(
  //     "public_key",
  //     "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
  //   );
  //   params.append(
  //     "private_key",
  //     "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
  //   );
  //   params.append(
  //     "tiene_filtro",rolGrafica.tieneFiltro
  //   );
  //   params.append(
  //     "nombre_rol",rolGrafica.filtro
  //   );

  //   var jsonDate1 = null;
  //   if (fechaGrafica[0] instanceof Date) {
  //     var year = fechaGrafica[0].getFullYear(); // Obtener el año de la fecha
  //     var month = String(fechaGrafica[0].getMonth() + 1).padStart(2, '0'); // Obtener el mes de la fecha (agregar 1 ya que los meses se indexan desde 0) y agregar un cero inicial si es necesario
  //     var day = String(fechaGrafica[0].getDate()).padStart(2, '0'); // Obtener el día de la fecha y agregar un cero inicial si es necesario
  //     var jsonDate1 = `${year}-${month}-${day}`;
  //     console.log(jsonDate1);
  //   } else {
  //     console.error('El valor no es un objeto Date válido');
  //   }
  //   if (jsonDate1 === null){
  //     jsonDate1 = new Date(0);
  //     var year = jsonDate1.getFullYear(); // Obtener el año de la fecha
  //     var month = String(jsonDate1.getMonth() + 1).padStart(2, '0'); // Obtener el mes de la fecha (agregar 1 ya que los meses se indexan desde 0) y agregar un cero inicial si es necesario
  //     var day = String(jsonDate1.getDate()).padStart(2, '0'); // Obtener el día de la fecha y agregar un cero inicial si es necesario
  //     jsonDate1 = `${year}-${month}-${day}`;
  //   }
  //   params.append(
  //     "fecha_inicio", jsonDate1
  //   );

  //   var jsonDate2 = null;
  //   if (fechaGrafica[1] instanceof Date) {
  //     var year = fechaGrafica[1].getFullYear(); // Obtener el año de la fecha
  //     var month = String(fechaGrafica[1].getMonth() + 1).padStart(2, '0'); // Obtener el mes de la fecha (agregar 1 ya que los meses se indexan desde 0) y agregar un cero inicial si es necesario
  //     var day = String(fechaGrafica[1].getDate()).padStart(2, '0'); // Obtener el día de la fecha y agregar un cero inicial si es necesario
  //     var jsonDate2 = `${year}-${month}-${day}`;
  //     console.log(jsonDate2);
  //   } else {
  //     console.error('El valor no es un objeto Date válido');
  //   }
  //   if (jsonDate2 === null){
  //     jsonDate2 = new Date();
  //     var year = jsonDate2.getFullYear(); // Obtener el año de la fecha
  //     var month = String(jsonDate2.getMonth() + 1).padStart(2, '0'); // Obtener el mes de la fecha (agregar 1 ya que los meses se indexan desde 0) y agregar un cero inicial si es necesario
  //     var day = String(jsonDate2.getDate()).padStart(2, '0'); // Obtener el día de la fecha y agregar un cero inicial si es necesario
  //     jsonDate2 = `${year}-${month}-${day}`;
  //   }
  //   params.append(
  //     "fecha_fin",jsonDate2
  //   );
  //   Post(
  //     `${aplication}/get/`,
  //     (response) => {
  //       setLoader(false);
  //       setData(response.data);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       pass(error);
  //     },
  //     false,
  //     "Obteniendo datos",
  //     params
  //   );
  // }, [fechaGrafica]);

  // 

  useEffect(() => {
    pass(menu);
  }, [menu]);

  const Progress = () => {
    var steps2 = [];
    var model2 = {};
    const m = [];
    if (data)
      if (data.form_create) {
        pass(data.form_create);
        for (let i in data.form_create.fields) {
          pass(data.form_create.fields[i]);
          if (!data.form_create.fields[i].type)
            for (let k in data.form_create.fields[i].fields) {
              if (data.form_create.fields[i].fields[k].type == "select")
                model2[data.form_create.fields[i].fields[k].id] = [];
              else model2[data.form_create.fields[i].fields[k].name] = "";
            }
          else model2[data.form_create.fields[i].id] = [];
        }

        for (let i in data.form_create.fields) {
          steps2.push({
            label: data.form_create.fields[i].name,
            name: data.form_create.fields[i].name,
            content: (
              <Group
                data={data}
                i={i}
                model2={model2}
                m={m}
                field={data.form_create.fields[i]}
              />
            ),
          });
        }
      }
    return (
      <StepProgressBar
        startingStep={0}
        wrapperClass={"w100"}
        submitBtnName={`Guardar ${entidad.print}`}
        nextBtnName={"Siguiente"}
        previousBtnName={"Anterior"}
        onSubmit={() => {
          pass(m[0]);

          m[0]["public_key"] =
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L";
          m[0]["private_key"] =
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu";

          pass(m[0]);
          const requestOptions = {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(m[0]),
          };
          try {
            fetch(`${baseURL}/${data.app}/create2/`, requestOptions)
              .then((response) => {
                return new Promise((resolve) =>
                  response.json().then((json) =>
                    resolve({
                      status: response.status,
                      ok: response.ok,
                      json,
                    })
                  )
                );
              })
              .then(({ status, json, ok }) => {
                var message = "";
                if (!ok) message = json.detail.message;

                if (ok) {
                  toast.success(`${entidad.print} Agregado`, { theme: "dark" });
                  setRows(json.data);
                } else toast.error(`${status} ${message}`, { theme: "dark" });
              });
          } catch {}
        }}
        steps={steps2}
      />
    );
  };
  useEffect(() => {}, [data]);

  if (data != null)
    return (
      <div className="container">
        {uuidReport && (
          <ModalReporte setToggleModal={setToggleModal} uuid={uuidReport} user={user} />
        )}
        {uuidForm && (
          <ModalEdit
            setToggleModal={setToggleModalR}
            uuid={uuidForm}
            entidad={entidad}
            setRows={setRows}
          />
        )}
        <div className="w100">
          <div className="row w100 pad10 bar hcenter vcenter  ">
            <div className="mxw1000px row vcenter hspace">
              <img
                src={Logotipo}
                alt="inicio"
                width={40}
                className={"pointier"}
              />
              <Icon
                icon={"material-symbols:logout-rounded"}
                style={{ fontSize: 40, color: "#fff" }}
                className={"pointier"}
                onClick={() => setUser(null)}
              />
            </div>
          </div>
          <div className="row pad20 wrap">
            <div className="row w100 hcenter">
              <div className="mxw1000px row vcenter hspace mtop25">
                <h2 className="fontSizeLg lighter ">
                  Bienvenido{" "}
                  <span className="bold">
                    {user.nombre} {user.apellido_p}
                  </span>
                </h2>
              </div>
            </div>

            <div className="row w100 hcenter mtop25">
              <Zoom cascade>
                <div className="mxw800px row vcenter hspace mtop25">
                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[0]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[0].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={Actividades} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Hojas de Actividades
                    </div>
                  </div>

                  <div
                    className={`menu_option_hover pointier w100 row wrap`}
                    onClick={() => {
                      setApp(menus[1]);
                    }}
                  >
                    <div className="row hcenter w100">
                      <div
                        className={`menu_option pointier ${
                          menu == menus[1].menu ? "active" : ""
                        }`}
                      >
                        <ReactSVG src={Asignaciones} />
                      </div>
                    </div>
                    <div
                      className="row hcenter mtop15 bold w100 t_center"
                      style={{ height: 30 }}
                    >
                      Gestión de Asignaciones y Proyectos
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>

            <div className="row w100 hcenter mtop25">
              <div className="mxw1000px row vcenter">
                <h3 className="fontSizeMd ">{app.name}</h3>
              </div>
            </div>

            <div className="row w100 hcenter mtop25">
              <div className="mxw1000px row vcenter">
                <p className="mxw800px">{app.description}</p>
              </div>
            </div>

            <div className="row w100 hcenter">
              <div className="mxw1000px row vcenter mtop25 hspace">
                <div className="row vcenter">
                  {data.filter ? (
                    <Filter
                      setFilters={setFilters}
                      rows={rows}
                      filters={data.filter}
                      rowsSearch={rowsSearch}
                      setRowsSearch={setRowsSearch}
                      filterss={filterss}
                      search={search}
                      setRowsF={setRowsF}
                    />
                  ) : (
                    ""
                  )}

                  {app.opciones.map((opcion) => (
                    <div
                      className={`btn_2 row vcenter pointier relative ${
                        aplication == opcion.path && !form ? "active" : ""
                      }`} onClick={() => { 
                        if(opcion.module != 'module'){
                          setEntidad(opcion);
                          setForm(false);
                        }else{
                          setEntidad(null);
                          setForm(null);
                          setModule(opcion.module);
                        }
                      } }
                    >
                      {opcion.name}
                    </div>
                  ))}
                </div>
                {entidad.add && (
                  <div
                    className={`btn_2 pointier row vcenter ${
                      form ? "active" : ""
                    }`}
                    onClick={() => {
                      setForm(!form);
                    }}
                  >
                    Agregar {entidad.print}
                  </div>
                )}
              </div>
            </div>
            {entidad.tipo === "tabla" && (
              <div className="row w100 hcenter">
                <div className="mxw1000px row vcenter">
                  {/* <Actions setSearch={setSearch} search={search} /> */}
                </div>
              </div>
            )}
      
            {/* Mauricio */}
            {entidad.tipo === "grafica" && (
              <div className="row w100 hcenter">
                <div className="mxw1000px row vcenter mtop25" style={{flexWrap: "wrap"}}>
                  {namesRol.map((r) => (
                    <div className={`btn_2 row vcenter pointier relative ${rolGrafica.nombre === r.nombre  ? "active" : ""}`}
                      onClick={() => { setRolGrafica(r) }}>
                      {r.nombre}
                    </div>
                  ))}
                  <Dropdown value={selectedUsuario} onChange={(e) => setSelectedUsuario(e.value)}
                    options={usuarios} optionLabel="name" placeholder="Usuarios" className="w-full md:w-14rem" style={{marginLeft:"20px"}} />
                </div>
                </div>
            )}

            {/* Mauricio */}
            {entidad.tipo === "grafica" && (
              <div className="row w100 hcenter">
                <div className="mxw1000px row vcenter mtop25" style={{marginLeft:"30px"}}>
                    
                    <Calendar value={dateGrafica} onChange={(e) => setDateGrafica(e.value)} view="month" dateFormat="mm/yy" placeholder="Periodo" />
                    
                    <Dropdown value={week} onChange={(e) => setWeek(e.value)}
                      options={optionWeeks} optionLabel="label" placeholder="Semana" className="w-full md:w-14rem" style={{marginLeft:"20px"}} />

                    <div className={`btn_2 row vcenter pointier relative`} onClick={() => { setDateGrafica(""); setWeek(null); }} >
                      RESETEAR FECHA
                    </div>

                </div>
              </div>
            )}

            {entidad.tipo === "grafica" && barChart && pieChart && radarChart &&(
              <div className="row w100 hcenter mtop25">
                <TabView>
                    <TabPanel header="Barras">
                      <div className={`row w100 hcenter mtop25`}>
                        <ReactApexChart options={barChart.options} series={barChart.serie} type="bar" height={500} width={1000} /> 
                      </div> 
                    </TabPanel>
                    <TabPanel header="Pie">
                      <div className={`row w100 hcenter mtop25`}>
                        <ReactApexChart options={pieChart.options} series={pieChart.serie} type="pie" height={500} width={1000} /> 
                      </div> 
                    </TabPanel>
                    <TabPanel header="Treemap">
                      <div className={`row w100 hcenter mtop25`}>
                        <ReactApexChart options={radarChart.options} series={radarChart.serie} type="treemap" height={500} width={1000} /> 
                      </div> 
                    </TabPanel>
                </TabView>
              </div>
            )}

            {/* Mauricio */}
            {entidad.tipo === "tabla" && (
              <div className="row w100 hcenter mtop25">
                <div className="row mxw1000px relative ">
                  {entidad.add && data.form_create && (
                    <Fade right collapse when={form}>
                      {form && (
                        <div className="w100 absolute">
                          <div className="row w100 wrap">
                            <div className="row w100 hcenter">
                              <Progress />
                            </div>
                          </div>
                        </div>
                      )}
                    </Fade>
                  )}

                  <Fade left when={!form}>
                    {loader ? (
                      <div className="box_c w100 absolute">
                        <div className="table_header pad15 row fontSizeSmr">
                          <span className="white w100 row">
                            <Icon icon={"ph:dots-three-outline-fill"} />
                          </span>
                          <span className="white w100 row">
                            <Icon icon={"ph:dots-three-outline-fill"} />
                          </span>
                          <span className="white w100 row">
                            <Icon icon={"ph:dots-three-outline-fill"} />
                          </span>
                          <span className="white w100 row">
                            <Icon icon={"ph:dots-three-outline-fill"} />
                          </span>
                        </div>
                        <div className="row w100 hcenter pad15 vcenter">
                          <ThreeCircles
                            height="43"
                            width="43"
                            color="#0061FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                          />
                        </div>
                      </div>
                    ) : (rows != null) & (rows?.length > 0) ? (
                      !form && (
                        <table
                          className="box_c w100 absolute"
                          cellPadding={0}
                          cellSpacing={0}
                        >
                          <thead className="table_header pad15">
                            <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                              {Object.keys(rows[0]).map(
                                (key) =>
                                  key != "uuid" &&
                                  key != "options" &&
                                  key != "ready" && (
                                    <td className="column" key={key}>
                                      {key}
                                    </td>
                                  )
                              )}
                              <td className="column"></td>
                            </tr>
                            <tr className="table_header w100 white">
                              <td className="column"><input type="text" className="w100" onChange={(e) => { setEmployee(e.target.value) }}/></td>
                              <td className="column"><input type="text" className="w100" onChange={(e) => { setClient(e.target.value) }}/></td>
                              <td className="column"><input type="text" className="w100" onChange={(e) => { setAssignment(e.target.value) }}/></td>
                              <td className="column"><input type="text" className="w100" onChange={(e) => { setDate(e.target.value) }}/></td>
                              <td className="column"><input type="text" className="w100" onChange={(e) => { setStatus(e.target.value) }}/></td>
                              <td className="column"></td>
                            </tr>
                          </thead>
                          <Zoom cascade>
                            <tbody>
                              {rowsSearch.map((row) => (
                                <tr className="row_table">
                                  {Object.keys(row).map(
                                    (key) =>
                                      key != "uuid" &&
                                      key != "options" &&
                                      key != "ready" &&
                                      (key == "Estatus de reporte" ? (
                                        <td className={`column`}>
                                          <span
                                            className={`${row[
                                              key
                                            ].toLowerCase()}`}
                                            style={{
                                              maxWidth: 100,
                                              margin: 0,
                                              margin: "auto",
                                            }}
                                          >
                                            {row[key]}
                                          </span>{" "}
                                        </td>
                                      ) : key == "Estado" ? (
                                        <td className={`column row`}>
                                          <span
                                            className={`${row[
                                              key
                                            ].toLowerCase()}`}
                                          >
                                            {row[key]}
                                          </span>{" "}
                                        </td>
                                      ) : key == "Contrato" ? (
                                        <td className={`column`}>
                                          {row[key] == "Sin contrato" ? (
                                            row[key]
                                          ) : (
                                            <a
                                              href={`https://portal.eks-solutions.com:8000${row[key]}`}
                                              target="_blank"
                                              style={{ textDecoration: "none" }}
                                            >
                                              Abrir
                                            </a>
                                          )}
                                        </td>
                                      ) : (
                                        <td className={`column`}>{row[key]} </td>
                                      ))
                                  )}
                                  <td className="column">
                                    <div className="row">
                                      {entidad.path == "hoja_actividades" && (
                                        <>
                                          <Icon
                                            icon={"ic:baseline-remove-red-eye"}
                                            className="fontSizeLg pointier hover_green"
                                            onClick={() => {
                                              setUUIDReport(row["uuid"]);
                                              setToggleModal(true);
                                            }}
                                          />
                                          <Icon icon={"fluent:document-settings-20-regular"} className="fontSizeLg pointier hover_green"
                                            onClick={() => {
                                              setUUIDReview(row["uuid"]);
                                              setToggleModalReview(true);
                                            }}
                                          />
                                        </>
                                      )}
                                      {entidad.path != "hoja_actividades" && (
                                        <Icon
                                          icon={
                                            "material-symbols:edit-square-outline-rounded"
                                          }
                                          className="fontSizeLg pointier hover_green"
                                          onClick={() => {
                                            setToggleModalR(true);
                                            setUUIDForm(row["uuid"]);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Zoom>
                        </table>
                      )
                    ) : (
                      <div className="table_header pad15 row vcenter hcenter white bold fontSizeSm w100">
                        Sin registros
                      </div>
                    )}
                  </Fade>
                </div>
              </div>
            )}
            { entidad?.path == 'incidencias' && (<Incidences />) }
            { entidad?.path == 'supports' && (<Support />) }
          </div>
        </div>
        {uuidReview && (
          <ModalActivities toggleModalReview={toggleModalReview} setToggleModalReview={setToggleModalReview} uuid={uuidReview} user={user} Post={Post}/>
        )}
      </div>
    );
}