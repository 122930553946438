import Logotipo from "./../static/images/Logotipo.svg";
import { Icon } from "@iconify/react";
import useCollapse from "react-collapsed";
import { useState, useEffect } from "react";
import Bounce from "react-reveal/Bounce";
import Filter from "./snippets/Module/Filter";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ThreeCircles } from "react-loader-spinner";
import { Zoom } from "react-reveal";
import ModalHoras from "./snippets/MoldaHoras";
import ModalReporte from "./snippets/MoldaReporte";
import ModalDocumento from "./snippets/ModalDocumento";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import SubirCertificado from "./Modules/Conocimiento/Certificaciones/Modales/SubirCertificado";
import ProcesosComponent from "./Modules/Conocimiento/Procesos/Components/Procesos";
import { default as Support } from "./Modules/Support/index";
import ModalActivities from "./Modules/PMO/ModalActivities";
import { toast } from "react-toastify";
import Graphics from "./Modules/Employee/Graphics";

const getUser = () => JSON.parse(localStorage.getItem('user'));

function RenderTable({ 
  rows,
  aplication,
  rowsSearch,
  redirigirALink,
  setUUIDReport,
  Post,
  setRows,
  setData,
  setLoader,
  setToggleModal,
  setToggleModalDocumento,
  setUUIDDocumento,
  setUUIDReview,
  setVentanaCertificado,
  setCertificadoSeleccionado,
  setToggleModalReporte,
  setToggleModalReview,
  setUUID}) {

  const [user, setUser] = useState(getUser());

  const [employee, setEmployee] = useState("");
  const [client, setClient] = useState("");
  const [assignment, setAssignment] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [itemsOrigin, setItemsOrigin] = useState(rows);

  useEffect(() => {
    filter()
  },[employee, client, assignment, date, status]);

  function filter() {
    if (user.rol.name != 'PMO'){
      return;
    }
    var arraySe = [];
    var rowm = itemsOrigin
    for (let row in rowm) {
      // typeof(Object.values(rowm[row])[value]) == "string" &&
      let values = Object.values(rowm[row]);
      if (
        values[1].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(employee.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[2].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(client.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[3].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(assignment.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[4].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(date.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[5].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(status.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase())){
        if (!arraySe.includes(rowm[row])) arraySe.push(rowm[row]);
      }
    }
    setRows(arraySe);
  }

  return (
    (rows != null) & (rows.length > 0) ? (
      aplication != "periodos" ? (
        rows[0].periodos ? (<div className="box_c w100">
          <div className="table_header pad15 row fontSizeSmr">
            <span className="white w100 row">
              <Icon icon={"ph:dots-three-outline-fill"} />
            </span>
            <span className="white w100 row">
              <Icon icon={"ph:dots-three-outline-fill"} />
            </span>
            <span className="white w100 row">
              <Icon icon={"ph:dots-three-outline-fill"} />
            </span>
            <span className="white w100 row">
              <Icon icon={"ph:dots-three-outline-fill"} />
            </span>
          </div>
          <div className="row w100 hcenter pad15 vcenter">
            <ThreeCircles
              height="43"
              width="43"
              color="#0061FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>)
          :
          (<table className="box_c w100" cellPadding={0} cellSpacing={0}>

            <thead className="table_header pad15">
              <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                {Object.keys(rows[0]).map(
                  (key) =>
                    key != "uuid" &&
                    key != "uuid_certificacion" &&
                    key != "uuid_relacion" &&
                    key != "uuid documento" &&
                    key != "path" &&
                    key != "path documento" &&
                    key != "options" &&
                    key != "ready" &&
                    (key == "Estatus de reporte" ? (
                      <td className="column t_center" key={key}>
                        {key}
                      </td>
                    ) : (
                      <td className="column" key={key}>
                        {key}
                      </td>
                    ))
                )}
                <td className="column">
                  {aplication == "periodos" ? "Editar" : ""}
                </td>
              </tr>
              { user.rol.name == "PMO" && (
              <tr className="table_header w100 white">
                <td className="column"><input type="text" className="w100" onChange={(e) => { setEmployee(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setClient(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setAssignment(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setDate(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setStatus(e.target.value) }}/></td>
                <td className="column"></td>
              </tr>
              ) }
            </thead>
            <Zoom cascade>
              <tbody>
                {rowsSearch.map((row) => (

                  <tr className="row_table">

                    {Object.keys(row).map(
                      (key) =>
                        key != "uuid" &&
                        key != "uuid documento" &&
                        key != "uuid_certificacion" &&
                        key != "uuid_relacion" &&
                        key != "path" &&
                        key != "path documento" &&
                        key != "options" &&
                        key != "ready" &&
                        (key == "Estatus de Reporte" ? (
                          <td className={`column `}>
                            <ProgressBar now={row[key]} />{" "}
                          </td>
                        ) : key == "Estatus de reporte" ? (
                          <td className={`column`}>
                            <span
                              className={`${row[
                                key
                              ].toLowerCase()} row hcenter`}
                              style={{
                                maxWidth: 100,
                                margin: 0,
                                margin: "auto",
                              }}
                            >
                              {row[key]}
                            </span>{" "}
                          </td>
                        ) : key == "Examen" ? (
                          <td className="column" key={key}>
                            <Button
                              tooltip="Link Examen"
                              onClick={() => {
                                redirigirALink(
                                  row[key]
                                );
                              }}
                              icon="fa-solid fa-paper-plane"
                              className="btn_calificar"
                              style={{ fontSize: "18px" }}
                              text
                              raised
                            />
                          </td>
                        ) : key == "Curso" ? (
                          <td className="column" key={key}>
                            <Button
                              tooltip="Link Curso"
                              onClick={() => {
                                redirigirALink(
                                  row[key]
                                );
                              }}
                              icon="fa-solid fa-link"
                              className="btn_calificar"
                              style={{ fontSize: "18px" }}
                              severity="info"
                              text
                              raised
                            />
                          </td>
                        ) : (
                          <td className={`column`}>{row[key]} </td>
                        ))
                    )}
                    <td className={`column`}>
                      {aplication == "hoja_actividades" && user.rol.name != "PMO" &&
                      (!row["ready"] ? (
                          <>
                            <Icon icon={"ic:baseline-remove-red-eye"} className="fontSizeLg pointier hover_green"
                              onClick={() => {
                                setUUIDReport(row["uuid"]);
                                setToggleModalReporte(true);
                              }}
                            />
                            { 
                            (row["Estatus de reporte"] != "Enviado") ? (
                              <Icon icon={"ri:send-plane-fill"} style={{ margin: 5 }} className="fontSizeMd pointier hover_green"
                                onClick={() => {
                                  toast.loading("Enviando", { toastId: "Enviando",theme:"dark" });
                                  var params = new FormData();
                                  params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
                                  params.append("uuid", row.uuid);
                                  params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
                                  Post(`${aplication}/send/`, (response) => {
                                      if(response?.data?.status_code == 400){
                                        toast.update("Enviando", {
                                          render: response?.data?.detail.message,
                                          type: "warning",
                                          isLoading: false,
                                          autoClose: true,
                                          toastId: "Enviando",
                                          theme:'dark'
                                        });
                                        return;
                                      }else{
                                        toast.update("Enviando", {
                                          render: "Enviado",
                                          type: "success",
                                          isLoading: false,
                                          autoClose: true,
                                          toastId: "Enviando",
                                          theme:'dark'
                                        });
                                      }
                                      setLoader(false);
                                      pass(response);
                                      setRows(response.data.data);
                                      setData(response.data);
                                    },
                                    (error) => {
                                      setLoader(false);
                                      pass(error);
                                    },
                                    false,
                                    "Enviando",
                                    params
                                  );
                                }}
                              />
                            ) : (<></>)
                            }
                          </>
                        ) : (
                          <>
                          <Icon icon={"ic:baseline-remove-red-eye"} className="fontSizeLg pointier hover_green" onClick={() => {
                            setUUIDReport(row["uuid"]);
                            setToggleModalReporte(true);
                          }} />
                          </>
                        )
                      )
                      
                      }

                      {aplication == "hoja_actividades" && user.rol.name == "PMO" &&
                        (
                          <>
                            <Icon icon={"ic:baseline-remove-red-eye"} className="fontSizeLg pointier hover_green"
                              onClick={() => {
                                setUUIDReport(row["uuid"]);
                                setToggleModalReporte(true);
                              }}
                            />
                            <Icon icon={"fluent:document-settings-20-regular"} className="fontSizeLg pointier hover_green"
                              onClick={() => {
                                setUUIDReview(row["uuid"]);
                                setToggleModalReview(true);
                              }}
                            />
                          </>
                        )
                      }

                      {aplication == "periodos" && (
                        <Icon
                          icon={"material-symbols:edit-outline-rounded"}
                          style={{}}
                          className="fontSizeMd pointier hover_green"
                          onClick={() => {
                            setToggleModal(true);
                            setUUID(row["uuid"]);
                          }}
                        />
                      )}
                      {aplication == "documentos" && (
                        <Icon
                          icon={"material-symbols:edit-outline-rounded"}
                          style={{}}
                          className="fontSizeMd pointier hover_green"
                          onClick={() => {
                            setToggleModalDocumento(true);
                            setUUIDDocumento(row);
                          }}
                        />
                      )}
                      {aplication == "certificaciones_usuario" && (
                        <Icon
                          icon={"material-symbols:upload"}
                          style={{}}
                          className="fontSizeMd pointier hover_green"
                          onClick={() => {
                            setVentanaCertificado(true);
                            setCertificadoSeleccionado(row)
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Zoom>
          </table>)
      ) : rows[0]?.periodos && rowsSearch[0]?.periodos ? (
        <table className="box_c w100" cellPadding={0} cellSpacing={0}>
          <thead className="table_header pad15">
            <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
              {Object.keys(rows[0].periodos[0]).map(
                (key) =>
                  key != "number" &&
                  key != "uuid" &&
                  key != "options" &&
                  key != "ready" &&
                  (key == "Estatus de reporte" ? (
                    <td className="column t_center" key={key}>
                      {key}
                    </td>
                  ) : (
                    <td className="column" key={key}>
                      {key}
                    </td>
                  ))
              )}
              <td className="column">
                {aplication == "periodos" ? "Editar" : ""}
              </td>
            </tr>
          </thead>
          <Zoom cascade>
            <tbody>
              {rowsSearch.map((row) => (
                <>
                  <tr className="row_table">
                    <td colSpan={5} style={{ textAlign: "center", padding: 20, background: "rgb(9, 31, 83)", color: "white", fontWeight: 900 }}>{row.Mes}</td>
                  </tr>
                  {row.periodos.map((periodo) => (
                    <>
                      <tr className="row_table">
                        {Object.keys(periodo).map(
                          (key) =>
                            key != "number" &&
                            key != "uuid" &&
                            key != "options" &&
                            key != "ready" &&
                            (key == "Estatus de Reporte" ? (
                              <td className={`column `}>
                                <ProgressBar now={periodo[key]} />{" "}
                              </td>
                            ) : key == "Estatus de reporte" ? (
                              <td className={`column`}>
                                <span
                                  className={`${periodo[
                                    key
                                  ].toLowerCase()} row hcenter`}
                                  style={{
                                    maxWidth: 100,
                                    margin: 0,
                                    margin: "auto",
                                  }}
                                >
                                  {periodo[key]}
                                </span>{" "}
                              </td>
                            ) : (
                              <td className={`column`}>
                                {periodo[key]}{" "}
                              </td>
                            ))
                        )}
                        <td className={`column`}>
                          {aplication == "hoja_actividades" &&
                            (!periodo["ready"] ? (
                              <Icon
                                icon={"ic:baseline-remove-red-eye"}
                                className="fontSizeLg pointier hover_green"
                                onClick={() => {
                                  setUUIDReport(periodo["uuid"]);
                                  setToggleModalReporte(true);
                                }}
                              />
                            ) : (
                              <Icon
                                icon={"ic:baseline-remove-red-eye"}
                                className="fontSizeLg pointier hover_green"
                                onClick={() => {
                                  setUUIDReport(periodo["uuid"]);
                                  setToggleModalReporte(true);
                                }}
                              />
                            ))}

                          {aplication == "periodos" && (
                            <Icon icon={ "material-symbols:edit-outline-rounded"} className="fontSizeMd pointier hover_green"
                              onClick={() => {
                                setToggleModal(true);
                                setUUID(periodo["uuid"]);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
            </tbody>
          </Zoom>
        </table>
      ) : (
        ""
      )
    ) : aplication === "documentos" ? (
      <div className="table_header pad15 vcenter row hcenter white bold fontSizeSm">
        No tienes ningun documento asignado
      </div>
    ) : aplication === "certificaciones_usuario" ? (
      <div className="table_header pad15 vcenter row hcenter white bold fontSizeSm">
        No tienes ninguna certificacion asignada
      </div>
    ) : user.rol.name == "PMO" ? (
      
          <table className="box_c w100" cellPadding={0} cellSpacing={0}>
            <thead className="table_header pad15">
              <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                <td className="column">Empleado</td>
                <td className="column">Cliente</td>
                <td className="column">Aignación</td>
                <td className="column">Fecha</td>
                <td className="column">Estatus de reporte</td>
                <td className="column"></td>
              </tr>
              <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                <td className="column"><input type="text" className="w100" onChange={(e) => { setEmployee(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setClient(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setAssignment(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setDate(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setStatus(e.target.value) }}/></td>
                <td className="column"></td>
              </tr>
            </thead>
            <tbody>
            {rowsSearch.map((row) => (
              <tr className="row_table">
                <td className="column">{row['Empleado']}</td>
                <td className="column">{row['Cliente']}</td>
                <td className="column">{row['Asignacion']}</td>
                <td className="column">{row['Fecha']}</td>
                <td className="column">{row['Status']}</td>
                <td className="column">

                </td>
              </tr>
            ))}
            </tbody>
          </table>
        
    ) : (
      <div className="table_header pad15 vcenter row hcenter white bold fontSizeSm">
        Felicidades, no tienes hojas de actividades pendientes
      </div>
    )
  )
}

function pass() { }
export default function Empleado2({ setUser, user, Post }) {
  const aplicaciones = [
    {
      aplication: "graphics",
      name: "Gráficas",
      component: () => <Graphics setLoader={setLoader} Post={Post}/>
    },
    {
    aplication: "hoja_actividades",
    name: "Hoja de actividades",
    component: () => <RenderTable 
    rows={rows}
    aplication={aplication}
    rowsSearch={rowsSearch}
    redirigirALink={redirigirALink}
    setUUIDReport={setUUIDReport}
    Post={Post}
    setRows={setRows}
    setData={setData}
    setLoader={setLoader}
    setToggleModal={setToggleModal}
    setToggleModalDocumento={setToggleModalDocumento}
    setUUIDDocumento={setUUIDDocumento}
    setUUIDReview={setUUIDReview}
    setVentanaCertificado={setVentanaCertificado}
    setCertificadoSeleccionado={setCertificadoSeleccionado}
    setToggleModalReporte={setToggleModalReporte}
    setToggleModalReview={setToggleModalReview}
    setUUID={setUUID} />
  },
  {
    aplication: "periodos",
    name: "Registrar actividades",
    component: () => <RenderTable 
    rows={rows}
    aplication={aplication}
    rowsSearch={rowsSearch}
    redirigirALink={redirigirALink}
    setUUIDReport={setUUIDReport}
    Post={Post}
    setRows={setRows}
    setData={setData}
    setLoader={setLoader}
    setToggleModal={setToggleModal}
    setToggleModalDocumento={setToggleModalDocumento}
    setUUIDDocumento={setUUIDDocumento}
    setVentanaCertificado={setVentanaCertificado}
    setCertificadoSeleccionado={setCertificadoSeleccionado}
    setToggleModalReporte={setToggleModalReporte}
    setUUID={setUUID} />
  },
  {
    aplication: "documentos",
    name: "Documentos",
    component: () => <RenderTable 
    rows={rows}
    aplication={aplication}
    rowsSearch={rowsSearch}
    redirigirALink={redirigirALink}
    setUUIDReport={setUUIDReport}
    Post={Post}
    setRows={setRows}
    setData={setData}
    setLoader={setLoader}
    setToggleModal={setToggleModal}
    setToggleModalDocumento={setToggleModalDocumento}
    setUUIDDocumento={setUUIDDocumento}
    setVentanaCertificado={setVentanaCertificado}
    setCertificadoSeleccionado={setCertificadoSeleccionado}
    setToggleModalReporte={setToggleModalReporte}
    setUUID={setUUID} />
  },
  {
    aplication: "certificaciones_usuario",
    name: "Certificaciones",
    component: () => <RenderTable 
    rows={rows}
    aplication={aplication}
    rowsSearch={rowsSearch}
    redirigirALink={redirigirALink}
    setUUIDReport={setUUIDReport}
    Post={Post}
    setRows={setRows}
    setData={setData}
    setLoader={setLoader}
    setToggleModal={setToggleModal}
    setToggleModalDocumento={setToggleModalDocumento}
    setUUIDDocumento={setUUIDDocumento}
    setVentanaCertificado={setVentanaCertificado}
    setCertificadoSeleccionado={setCertificadoSeleccionado}
    setToggleModalReporte={setToggleModalReporte}
    setUUID={setUUID} />
  },
  {
    aplication: "proceso",
    name: "Procesos",
    component: () => <ProcesosComponent />
  },
  {
    aplication: "supports",
    name: "Rep. Soporte",
    component: () => <Support />
  }]
  const [isExpanded, setExpanded] = useState(false);
  const [showText, setShowText] = useState(false);
  const [loader, setLoader] = useState(true);
  const [notificaciones, setNotificaciones] = useState(user.notificaciones);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [uuid, setUUID] = useState(null);
  const [rowsSearch, setRowsSearch] = useState([]);
  const [uuidReport, setUUIDReport] = useState(null);
  const [update, setUpdate] = useState({});
  const [rowsF, setRowsF] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalReporte, setToggleModalReporte] = useState(false);
  const [toggleModalReview, setToggleModalReview] = useState(false);
  const [search, setSearch] = useState("");
  const [filterss, setFilters] = useState({});
  const [aplication, setAplicaicon] = useState("graphics");
  const [toggleModalDocumento, setToggleModalDocumento] = useState(false);
  const [uuidDocumento, setUUIDDocumento] = useState(null);
  const [uuidReview, setUUIDReview] = useState(null);
  const [ventanaCertificado, setVentanaCertificado] = useState(false);
  const [certificadoSeleccionado, setCertificadoSeleccionado] = useState(null);

  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModal) {
      setUUID(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModal]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalReporte) {
      setUUIDReport(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalReporte]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalReview) {
      setUUIDReport(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [toggleModalReview]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!toggleModalDocumento) {
      setUUIDDocumento(null);
      bodyClassList.remove("open");
      bodyClassList.add("closed");
    } else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }

    setRows([])
    setData(null)
    setLoader(true);
    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    Post(
      `${aplication}/get/`,
        (response) => {
        setLoader(false);
        pass(response);
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [toggleModalDocumento]);
  //

  useEffect(() => {
    if(aplication == "supports" || aplication == "graphics"){
      return;
    }
    setRows([])
    setData(null)
    setLoader(true);
    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    Post(
      `${aplication}/get/`,
        (response) => {
        setLoader(false);
        pass(response);
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [aplication]);

  useEffect(() => {
    setLoader(true);
    var params = new FormData();
    params.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    params.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );
    Post(
      `${aplication}/get/`,
      (response) => {
        setLoader(false);
        setToggleModal(false);
        pass(response);
        setRows(response.data.data);
        setData(response.data);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [update]);

  useEffect(() => {

  }, [data]);

  function redirigirALink(link) {
    // const fileInput = document.createElement('a');
    // fileInput.href = link;
    // fileInput.click();
    window.open(link, "_blank");
  }

  useEffect(() => { }, [rowsSearch]);

  useEffect(() => {
    pass(notificaciones);
  }, [notificaciones]);

  useEffect(() => {
    pass(filterss);
  });

  useEffect(() => {

    setRowsSearch(rows);
  }, [rows]);


  return (
    <div className="container">

      {uuidReport && (
        <ModalReporte
          setToggleModal={setToggleModalReporte}
          uuid={uuidReport}
          user={user}
        />
      )}
      {uuid && (
        <ModalHoras
          setToggleModal={setToggleModal}
          uuid={uuid}
          Post={Post}
          setUpdate={setUpdate}
          user={user}
        />
      )}
      {uuidDocumento && (
        <ModalDocumento
          setToggleModal={setToggleModalDocumento}
          uuid={uuidDocumento}
          user={user}
        />
      )}
      {uuidReview && (
        <ModalActivities toggleModalReview={toggleModalReview} setToggleModalReview={setToggleModalReview} uuid={uuidReview} user={user} Post={Post}/>
      )}
      <div className="w100">
        <div className="row w100 pad10 bar hcenter vcenter  ">
          <div className="mxw1000px row vcenter hspace">
            <img
              src={Logotipo}
              alt="inicio"
              width={40}
              className={"pointier"}
            />
            <Icon
              icon={"material-symbols:logout-rounded"}
              style={{ fontSize: 40, color: "#fff" }}
              className={"pointier"}
              onClick={() => setUser(null)}
            />
          </div>
        </div>
        <div className="row pad20 wrap">
          <div className="row w100 hcenter">
            <div className="mxw1000px row vcenter hspace mtop25">
              <h2 className="fontSizeLg lighter ">
                Bienvenido{" "}
                <span className="bold">
                  {user.nombre} {user.apellido_p}
                </span>
              </h2>
            </div>
          </div>


          <div className="row w100 hcenter mtop25">
            <div className="mxw1000px row vcenter hspace">
              <div className="box_c w100">
                <div
                  className="box_header w100 white bold hspace vcenter pointier"
                  {...getToggleProps({
                    style: { display: "flex" },
                    onClick: () => {
                      setExpanded((x) => !x);
                    },
                  })}
                >
                  <p className="fontSizeSm">Notificaciones</p>
                  <div className={`icon_box ${isExpanded ? "active" : ""}`}>
                    <Icon
                      className="row center dropw fontSizeLg"
                      icon={"ri:arrow-drop-down-line"}
                    />
                  </div>
                </div>

                <div className="padlr20" {...getCollapseProps()}>
                  {notificaciones.length > 0 ? (
                    notificaciones.map((notificacion) => (
                      <div className="row w100 notification hspace">
                        <p>
                          {notificacion.created.substring(0, 10)} -{" "}
                          {notificacion.message}{" "}
                          {notificacion.type == "llenar" && (
                            <span
                              className="bold pointier"
                              style={{
                                color: "#1461FF",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                setAplicaicon("periodos");
                                setToggleModal(true);
                                setUUID(notificacion.uuid_redirect);
                              }}
                            >
                              Llenalas ahora
                            </span>
                          )}
                          {notificacion.type == "firma_documento" && (
                            <span
                              className="bold pointier"
                              style={{
                                color: "#1461FF",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                setAplicaicon("documentos");
                                setToggleModalDocumento(true);
                                rowsSearch.map((row) => (
                                  row.uuid === notificacion.uuid_redirect ? setUUIDDocumento(row) : null
                                ))
                              }}
                            >
                              Revisalo ahora
                            </span>
                          )}
                        </p>
                        <span
                          className="row vcenter pointier"
                          onClick={() => {
                            for (let i in notificaciones) {
                              if (notificacion == notificaciones[i]) {
                                let cpArray = notificaciones.filter(
                                  (notificacionO) =>
                                    notificacionO != notificaciones[i]
                                );
                                setNotificaciones(cpArray);
                              }
                            }
                            var params = new FormData();
                            params.append(
                              "public_key",
                              "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                            );
                            params.append(
                              "private_key",
                              "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                            );
                            params.append("uuid", notificacion.uuid);
                            Post(
                              `notification/read/`,
                              (response) => { },
                              (error) => { },
                              false,
                              "Obteniendo datos",
                              params
                            );
                          }}
                        >
                          Archivar
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="row w100 notification hspace">
                      <p>No tienes notificaciones</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>



          <div className="row w100 hcenter mtop15">
            <div className="mxw1000px row vcenter mtop15">
              <h3 className="fontSizeMd ">Mis Proyectos</h3>
              <div className="relative h100">
                <div className="absolute">
                  <div className="row vcenter">
                    <Icon
                      icon={"material-symbols:help"}
                      className={`fontSizeSmmr pointier ${showText ? "c_active" : ""
                        }`}
                      style={{ marginLeft: 5, top: 0 }}
                      onClick={() => setShowText(!showText)}
                    />

                    <Bounce when={showText}>
                      <div className="info_box fontSizeSmr">
                        Esta lista muestra los proyectos a los que has sido
                        asignado por la empresa. El llenado de tus hojas nos
                        ayuda grandemente a poder generar tu nómina. Ayúdanos a
                        lograrlo a tiempo, nuestros días de corte son los{" "}
                        <span className="bold">días 15 y 25 de cada mes.</span>
                      </div>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row w100 hcenter">
            <div className="mxw1000px row vcenter mtop15">
              <p className="fontSizeSm mxw800px">
                En la siguiente lista encontrarás las entradas a los{" "}
                <span className="bold">
                  reportes de actividades por proyecto
                </span>{" "}
                que tienes asignados. Tu tarea consiste en llenarlos por día
                para cumplir la <span className="bold">cuota del mes</span>.
              </p>
            </div>
          </div>
          <div className="row w100 hcenter mtop15 wrap">
            <div className="mxw1000px row vcenter mtop25">
              {data != null && data.filter && (
                <Filter
                  setFilters={setFilters}
                  rows={rows}
                  filters={data.filter}
                  rowsSearch={rowsSearch}
                  setRowsSearch={setRowsSearch}
                  filterss={filterss}
                  search={search}
                  setRowsF={setRowsF}
                />
              )}
              {aplicaciones.map((ap) =>
                <div className={`btn_2 row vcenter pointier relative ${aplication == ap.aplication ? "active" : ""}`} onClick={() => setAplicaicon(ap.aplication)}>
                  {ap.name}
                </div>)}
            </div>
          </div>
          <div className="row w100 hcenter">
            <div className="mxw1000px mtop15">
              {loader ? (
                <div className="box_c w100">
                  <div className="table_header pad15 row fontSizeSmr">
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                    <span className="white w100 row">
                      <Icon icon={"ph:dots-three-outline-fill"} />
                    </span>
                  </div>
                  <div className="row w100 hcenter pad15 vcenter">
                    <ThreeCircles
                      height="43"
                      width="43"
                      color="#0061FF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor=""
                      middleCircleColor=""
                    />
                  </div>
                </div>
              ) : aplicaciones.find((ap) => ap.aplication == aplication).component()}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        key='Subir certificado'
        header='Subir certificado'
        visible={ventanaCertificado}
        onHide={() => { setVentanaCertificado(false) }}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
        <SubirCertificado certificado={certificadoSeleccionado} setShowUpload={setVentanaCertificado} />
      </Dialog>

    </div>

  );
}
