import { useEffect } from "react"
import { useState } from "react"
import { ServicesCertificaciones } from "../Services"
import Loader from "../../../../Atoms/Loader"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Dialog } from "primereact/dialog";
import SubirCertificado from "../Modales/SubirCertificado";
import SeeImagen from "../Modales/SeeImagen";
import { baseURL, URL } from "../../../../../App";

export default function Tableusuarios({ cerificacion }) {
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [showSubir, setShowSubir] = useState(false)
    const [showCertificado, setShowCertificado] = useState(false)
    const [certificado, setCertificado] = useState()

    const getSeverity = (value) => {
        switch (value) {
            case 'Activo':
                return 'success';

            case 'Por vencer':
                return 'warning';

            case 'No completada':
            case 'Vencida':
                return 'danger';

            case 'En Curso':
                return 'info'

            default:
                return null;
        }
    };

    useEffect(() => {
        setLoader(true)
        ServicesCertificaciones.getUsuariosAsignados(cerificacion, (response) => {
            response.then((data) => {
                setData(data);
                setLoader(false);
            });
        })
    }, [])

    useEffect(() => {
    }, [showCertificado,showSubir,certificado])
    
    if (loader)
        return (<Loader />)

    const renderState = (estado) => <Tag value={estado.estado} severity={getSeverity(estado.estado)}></Tag>

    const renderActions = (certificacion) => {
        return(<>
            <i className="pi pi-check-circle pointier success" style={{ fontSize: '1.5rem', margin:5 }} onClick={() => {
                ServicesCertificaciones.changeStatusCertificacion({uuid_relacion:certificacion.uuid_relacion, status:'Activo'},(response)=>{
                    response.then((data)=>{setData(data)})
                })
            }}></i>
            <i className="pi pi-times-circle pointier danger" style={{ fontSize: '1.5rem',margin:5  }} onClick={() => {
                ServicesCertificaciones.changeStatusCertificacion({uuid_relacion:certificacion.uuid_relacion, status:'En curso'},(response)=>{
                    response.then((data)=>{setData(data)})
                })
            }}></i>
        </>)

    }

    const renderBtn = (certificacion) => {
        if (certificacion.url) {
            return <>
            {certificacion.estado=="Esperando aprobación"?renderActions(certificacion):""}
            <i className="pi pi-eye pointier" style={{ fontSize: '1.5rem',margin:5  }} onClick={() => {
                setCertificado(certificacion);
                setShowCertificado(true)
            }}></i>
            </>
        } else if (certificacion.estado === 'Activo' || certificacion.estado === 'Esperando aprobación' || certificacion.estado === 'En Curso') {
            return <i className="pi pi-upload pointier" style={{ fontSize: '1.5rem' }} onClick={() => {
                setCertificado(certificacion);
                setShowSubir(true)
            }}>
            </i>
        }
    }

    return (
        <>
            <DataTable value={data} editMode="row" dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column field="nombre" sortable header="Nombre" ></Column>
                <Column field="estado" sortable header="Estado" body={renderState}></Column>
                <Column field="inicio" header="Inicio" ></Column>
                <Column field="fin" sortable header="Fin" ></Column>
                <Column field="intentos" header="Intentos" ></Column>
                <Column field="see" header="" body={renderBtn} ></Column>
            </DataTable>
            <Dialog
                key='Subir certificado'
                header='Subir certificado'
                visible={showSubir}
                onHide={() => { setShowSubir(false) }}
                style={{ width: "40vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
                <SubirCertificado certificado={certificado} setShowUpload={setShowSubir} />
            </Dialog>
            <Dialog
                key={`Certificación de ${certificado?.nombre}`}
                header={`Certificación de ${certificado?.nombre}`}
                visible={showCertificado}
                onHide={() => { setShowCertificado(false) }}
                style={{ width: "40vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
                <div className="w100 row hcenter">
                    <SeeImagen url={`${baseURL}${certificado?.url}`} />
                </div>
            </Dialog>
        </>

    )
}