import { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import useFiltroDatos from "../../../../../Hooks/useFiltroDatos";
import { ServicesCertificaciones } from "../Services";
import SearchData from "../../../../Organism/Search";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Reload from "../../../../Atoms/Reload";
import Tableusuarios from "./TableUsuarios";
export default function CertificacionesContent() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const { datosFiltrados, handleChangeFiltro, setDatosIniciales } =
        useFiltroDatos(data);
    const [show, setShow] = useState(false)
    const [certificacion, setCertificacion] = useState()
    const getData = () => {
        setLoader(true);
        ServicesCertificaciones.getCertificaciones((response) => {
            response.then((data) => {
                setData(data);
                setLoader(false);
            });
        });
    }

    useEffect(() => {
        setDatosIniciales(data)
    }, [data])

    useEffect(getData, []);
    const renderColumnLink = (key, url, content) => (
        <td className="column" key={key}>
            <a href={url} target={"_blank"} >{content()}</a>
        </td>
    );
    const renderButton = (key, severity, content) => (
        <td className="column" key={key}>
            <Button
                label={content.toString()}
                className="btn_calificar"
                severity={severity}
                outlined
            />
        </td>
    );
    const renderColumn = (key, content) => (
        <td className="column" key={key}>
            {content}
        </td>
    );
    const renderTableContainer = (datosFiltrados) => (
        <div className="mxw1000px row vcenter hspace menTam">
            <div className="table-container">
                <table className="w100" cellPadding={0} cellSpacing={0}>
                    {renderTableHeader()}
                    {renderTableBody(datosFiltrados)}
                </table>
            </div>
        </div>
    );
    const renderTableHeader = () => (
        <thead className="table_header encab2">
            <tr className="table_header white bold hspace vcenter fontSizeSm">
                <th className="column">Clave</th>
                <th className="column">Nombre</th>
                <th className="column">Curso</th>
                <th className="column">Examen</th>
                <th className="column">Asignados</th>
                <th className="column">Activos</th>
                <th className="column">Por vencer</th>
                <th className="column">Vencidos</th>
            </tr>
        </thead>
    );

    const renderTableRow = (row, index) => (
        <tr
            className="row_table pointier"
            key={index}
            onClick={() => {
                setCertificacion(row)
                setShow(true)
            }}
        >
            {renderColumn("clave", row.clave)}
            {renderColumn("nombre", row.nombre)}
            {renderColumnLink("linkCurso", row.linkCurso, () => <Button
                tooltip="Link del curso"
                icon="fa-solid fa-link"
                className="btn_calificar"
                style={{ fontSize: "18px" }}
                severity="info"
                text
                raised
            />)}
            {renderColumnLink("linkExamen", row.linkExamen, () => <Button
                tooltip="Link del examen"
                icon="fa-solid fa-link"
                className="btn_calificar"
                style={{ fontSize: "18px" }}
                severity="info"
                text
                raised
            />)}
            {renderButton("n_asignados", "info", row.n_asignados)}
            {renderButton("n_activos", "success", row.n_activos)}
            {renderButton("n_por_caducar", "warning", row.n_por_caducar)}
            {renderButton("n_vencidos", "danger", row.n_vencidos)}


        </tr>
    );

    const renderTableBody = (datosFiltrados) => (
        <tbody className="tbodyTablaSeleccion">
            {datosFiltrados.map(renderTableRow)}
        </tbody>
    );

    const renderLoader = () => (
        <div className="row w100 hcenter pad15 vcenter">
            <ThreeCircles
                height="43"
                width="43"
                color="#0061FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </div>
    );
    return (
        <div className="w100 row hcenter">
            <div className="mxw1000px row hcenter wrap">
                <div className="row w100 mtop15">
                    <SearchData handleChangeFiltro={handleChangeFiltro} />
                    <Reload onClick={getData} />
                </div>
                {loader ? renderLoader() : renderTableContainer(datosFiltrados)}
            </div>
            <Dialog
                header={`${certificacion?.nombre}`}
                visible={show}
                onHide={() => {setShow(false)}}
                style={{ width: "60vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
                    <Tableusuarios cerificacion={certificacion?.uuid} />
            </Dialog>
        </div>
    )
}