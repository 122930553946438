import { Icon } from "@iconify/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { toast } from "react-toastify";

export default function ModalSupport({setShowModalSupport, data, actividad, setActividad}) {
  const [module, setModule] = useState(actividad.hasOwnProperty('support') ? actividad.support.module : "" );
  const [origin, setOrigin] = useState(actividad.hasOwnProperty('support') ? actividad.support.origin : "" );
  const [typeError, setTypeError] = useState(actividad.hasOwnProperty('support') ? actividad.support.typeError : "" );
  const [error, setError] = useState(actividad.hasOwnProperty('support') ? actividad.support.error : "" );
  const [solution_description, setSolutionDescription] = useState(actividad.hasOwnProperty('support') ? actividad.support.solution_description : "" );
  const [previous, setPrevious] = useState(actividad.hasOwnProperty('support') ? actividad.support.previous : "" );
  const [solution, setSolution] = useState(actividad.hasOwnProperty('support') ? actividad.support.solution : "" );

  function saveSupport()
  {
    if(
      (module == undefined || module == "") ||
      (origin == undefined || origin == "") ||
      (typeError == undefined || typeError == "") ||
      (error == undefined || error == "") ||
      (solution_description == undefined || solution_description == "") ||
      (previous == undefined || previous == "") ||
      (solution == undefined || solution == "")
    ){
      toast.warning("Verifica que tu formulario este completo", { theme: "dark" })
      return;
    }
    let activitySupport = {...actividad};
    activitySupport.support = {};
    activitySupport.support.id2 = actividad.hasOwnProperty('support') ? actividad.support.id2 : null;
    activitySupport.support.module = module;
    activitySupport.support.origin = origin;
    activitySupport.support.typeError = typeError;
    activitySupport.support.error = error;
    activitySupport.support.solution_description = solution_description;
    activitySupport.support.previous = previous;
    activitySupport.support.solution = solution;
    setActividad(activitySupport);
    setShowModalSupport(false);
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier">
            <Icon icon={"ic:twotone-close"} onClick={() => setShowModalSupport(false)} />
          </div>
          <div className="row hcenter w100 pad20 vcenter" style={{ background: "#000", color: "#fff" }} >
            <h2 className="row vcenter">Bitácora de Soporte</h2>
          </div>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <div className="row col_2 pad10 fontSizeMd">
            <span className="bold">Cliente:&nbsp;</span> {data[0].value}
          </div>
          <div className="row col_2 pad10 fontSizeMd">
            <span className="bold">Fecha:&nbsp;</span>  {data[3].value}
          </div>

          <div className="row col_2 pad10 fontSizeMd m-0">
            <span className="p-float-label flex-1" key={"module"}>
              <InputText id={"module"} value={module} onChange={(e) => setModule(e.target.value)} style={{ width: "100%", height: 40 }} />
              <label>Módulo</label>
            </span>
          </div>

          <div className="row col_2 pad10 fontSizeMd m-0">
            <span className="p-float-label flex-1" key={"origin"}>
              <InputText id="origin" value={origin} onChange={(e) => setOrigin(e.target.value)} style={{ width: "100%", height: 40 }} />
              <label>Origen</label>
            </span>
          </div>

          <div className="row support-col-text pad10 fontSizeMd m-0">
            <span className="my-auto">Tipo de Error:</span>
          </div>
          <div className="row support-col-input pad10 fontSizeMd m-0">
            <InputTextarea autoResize id="type_error" value={typeError} onChange={(e) => setTypeError(e.target.value)} style={{ width: "100%", height: "30px" }} rows={3} cols={30}/>
          </div>

          <div className="row support-col-text pad10 fontSizeMd m-0">
            <span className="my-auto">Error:</span>
          </div>
          <div className="row support-col-input pad10 fontSizeMd m-0">
            <InputTextarea autoResize id="error" value={error} onChange={(e) => setError(e.target.value)} style={{ width: "100%", height: "30px" }} rows={3} cols={30} />
          </div>

          <div className="row support-col-text pad10 fontSizeMd m-0">
            <span className="my-auto">Solución:</span>
          </div>
          <div className="row support-col-input pad10 fontSizeMd m-0">
            <InputTextarea autoResize id="solution_description" value={solution_description} style={{ width: "100%", height: "30px" }} rows={3} cols={30} onChange={(e) => setSolutionDescription(e.target.value) }/>
          </div>

          <div className="row support-col-text pad10 fontSizeMd m-0">
            <span className="my-auto">Anterior Código / Configuración:</span>
          </div>
          <div className="row support-col-input pad10 fontSizeMd m-0">
            <InputTextarea autoResize id="previus" value={previous} style={{ width: "100%", height: "30px" }} rows={3} cols={30} onChange={(e) => setPrevious(e.target.value) }/>
          </div>

          <div className="row support-col-text pad10 fontSizeMd m-0">
            <span className="my-auto">Solución Código / Configuración:</span>
          </div>
          <div className="row support-col-input pad10 fontSizeMd m-0">
            <InputTextarea autoResize id="solution" value={solution} style={{ width: "100%", height: "30px" }} rows={3} cols={30} onChange={(e) => setSolution(e.target.value) }/>
          </div>

          <div className="row w100 pad10 hcenter">
            <button className="btn green active pointier bg_green fontSizeSm bold" onClick={saveSupport}>
              Guardar
            </button>
          </div>

        </div>
        
      </div>
    </>
  );
}
