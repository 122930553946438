import { Icon } from "@iconify/react";
import "./../../../static/scss/input.scss";
import Select from "react-select";
import { ThreeCircles } from "react-loader-spinner";
import { useEffect } from "react";
import { useState } from "react";
import WindowDimension from "../../../Hooks/WindowDimension";
function pass(){}
function Input({
  type,
  name,
  print_name,
  isMulti,
  values,
  accept,
  setFormData,
  formData,
  value_,
  min_date,
  max_date,
}) {
  const [value, setValue] = useState(value_);

  useEffect(() => pass(value), [value]);
  function genData(e) {
    setValue(e);
    var formClone = formData;
    if (isMulti) {
      var arrayData = [];
      for (var data in e) {
        arrayData.push(e[data].value);
      }
      formClone[name] = arrayData;
      setFormData(formClone);
    } else {
      formClone[name] = e.value;
      setFormData(formClone);
    }
  }
  if (type === "select")
    return (
      <Select
        options={values}
        isMulti={isMulti}
        onChange={(e) => genData(e)}
        className={"input light"}
        value={value}
        name={name}
        id={name}
      />
    );
  return (
    <input
      type={type}
      name={name}
      id={name}
      placeholder={print_name}
      className={"input light pad10"}
      accept={accept}
      value={value == "null" || value == null ? "" : value}
      checked={value}
      min={min_date}
      max={max_date}
      onChange={(e) => {
        pass(e.target.value);
        var formClone = formData;
        if (type === "file") formClone[name] = e.target.files[0];
        else formClone[name] = type === "checkbox" ? !value : e.target.value;
        setValue(type === "checkbox" ? !value : e.target.value);
        setFormData(formClone);
      }}
    />
  );
}

function getData(
  uuid,
  route,
  data,
  Post,
  setEditForm,
  setFormData,
  setMultiFormA,
  setForms,
  setResponseD,
  setGetting
) {
  setGetting(true);
  var params = new FormData();
  params.append(
    "public_key",
    "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
  );
  params.append(
    "private_key",
    "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
  );

  params.append("uuid", uuid);
  Post(
    `${data.app}/${route}/`,
    (response) => {
      pass(response);
      setResponseD(response.data);
      setFormData(response.data.form_data);
      setGetting(false);
      if (data.form.multi) {
        document.getElementById("modal").classList.add("multi");
        setMultiFormA(response.data.fields[0]);
        setForms(response.data.fields);
      } else {
        setEditForm(response.data.fields);
      }
    },
    (error) => {
      pass(error);
      setGetting(false);
    },
    false,
    "Enviando...",
    params
  );
}

export default function Formulario({
  data,
  route,
  form,
  setToggleModal,
  Post,
  setData,
  title,
  row,
}) {
  const [formData, setFormData] = useState({});
  const [responseD, setResponseD] = useState({});
  const [editForm, setEditForm] = useState(null);
  const [multiFormA, setMultiFormA] = useState({});
  const [forms, setForms] = useState([]);
  const [getting, setGetting] = useState(false);

  useEffect(() => {
    setEditForm(multiFormA.fields);
  }, [multiFormA]);

  useEffect(() => {
    if (row && editForm == null && !getting)
      getData(
        row.uuid,
        route,
        data,
        Post,
        setEditForm,
        setFormData,
        setMultiFormA,
        setForms,
        setResponseD,
        setGetting
      );
  }, []);

  if (row) {
    return (
      <>
        {responseD.info ? (
          <div className="row wrap hcenter">
            {responseD.info.map((info) => (
              <div className="m_15px">
                <span className="bold">{info.name}:</span>
                {`${" "} ${info.data}`}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {editForm != null ? (
          <div className="row h100">
            {data.form.multi ? (
              <div className="menu_modal">
                <div className="row wrap">
                  {forms.map((group) => (
                    <div
                      className={`modal_option row w100 ${
                        multiFormA == group ? "active" : ""
                      }`}
                      key={group.name}
                      onClick={() => setMultiFormA(group)}
                    >
                      {group.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row wrap w100 hcenter">
              {multiFormA.text ? (
                <h3 className="row w100 hcenter mtop25">{multiFormA.text}</h3>
              ) : (
                ""
              )}
              {data.form.multi ? (
                forms.map((group) => (
                  <div
                    className={`row w100 hcenter form_space mtop15 wrap ${
                      multiFormA != group ? "none" : ""
                    }`}
                  >
                    <div className="row w100 hcenter wrap">
                      {group.fields.map((field) => (
                        <div className="mtop15" key={field.name}>
                          <label
                            htmlFor={field.name}
                            className="row"
                            style={{ marginLeft: 5 }}
                          >
                            {field.print_name}:
                          </label>
                          <Input
                            type={field.type}
                            name={field.name}
                            print_name={field.print_name}
                            isMulti={field.multiple}
                            values={field.values}
                            value_={field.value}
                            accept={field.accept}
                            setFormData={setFormData}
                            formData={formData}
                            min_date={field.min_date}
                            max_date={field.max_date}
                          />
                        </div>
                      ))}
                    </div>
                    {responseD.table ? (
                      <div className="row hcenter w100 m_15px">
                        <table className="table mtop25 w100">
                          {responseD.table.length > 0 ? (
                            <>
                              <thead className="thead">
                                <tr className="">
                                  {Object.keys(responseD.table[0]).map((key) =>
                                    key != "uuid" ? (
                                      <td className="colum bold">
                                        <div className="clamp_1">{key}</div>{" "}
                                      </td>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {responseD.table.map((row) => (
                                  <tr className={`row_table`} key={row.uuid}>
                                    {Object.keys(responseD.table[0]).map(
                                      (key) =>
                                        key != "uuid" ? (
                                          <td className="colum" key={key}>
                                            <div className="clamp_1">
                                              {row[key]}
                                            </div>
                                          </td>
                                        ) : (
                                          ""
                                        )
                                    )}
                                    <td
                                      className="colum_2 pointier relative"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "right",
                                      }}
                                    ></td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          ) : (
                            <div className="thead w100 row hcenter">
                              No hay registros
                            </div>
                          )}
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              ) : (
                <div className="row w100 hcenter wrap form_space">
                  {editForm.length > 0 ? (
                    <div className="row w100 hcenter wrap">
                      {editForm.map((field) => (
                        <div className="mtop15" key={field.name}>
                          <label
                            htmlFor={field.name}
                            className="row"
                            style={{ marginLeft: 5 }}
                          >
                            {field.print_name}:
                          </label>
                          <Input
                            type={field.type}
                            name={field.name}
                            print_name={field.print_name}
                            isMulti={field.multiple}
                            values={field.values}
                            value_={field.value}
                            accept={field.accept}
                            setFormData={setFormData}
                            formData={formData}
                            min_date={field.min_date}
                            max_date={field.max_date}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}

                  {responseD.table ? (
                    <div className="row hcenter w100 m_15px">
                      <table className="table mtop25 w100">
                        {responseD.table.length > 0 ? (
                          <>
                            <thead className="thead">
                              <tr className="">
                                {Object.keys(responseD.table[0]).map((key) =>
                                  key != "uuid" ? (
                                    <td className="colum bold">
                                      <div className="clamp_1">{key}</div>{" "}
                                    </td>
                                  ) : (
                                    ""
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {responseD.table.map((row) => (
                                <tr className={`row_table`} key={row.uuid}>
                                  {Object.keys(responseD.table[0]).map((key) =>
                                    key != "uuid" ? (
                                      <td className="colum" key={key}>
                                        <div className="clamp_1">
                                          {row[key]}
                                        </div>
                                      </td>
                                    ) : (
                                      ""
                                    )
                                  )}
                                  <td
                                    className="colum_2 pointier relative"
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "right",
                                    }}
                                  ></td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        ) : (
                          <div className="thead w100 row hcenter">
                            No hay registros
                          </div>
                        )}
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div className="row w100 hright pad10">
                {responseD.btns ? (
                  responseD.btns.map((btn) => (
                    <div className="row hright">
                      <div
                        className={`btn ${
                          btn.key == 1 ? "btn_accept" : "btn_cancel"
                        } m_15px pointier`}
                        onClick={() => setToggleModal(false)}
                      >
                        {btn.text}
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className={`row hright`}>
                      <div
                        className="btn btn_accept m_15px pointier"
                        onClick={() => {
                          var params = new FormData();
                          params.append(
                            "public_key",
                            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                          );
                          params.append(
                            "private_key",
                            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                          );
                          for (var key in formData) {
                            params.append(key, formData[key]);
                          }
                          if (row) params.append("uuid", row.uuid);
                          pass(formData);
                          Post(
                            `${
                              responseD.action ? responseD.action : form.action
                            }`,
                            (response) => {
                              pass(response);
                              setData(response.data);
                              setToggleModal(false);
                            },
                            (error) => {
                              pass(error);
                            },
                            true,
                            "Enviando...",
                            params
                          );
                        }}
                      >
                        {title}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="row hcenter w100 vcenter"
            style={{ height: "-webkit-fill-available" }}
          >
            <ThreeCircles
              height="60"
              width="60"
              color="#0061FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        )}
      </>
    );
  } else
    return (
      <div className="row wrap w100 hcenter">
        <div className="row w100 hcenter wrap form_space mtop15">
          {form.fields.map((field) => (
            <div className="mtop15" key={field.name}>
              <div className="row" style={{ marginLeft: 5 }}>
                {field.print_name}:
              </div>
              <Input
                type={field.type}
                name={field.name}
                print_name={field.print_name}
                isMulti={field.multiple}
                values={field.values}
                accept={field.accept}
                setFormData={setFormData}
                formData={formData}
              />
            </div>
          ))}
        </div>

        <div className="row w100 hright pad10">
          {responseD.btns ? (
            responseD.btns.map((btn) => (
              <div className="row hright">
                <div
                  className={`btn ${
                    btn.key == 1 ? "btn_accept" : "btn_cancel"
                  } btn_cancel m_15px pointier`}
                  onClick={() => setToggleModal(false)}
                >
                  {btn.text}
                </div>
              </div>
            ))
          ) : (
            <>
              <div className={`row hright`}>
                <div
                  className="btn btn_accept m_15px pointier"
                  onClick={() => {
                    var params = new FormData();
                    params.append(
                      "public_key",
                      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
                    );
                    params.append(
                      "private_key",
                      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
                    );
                    for (var key in formData) {
                      params.append(key, formData[key]);
                    }
                    if (row) params.append("uuid", row.uuid);
                    pass(formData);
                    Post(
                      `${responseD.action ? responseD.action : form.action}`,
                      (response) => {
                        pass(response);
                        setData(response.data);
                        setToggleModal(false);
                      },
                      (error) => {
                        pass(error);
                      },
                      true,
                      "Enviando...",
                      params,
                      true
                    );
                  }}
                >
                  {title}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
}
