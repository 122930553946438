import { baseURL } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
export class Services {
  static post(body, success, url) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}${url}`, requestOptions)
      .then((response) => {
        if (response.ok)
          success(response.json());
        else
          response.json().then((data) => {
            toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
          })
      })

  }

  static put(body, success, url) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}${url}`, requestOptions)
      .then((response) => {
        if (response.ok)
          success(response.json());
        else
          response.json().then((data) => {
            toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
          })
      })

  }

  static upload_certificacion(body, success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/CertificacionUsuario/upload_certificado_a/`, requestOptions)
      .then((response) => {
        if (response.ok)
          success(response);
        else
          response.json().then((data) => {
            toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
          })
      })
  }

  static getUsuariosAsignados(certificacion,success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Certificaciones/usuariosAsignados/${certificacion}`, requestOptions)
      .then((response) => {
        if (response.ok)
          success(response.json());
        else
          response.json().then((data) => {
            toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
          })
      })
  }

  static changeStatusCertificacion(body,success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Certificaciones/changeStatus`, requestOptions)
      .then((response) => {
        if (response.ok)
          success(response.json());
        else
          response.json().then((data) => {
            toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
          })
      })
  }
}