import { Zoom } from "react-reveal";
import Formulario from "../Formulario/Formulario";
import { ThreeCircles } from "react-loader-spinner";
import Dropdown from "../Dropdown";
import { useState } from "react";
import { useEffect } from "react";
export default function Table({
  data,
  rows,
  load,
  setToggleModal,
  setTitleModal,
  setModal,
  aplicacion,
  Post,
  Get,
  setData,
}) {
  const [click,setClick]= useState(null)
  const [activeC,setActiveC]=useState(false)
  const [rowA,setRowA]=useState(null)

  useEffect(()=>{},[activeC])
  useEffect(()=>{setActiveC(false);setRowA(null)},[click])
  useEffect(()=>{},[rowA])
  return (
    <div className="row w100 hcenter">
      {activeC?<div className="click_action " onClick={(e)=>setClick(e)}></div>:""}
      <div className="row hcenter mxw1000px">
        <table className="table mtop15 w100" cellSpacing={0}>
          {load ? (
            <div className="row hcenter w100 vcenter">
              <ThreeCircles
                height="60"
                width="60"
                color="#0061FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            </div>
          ) : data ? (
            data.data[0] ? (
              <>
                <thead className="thead">
                  <tr>
                    {data ? (
                      data.data[0] ? (
                        Object.keys(data.data[0]).map((key) =>
                          key != "uuid" && key != "options" ? (
                            <td className="colum bold thead" key={key}>
                              {key}
                            </td>
                          ) : (
                            ""
                          )
                        )
                      ) : (
                        <div className="row hcenter w100">No hay datos</div>
                      )
                    ) : (
                      <div className="row hcenter w100">No hay datos</div>
                    )}
                    <td className="colum_2 bold thead"></td>
                  </tr>
                </thead>
                <Zoom cascade>
                  <tbody>
                    {rows.map((row) => (
                      <tr className={`row_table ${rowA==row?"active":""}`} key={row.uuid} st>
                        {Object.keys(rows[0]).map((key) =>
                          key != "uuid" && key!="options"? (
                            <td className="colum" key={key}>
                              <div className="clamp_1">
                              {key=="Status"?<span className={`${row[key].toLowerCase()}`}>{row[key]}</span>:row[key]}
                              </div>
                            </td>
                          ) : (
                            ""
                          )
                        )}
                        <td
                          className="colum_2 pointier relative"
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "right",
                          }}
                        >
                          <Dropdown click={click} data={data} setToggleModal={setToggleModal} Post={Post} setData={setData} row={row} setTitleModal={setTitleModal} aplicacion={aplicacion} setModal={setModal} setActiveC={setActiveC} setRowA={setRowA} setClick={setClick} actions={row.options?row.options:data.actions} Get={Get}  />
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Zoom>
              </>
            ) : (
              <div className="row hcenter w100">No hay datos</div>
            )
          ) : (
            <div className="row hcenter w100">No hay datos</div>
          )}
        </table>
      </div>
    </div>
  );
}
