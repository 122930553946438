import { Zoom } from "react-reveal";
export default function Tabbar({module,aplicacion,setAplicaicon}){
    return(
        <div className="row hcenter mtop25">
          <Zoom cascade>
            <div className="row mxw1000px tabs">
              {module.aplicaciones.map((aplicacion_) => (
                <div
                  key={aplicacion_.Nombre}
                  className={`tab pointier ${
                    aplicacion.Nombre == aplicacion_.Nombre ? "active" : ""
                  }`}
                  onClick={() => {
                    setAplicaicon(aplicacion_);
                  }}
                >
                  {aplicacion_.Nombre}
                </div>
              ))}
            </div>
          </Zoom>
        </div>
    )
}