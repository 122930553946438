import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import ModalShow from "./ModalShow";
import { baseURL } from "../../../App";
import Actions from "../../snippets/Module/Actions";
import Bounce from "react-reveal/Bounce";
import { Zoom } from "react-reveal";
import { toast } from "react-toastify";

export default function Index() {
  const getUser = () => JSON.parse(localStorage.getItem('user'));
  const [showModalShow, setShowModalShow] = useState(false);
  const [editUuid, setEditUuid] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsOrigin, setItemsOrigin] = useState([]);
  const [search, setSearch] = useState("");

  const [sId, setSId] = useState("");
  const [sDate, setSDate] = useState("");
  const [sModule, setSModule] = useState("");
  const [sOrigin, setSOrigin] = useState("");
  const [sTypeError, setSTypeError] = useState("");
  const [user, setUser] = useState(getUser());

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!showModalShow) {
      bodyClassList.remove("open");
      bodyClassList.add("closed");
      loadItems()
    }else {
      bodyClassList.remove("closed");
      bodyClassList.add("open");
    }
  }, [showModalShow]);
  useEffect(() => {
    loadItems();
  },[]);
  useEffect(() => {
    filter()
  },[sId,sDate,sModule,sOrigin,sTypeError]);

  useEffect(() => {
    if (search.length > 0) filter();
    else setItems(itemsOrigin);
  }, [search]);

  function filter() {
    var arraySe = [];
    var rowm = itemsOrigin
    console.log(sDate.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase());
    for (let row in rowm) {
      // typeof(Object.values(rowm[row])[value]) == "string" &&
      let values = Object.values(rowm[row]);      
      if (
        values[1].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ' ').includes(sId.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[6].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ' ').includes(sDate.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[7].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ' ').includes(sModule.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[8].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ' ').includes(sOrigin.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase()) &&
        values[2].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ' ').includes(sTypeError.normalize("NFD").replace(/[\u0300-\u036f]/g,"").toLowerCase())){
        if (!arraySe.includes(rowm[row])) arraySe.push(rowm[row]);
      }
    }
    setItems(arraySe);
  }

  function loadItems()
  {
    fetch(`${baseURL}/supports/`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setItems(data);
      setItemsOrigin(data);
      console.log(data)
    });
  }
  function setEmployeeSupportLevel(value)
  {
    fetch(`${baseURL}/configuration/employee_support_level/update/`, {
      method: "POST",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ slug:"employee_support_level", value:value }),
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      toast.success("Configuración guardada exitosamente", { theme: 'dark'})
      loadItems();
    });
  }
  const [lockActive, setActiveC] = useState(false);

  return (
    <div className="w100" style={{ marginBottom: "20px" }}>
        { user.rol.name != "Empleado" && (
        <div className="row w100 hcenter">
            <div className="mxw1000px row vcenter mtop25 jc-end">
            <div className="row col_2 jc-end lock-user">
              <div className="lock-filter row vcenter pointier relative" onClick={() => setActiveC(!lockActive)} >
              <Icon icon="material-symbols:lock-person" />
              <Bounce when={lockActive} collapse>
                <div className="absolute lock-filter-select">
                  <div className="w100 lock-f15 bold p5" onClick={() => setEmployeeSupportLevel("all")}>Todos</div>
                  <div className="w100 lock-f15 bold p5" onClick={() => setEmployeeSupportLevel("client")}>Cliente</div>
                  <div className="w100 lock-f15 bold p5" onClick={() => setEmployeeSupportLevel("support")}>Proyecto</div>
                </div>
              </Bounce>
              </div>
            </div>
            </div>
        </div>
        )}
      <div className="row w100 hcenter">
        <div className="row mxw1000px relative ">
          <table className="box_c w100 absolute" cellPadding={0} cellSpacing={0} >
            <thead className="table_header pad15">
              <tr className="table_header w100 white bold hspace vcenter fontSizeSm">
                <td className="column">ID</td>
                <td className="column">Empleado</td>
                <td className="column">Cliente</td>
                <td className="column">Asignación</td>
                <td className="column">Fecha</td>
                <td className="column">Acciones</td>
              </tr>
              <tr className="table_header w100 white">
                <td className="column"><input type="text" className="w100" onChange={(e) => { setSId(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setSDate(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setSModule(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setSOrigin(e.target.value) }}/></td>
                <td className="column"><input type="text" className="w100" onChange={(e) => { setSTypeError(e.target.value) }}/></td>
                <td className="column"></td>
              </tr>
            </thead>
            <tbody>
            {items.map((row) => {
              return (
                <tr className="row_table">
                  <td className="column">{row["id2"]}</td>
                  <td className="column">{row["employee"]}</td>
                  <td className="column">{row["client"]}</td>
                  <td className="column">{row["assignment"]}</td>
                  <td className="column">{row["created"]}</td>
                  <td className="column">
                    <button className="btn_accept2 pad5 pointier row vcenter" onClick={ () => {setEditUuid(row["uuid"]); setShowModalShow(true); } }>Ver</button>
                  </td>
                </tr>
              );}
            )}
            </tbody>
          </table>
        </div>
      </div>

      { showModalShow && ( <ModalShow setShowModalShow={setShowModalShow} uuid={editUuid}/> )}
      
    </div>
  );
}