import { useEffect } from "react";
import { Icon } from "@iconify/react";
import Formulario from "./Formulario/Formulario";
import Bounce from "react-reveal/Bounce";
import Slide from "react-reveal/Slide";
import { useState } from "react";
import Zoom from "react-reveal/Zoom";
function pass(){}
export default function Dropdown({
  click,
  data,
  setToggleModal,
  Post,
  Get,
  setData,
  row,
  setTitleModal,
  aplicacion,
  setModal,
  setActiveC,
  setRowA,
  setClick,
  actions,
}) {
  useEffect(() => setShow(false), [click]);
  const [show, setShow] = useState(false);
  function handlerOnClick(action) {
    switch (action.key) {
      case 1:
        setClick(action);
        setToggleModal(true);
        setTitleModal(`${action.title?action.title:"Actualizar"} ${aplicacion.Nombre.toLowerCase()}`);
        setModal(() => (
          <Formulario
            data={data}
            route={action.route}
            form={data.editable_form}
            setToggleModal={setToggleModal}
            Post={Post}
            setData={setData}
            title={action.title?action.title:"Actualizar"}
            row={row}
          />
        ));
        break;
      case 3:
        setClick(action);
        var params = new FormData();
        params.append(
          "public_key",
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
        );
        params.append(
          "private_key",
          "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
        );
        if (row) params.append("uuid", row.uuid);
        Post(
          action.route,
          (response) => {
            pass(response);
            setData(response.data);
          },
          (error) => {
            pass(error);
          },
          true,
          "Enviando...",
          params
        );
        break;

      case 4:
        setClick(action);
        var params = new FormData();
        var public_ = "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
        params.append(
          "public_key",
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
        );
        params.append(
          "private_key",
          "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
        );

        if (row) params.append("uuid", row.uuid);
        Get(
          `${action.route}${row.uuid}`,
          (response) => {
            pass(response);
            var binaryData = [];
            binaryData.push(response.data);
            const href = window.URL.createObjectURL(new Blob(binaryData, {type: response.headers.content_type}))
            
            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "file.pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          },
          (error) => {
            pass(error);
          },
          true,
          "Descargando...",
          {responseType:'blob'}
        );
        break;

      default:
        setClick(action);
        setToggleModal(true);
        setTitleModal(`${action.title?action.title:"Actualizar"} ${aplicacion.Nombre.toLowerCase()}`);
        setModal(() => (
          <Formulario
            data={data}
            route={action.route}
            form={data.editable_form}
            setToggleModal={setToggleModal}
            Post={Post}
            setData={setData}
            title={action.title?action.title:"Actualizar"}
            row={row}
          />
        ));
        break;
    }
  }

  return (
    <>
      <div
        className="options"
        onClick={() => {
          setActiveC(true);
          setRowA(row);
          setShow(true);
        }}
      >
        <Icon icon={"mi:options-vertical"} className="thead" />
      </div>

      <div className="absolute" style={{ zIndex: 9 }}>
        <Zoom collapse when={show}>
          <div className="dropdown">
            <ul>
              {actions.map((action) => (
                <li
                  key={action.key}
                  className="dropitem row vcenter"
                  onClick={() => handlerOnClick(action)}
                >
                  <span style={{ width: "100%" }}>{action.name}</span>{" "}
                  <span className="row vcente  f_small">
                    <Icon icon={action.icon} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </Zoom>
      </div>
    </>
  );
}
