import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Services } from "../../Services";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressBar } from "primereact/progressbar";
import { tieneValoresNulosOVacios } from "../../../../Utils";
import { Calendar } from "primereact/calendar";
import { toUTCDate } from "../../../../Utils";
import { toStringDate } from "../../../../Utils";

function EmpleadoAsignado({
  empleado,
  fields,
  setUsuariosAsignados,
  usuariosAsignados,
}) {
  const [edit, setEdit] = useState(false);
  const [_empleado, _setEmpleado] = useState({ ...empleado });
  return (
    <>
      <td className="column text-centrado">
        {edit ? (
          <div>
            <Button
              icon="pi pi-check"
              rounded
              text
              severity="success"
              onClick={() => {
                setEdit(false);
                console.log(usuariosAsignados);
                let _usuariosAsignados = usuariosAsignados.map((usuario) =>
                  usuario.nombre === empleado.nombre ? _empleado : usuario
                );
                setUsuariosAsignados(_usuariosAsignados);
              }}
            />
            <Button
              icon="pi pi-times"
              rounded
              text
              severity="danger"
              onClick={() => {
                _setEmpleado(empleado);
                setEdit(false);
              }}
            />
          </div>
        ) : (
          <Button
            rounded
            outlined
            severity="info"
            icon="pi pi-pencil"
            onClick={() => {
              setEdit(true);
            }}
          />
        )}
      </td>
      {fields.map((field) => (
        <td className="column text-centrado">
          <RenderField
            field={field}
            key={field.name}
            templateAdd={empleado}
            setAddBody={_setEmpleado}
            addBody={_empleado}
            edit={edit}
          />
        </td>
      ))}
    </>
  );
}

function RenderField({ field, addBody, setAddBody, templateAdd, edit }) {
  const [value, setValue] = useState(addBody[field.name]);
  useEffect(() => {
    let _body = { ...addBody };
    _body[field.name] = value;
    setAddBody(_body);
  }, [value]);

  switch (field.type) {
    case "switchField":
      return (
        <InputSwitch
          checked={addBody[field.name]}
          onChange={(e) => {
            setValue(e.value);
          }}
          name={field.name}
          disabled={!edit}
        />
      );
    case "dateField":
      return (
        <Calendar
          showIcon
          value={
            addBody[field.name].length > 1
              ? [
                  toUTCDate(addBody[field.name][0]),
                  toUTCDate(addBody[field.name][1]),
                ]
              : addBody[field.name]
          }
          disabled={!edit}
          selectionMode={field.selectionMode ? field.selectionMode : "single"}
          readOnlyInput
          dateFormat="dd/mm/yy"
          className="row w100 p25 hcenter mtop10"
          onChange={(event) => setValue([toStringDate(event.value[0]),toStringDate(event.value[1])])}
          name={field.name}
        />
      );
    case "select":
      return (
        <Dropdown
          value={addBody[field.name]}
          options={field.options}
          onChange={(event) => setValue(event.value)}
          placeholder={`Selecciona un valor`}
          emptyFilterMessage={`Dato no encontrado`}
          optionLabel="name"
          optionValue="code"
          disabled={!edit}
          filter
          className="multiselect-custom row w100 hcenter mtop10"
          display="chip"
        />
      );
  }
}
function ActividadCard({ actividad, setBody, body, empleados }) {
  const templateAdd = {
    nombre: "",
    lapso: [],
    completado: false,
  };
  const [addBody, setAddBody] = useState(templateAdd);
  const fields = [
    { name: "nombre", header: "Nombre", type: "select", options: empleados },
    {
      name: "lapso",
      header: "Lapso de cumplimiento",
      type: "dateField",
      selectionMode: "range",
    },
    { name: "completado", header: "Completado", type: "switchField" },
  ];
  const [usuariosAsignados, setUsuariosAsignados] = useState(
    actividad.asignados
  );
  useEffect(() => {
    let _actividad = { ...actividad };
    _actividad.asignados = [...usuariosAsignados];
    let _body = body.map((actividad) =>
      _actividad.uuid === actividad.uuid ? _actividad : actividad
    );
    setBody(_body);
  }, [usuariosAsignados]);
  return (
    <>
      <div className="date_row mtop10">
        <h3 style={{ color: "white" }}>
          {actividad["name"]}, se deben completar{" "}
          {actividad["asignaciones_obligatorias"]}
        </h3>
      </div>
      <div className="row w100 hcenter">
        <div className="mxw1000px ">
          <table className="w100 box_c" cellPadding={0} cellSpacing={0}>
            <thead className="">
              <tr className="  bold hspace vcenter fontSizeSm">
                <th className="column">
                  {actividad["asignados"].length > 0 ? "Editar" : "Agregar"}
                </th>
                <th className="column">Nombre</th>
                <th className="column">Lapso de cumplimiento</th>
                <th className="column">Completado</th>
              </tr>
            </thead>
            <tbody>
              {usuariosAsignados.map((empleado) => (
                <tr className="row_table">
                  <EmpleadoAsignado
                    empleado={empleado}
                    fields={fields}
                    setUsuariosAsignados={setUsuariosAsignados}
                    usuariosAsignados={usuariosAsignados}
                  />
                </tr>
              ))}
              <tr className="row_table">
                <td className="column text-centrado">
                  <Button
                    rounded
                    icon="pi pi-plus"
                    severity="success"
                    onClick={() => {
                      if (!tieneValoresNulosOVacios(addBody)) {
                        setUsuariosAsignados([...usuariosAsignados, addBody]);
                        setAddBody(templateAdd);
                      }
                    }}
                  />
                </td>

                {fields.map((field) => (
                  <td className="column text-centrado">
                    <RenderField
                      field={field}
                      key={field.name}
                      templateAdd={templateAdd}
                      setAddBody={setAddBody}
                      addBody={addBody}
                      edit={true}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default function ExpertiseDetails({ show, onHide, expertise }) {
  const [loader, setLoader] = useState(true);
  const [body, setBody] = useState([]);
  const [empleados, setEmpleados] = useState([]);

  useEffect(() => {
    Services.getEmpleados((response) => {
      response.then((data) => {
        setEmpleados(data);
      });
    });
  }, []);

  useEffect(() => {
    console.log(body);
  }, [body]);

  useEffect(() => {
    if (expertise) {
      setLoader(true);
      Services.getExpertiseDetail(expertise.uuid, (response) => {
        response.then((data) => {
          setLoader(false);
          setBody(data);
        });
      });
    }
  }, [expertise]);

  return (
    <Dialog
      header={expertise?.name}
      visible={show}
      onHide={() => onHide()}
      style={{ width: "60vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      {loader ? (
        <div className="row w100 hcenter pad15 vcenter">
          <ThreeCircles
            height="43"
            width="43"
            color="#0061FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      ) : (
        <>
          <div className="row hspace mtop10">
            <div className="mright15">
              <h3>FECHA DE INICIO</h3>
              <p style={{ marginTop: ".2rem" }}>{expertise.f_inicio}</p>
            </div>
            <div className="mright15">
              <h3>FECHA DE FIN</h3>
              <p style={{ marginTop: ".2rem" }}>{expertise.f_fin}</p>
            </div>
            <div className="mright15">
              <h3>NO. DE ACTIVIDADES</h3>
              <p style={{ marginTop: ".2rem" }}>{expertise.n_actividades}</p>
            </div>
            <div className="mright15">
              <h3>RESP. ASIGNADOS</h3>
              <p style={{ marginTop: ".2rem" }}>{expertise.n_responsables}</p>
            </div>
          </div>

          <ProgressBar
            aria-label="Status"
            style={{ color: "#2B2B2B", marginTop: "15px" }}
            value={expertise.progress}
          ></ProgressBar>

          {body.map((actividad) => (
            <ActividadCard
              actividad={actividad}
              empleados={empleados}
              setBody={setBody}
              body={body}
            />
          ))}
          <div
            className="row w100 hcenter mtop15"
            style={{
              paddingInlineStart: "10%",
              paddingInlineEnd: "10%",
            }}
          >
            <button
              className="row w100 btn_2 hcenter mtop15 encab2"
              onClick={() => {
                Services.asignarEmpleadosExpertise({actividad:body},(response)=>{onHide()})
              }}
            >
              Guardar todos los cambios
            </button>
          </div>
        </>
      )}
    </Dialog>
  );
}
