import { baseURL } from "../../../App";
import { ToastContainer, toast } from "react-toastify";
export class Services {
  static create_equipo(body, success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Equipos/create/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })

  }

  static update_equipo(body, success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Equipos/update/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static getIntegrantesEquipo(equipoId, success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Equipos/getIntegrantes/${equipoId}`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response.json());
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static getAllMiembros(success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/EquiposSeleccion/get_all_miembros/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response.json());
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static getEmpleados(success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/EquiposSeleccion/getEmpleados/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response.json());
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static updateCalificacion(userDetalle, evaluacionUnica, success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
      usuarioId: userDetalle.uuid,
      evaluacionUnica: evaluacionUnica,
    };
    console.log(request);
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/CalifiacionDeEmpleados/update/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static addCertificacion(body, success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/CertificacionUsuario/add/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static updateCertificacion(body, success) {
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/CertificacionUsuario/update/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static getDataExpertise(success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Expertise/getData/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response.json());
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static getExpertiseDetail(expertise,success) {
    var request = {
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Expertise/getActividades/${expertise}`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response.json());
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static createExpertise(body,success){
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Expertise/createExpertise/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

  static asignarEmpleadosExpertise(body,success){
    var request = {
      ...body,
      public_key:
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
    };
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    fetch(`${baseURL}/Expertise/agregarAsignaciones/`, requestOptions)
    .then((response) => {
        if(response.ok)
            success(response);
        else
            response.json().then((data)=>{
                toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
            })
      })
  }

}
