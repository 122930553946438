import { Icon } from "@iconify/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect, useRef } from "react";
import { baseURL } from "../../../../App";
import { toast } from "react-toastify";
import { SplitButton } from 'primereact/splitbutton';
import Logo from "../../../../static/images/Logo.png";
import toolbar from "../../../../static/images/vector-toolbar-pdf.svg";
import locationIcon from "../../../../static/images/location-icon.svg";
import webIcon from "../../../../static/images/web-icon.svg";

const getTargetElement = () => document.getElementById('modalIncidentEdit');

export default function ModalUpdate({ setShowModalUpdate, uuid }) {
  const [id2, setId2] = useState('');
  const [client_name, setClientName] = useState('');
  const [client_phone, setClientPhone] = useState('');
  const [client_company, setClientCompany] = useState('');
  const [client_email, setClientEmail] = useState('');
  const [employee, setEmployeeState] = useState('');
  const [employee_phone, setEmployeePhone] = useState('');
  const [employee_email, setEmployeeEmail] = useState('');
  const [priority, setPriority] = useState(null);
  const [incidence_category, setIncidenceCategory] = useState(null);
  const [incidence_description, setIncidenceDecription] = useState('');
  const [incidence_impact, setIncidenceImpact] = useState('');

  const [incidence_actions, setIncidenceActions] = useState('');
  const [incidence_comments, setIncidenceComments] = useState('');

  const [incident_status, setIncidenceStatus] = useState({value:null, label:""});

  const [pdfStyleModal, setPdfStyleModal] = useState(false)
  
  const priorities = [
    { value: 1, label: "Alta"}, 
    { value: 2, label: "Media"}, 
    { value: 3, label: "Baja"},
  ];
  const incidence_categories = [
    { value: 1, label: "Disponibilidad"}, 
    { value: 2, label: "Profesionalismo"}, 
    { value: 3, label: "Calidad de trabajo"},
    { value: 4, label: "Atrasos"},
  ];

  const spliButtonOptions = [
    {
      label: "Cerrar Incidencia",
      icon: "",
      command: async () => {update({value:2, label:"Cerrada"}); }
    },
    {
      label: "Guardar",
      icon: "",
      command: () => { update({value:1, label:"Abierta"});  }
    }
  ]
  useEffect(() => {
    if(!uuid) return;

    fetch(`${baseURL}/incidents/${uuid}`, {
      method: "GET",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      setIncident(data)
    });
  },[uuid]);

  function setIncident(value)
  {
    setId2(value.id2);
    setClientName(value.client_name);
    setClientPhone(value.client_phone);
    setClientCompany(value.client_company);
    setClientEmail(value.client_email);
    setEmployeeState(value.employee_name);
    setEmployeePhone(value.employee_phone);
    setEmployeeEmail(value.employee_email);
    setPriority(value.priority);
    setIncidenceCategory(value.category);
    setIncidenceDecription(value.description);
    setIncidenceImpact(value.impact);
    setIncidenceActions(value.actions);
    setIncidenceComments(value.comments);
    setIncidenceStatus(value.status);
  }

  async function pdfDownload()
  {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    fetch(`${baseURL}/incidents/${uuid}/pdf/download/`, { method: 'POST', headers: headers, })
    .then(async res => ({
        filename: id2,
        blob: await res.blob()
    }))
    .then(resObj => {
      console.log(resObj);
      // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
      const newBlob = new Blob([resObj.blob], { type: 'application/pdf',  });
      // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, resObj.filename);
      } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click()
          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
      }
    })
    .catch((error) => {
        console.log('DOWNLOAD ERROR', error);
    });
  }

  function update(incidenceStatus = null)
  {
    if(incidenceStatus != null ){ setIncidenceStatus(incidenceStatus) }

    fetch(`${baseURL}/incidents/${uuid}/update/`, {
      method: "PUT",
      credentials: "include",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        actions: incidence_actions,
        comments: incidence_comments,
        status: incidenceStatus == null ? incident_status.value : incidenceStatus.value
      })
    }).then((response) => { return new Promise((resolve) => response.json().then((json) => resolve(json) )); }).then((data) => {
      if(data.code == 1){
        toast.success("Se actualizo la incidencia con éxito", { toastId: "incident-success", theme: "dark", position: toast.POSITION.TOP_RIGHT, });
        setShowModalUpdate(false);
      }
    });
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={pdfStyleModal == true ? 'modal-pdf' : 'modal-large'} id={"modalIncidentEdit"}>
        <div className="relative">
          <div className="close absolute pointier">
            <Icon icon={"ic:outline-file-download"} onClick={() => pdfDownload()} />
            <Icon icon={"ic:twotone-close"} onClick={() => setShowModalUpdate(false)} />
          </div>
          <div className="row hcenter w100 pad20 vcenter" style={{ background: "#000", color: "#fff" }} >
            <h2 className="row vcenter">Incidencia {id2}</h2>
          </div>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Información del cliente </p>
          <div className="line w25 white vcenter row"></div>
        </div>

        <div className="row w100 wrap fontSizeSm mb-5px">

          <span className="p-float-label flex-1" key={"group-client-name"}>
            <InputText id={"client_name"} value={client_name} onChange={(e) => setClientName(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Nombre del Cliente"}>{"Nombre del Cliente"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-client-phone"}>
            <InputText id={"client_phone"} value={client_phone} onChange={(e) => setClientPhone(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Teléfono"}>{"Teléfono"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-client-company-name"}>
            <InputText id={"client_company"} value={client_company} onChange={(e) => setClientCompany(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Empresa"}>{"Empresa"}</label>
          </span>

          <div class="flex-break"></div>

          <span className="p-float-label flex-1" key={"group-client-email"}>
            <InputText id={"client_email"} keyfilter="email" value={client_email} onChange={(e) => setClientEmail(e.target.value)} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Correo Electrónico"}>{"Correo Electrónico"}</label>
          </span>

          <div class="p-float-label flex-1"></div>
          <div class="p-float-label flex-1"></div>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Información del empleado </p>
          <div className="line w25 white vcenter row"></div>
        </div>

        <div className="row w100 wrap fontSizeSm mb-5px">
          <span className="p-float-label flex-1" key={"group-employee-name"}>
            <InputText  value={employee} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Nombre del empleado"}>{"Nombre del empleado"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-employee-phone"}>
            <InputText id={"employee_phone"} value={employee_phone} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Teléfono"}>{"Teléfono"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-employee-email"} style={ { marginLeft: "auto" } }>
            <InputText id={"employee_email"} value={employee_email} style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Correo Electrónico"}>{"Correo Electrónico"}</label>
          </span>
        </div>

        <div className="row w100 wrap fontSizeSm pad10 mtop25">
          <p className="row w100 fontSizeMd mb-5px bolder font-primary"> Detalle de la incidencia </p>
          <div className="line w25 white vcenter row"></div>
        </div>

        <div className="row w100 wrap fontSizeSm">
          <span className="p-float-label flex-1" key={"group-category-incidence"}>
            <Dropdown value={incidence_category} onChange={(e) => setIncidenceCategory(e.value)} options={incidence_categories} optionLabel="label" 
              placeholder="Select a City" className="w-full" style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Categoría de la Incidencia"}>{"Categoría de la Incidencia"}</label>
          </span>
          
          <span className="p-float-label flex-1" key={"group-incidence-description"}>
            <InputTextarea autoResize id={"incidence_description"} value={incidence_description} style={{ width: "100%", height: 40 }} rows={5} cols={30} onChange={(e) => setIncidenceDecription(e.target.value)} disabled/>
            <label htmlFor={"Descripción de la Incidencia"}>{"Descripción de la Incidencia"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-incidence-actions"}>
            <InputTextarea autoResize id={"incidence_actions"} value={incidence_actions} style={{ width: "100%", height: 40 }} rows={5} cols={30} 
              onChange={(e) => setIncidenceActions(e.target.value)} disabled={incident_status.value == 1 ? false : true }/>
            <label htmlFor={"Acciones tomadas"}>{"Acciones tomadas"}</label>
          </span>

          <div class="flex-break"></div>

          <span className="p-float-label flex-1" key={"group-priority"}>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorities} optionLabel="label" 
              placeholder="Select a City" className="w-full" style={{ width: "100%", height: 40 }} disabled/>
            <label htmlFor={"Prioridad"}>{"Prioridad"}</label>
          </span>

          <span className="p-float-label flex-1" key={"group-incidence-impact"}>
            <InputTextarea autoResize id={"incidence_impact"} value={incidence_impact} style={{ width: "100%", height: 40 }} rows={5} cols={30} onChange={(e) => setIncidenceImpact(e.target.value)} disabled/>
            <label htmlFor={"Impacto de la Incidencia"}>{"Impacto de la Incidencia"}</label>
          </span>


          <span className="p-float-label flex-1" key={"group-incidence-comments"}>
            <InputTextarea autoResize id={"incidence_comments"} value={incidence_comments} style={{ width: "100%", height: 40 }} rows={5} cols={30} 
              onChange={(e) => setIncidenceComments(e.target.value)} disabled={incident_status.value == 1 ? false : true }/>
            <label htmlFor={"Comentarios adicionales"}>{"Comentarios adicionales"}</label>
          </span>
        </div>

        <div className="row hcenter w100 pad20 vcenter">
          <SplitButton label={incident_status["label"]} icon="pi pi-save" onClick={ (e) => update({value:1, label:"Abierta"})} model={spliButtonOptions} style={{ height: 40 }}
            disabled={incident_status.value == 1 ? false : true }/>
        </div>
      </div>
    </>
  );
}