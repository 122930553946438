import { Navigate } from "react-router-dom";
import Admin from "./Admin";
import Empleado from "./Empleado";
import Aprobador from "./Aprobador";
import CapitalH from "./CapitalH";
import Tecnico from "./Tecnico";
import Tecnico2 from "./Tecnico2"
import AdminV from "./AdminV";
import CapitalH2 from "./CapitalH2";
import Empleado2 from "./Empleado2";
import PMO from "./PMO";
import "./../static/scss/main.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import { useEffect, useState } from "react";
import { URL } from "../App";
import AdminConcimientos from "./AdminConocimientos";
function pass(){}
export default function Main({ toast, user, Post, setUser, Get }) {
  if (!user) return <Navigate to="/login" />;

  /*
  var socket = new WebSocket(`wss://${URL}/ws/`);

  socket.onopen = function (e) {
    pass(e);
  };

  socket.onmessage = function (event) {
    pass(event);
    toast.success(event.data, { toastId: event.data, theme: "dark" });
  };

  socket.onclose = function (event) {
    if (event.wasClean) {
      pass(event)
    } else {
      // ej. El proceso del servidor se detuvo o la red está caída
      // event.code es usualmente 1006 en este caso
      pass(event);
      socket = new WebSocket(`wss://localhost:8000/ws/`);
    }
  };

  socket.onerror = function (error) {
    pass(error);
  };*/
  var socket = "";
  if (["Empleado","Personal Administrativo","Personal Contabilidad"].includes(user.rol.name))
    // return (<Empleado setUser={setUser} user={user} Post={Post} />);\
    return (<Empleado2 setUser={setUser} user={user} Post={Post} />);
  if (user.rol.name == "Aprobador")
    return (<Aprobador setUser={setUser} user={user} Post={Post} />);
  if (user.rol.name == "Capital Humano")
    // return (<CapitalH setUser={setUser} user={user} Post={Post} />);
    return (<CapitalH2 setUser={setUser} user={user} Post={Post} />);
  if (user.rol.name == "Tecnico")
    return (<Tecnico2 setUser={setUser} user={user} Post={Post}  />);
  if (user.rol.name == "admin")
    return (<AdminV setUser={setUser} user={user} Post={Post}  />);
  if (user.rol.name == "Gestor Conocimiento")
    return (<AdminConcimientos setUser={setUser} user={user} Post={Post}  />);
  if (user.rol.name == "PMO")
    return (<PMO setUser={setUser} user={user} Post={Post} />);
  return (
    <Admin user={user} setUser={setUser} socket={socket} Post={Post} Get={Get} />
  );
}
