import Slider from "./Slider";
import Zoom from "react-reveal/Zoom";
export default function Submenu({ menu_, module, setModule }) {
  return (
    <Zoom cascade>
      <div className="row hcenter gap_50 m_25px mxw800px box dark">
        <Slider menu_={menu_} module={module} setModule={setModule} />
      </div>
    </Zoom>
  );
}
