import { useEffect, useState } from "react";
import useFiltroDatos from "../../../../../Hooks/useFiltroDatos";
import { Services } from "../Services";
import SearchData from "../../../../Organism/Search";
import Reload from "../../../../Atoms/Reload";
import Loader from "../../../../Atoms/Loader";
import FileComponent from "../../../../Atoms/FileComponent";
import { Card } from "primereact/card";
import { Zoom } from "react-reveal";
export default function ProcesosComponent() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const { datosFiltrados, handleChangeFiltro, setDatosIniciales } =
        useFiltroDatos(data);
    useEffect(() => {
        setDatosIniciales(data);
    }, [data]);
    const getData = () => {
        setLoader(true);
        Services.post({}, (response) => {
            response.then((data) => {
                setData(data);
                setLoader(false);
            });
        }, "/proceso/get/");
    }
    useEffect(getData, []);

    return (
        <div className="w100 row hcenter">
            <div className="mxw1000px row hcenter wrap">
                <div className="row w100 mtop15">
                    <SearchData handleChangeFiltro={handleChangeFiltro} />
                    <Reload onClick={getData} />
                </div>
                {loader ? <Loader /> : <Card className="w100"><div className="row">
                    {datosFiltrados.length > 0 ? datosFiltrados.map((proceso) =>
                        <Zoom> <FileComponent file={proceso} /></Zoom>
                    ) : <div className="w100 row hcenter">
                        <h3>Archivos no encontrados</h3>
                    </div>}
                </div>
                </Card>}
            </div>
        </div>
    );
}