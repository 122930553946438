import { baseURL } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
export class ServiceMetas{

    static getPresupuestos(success) {
        var request = {
          public_key:
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
          private_key:
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
        };
        const requestOptions = {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        fetch(`${baseURL}/presupuesto/get/`, requestOptions)
        .then((response) => {
            if(response.ok)
                success(response.json());
            else
                response.json().then((data)=>{
                    toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
                })
          })
      }

      static createPresupuesto(body,success) {
        var request = {...body,
          public_key:
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
          private_key:
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
        };
        const requestOptions = {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        fetch(`${baseURL}/presupuesto/create/`, requestOptions)
        .then((response) => {
            if(response.ok)
                success(response.json());
            else
                response.json().then((data)=>{
                    toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
                })
          })
      }

      static updatePresupuesto(body,success) {
        var request = {...body,
          public_key:
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
          private_key:
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu",
        };
        const requestOptions = {
          method: "PUT",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        fetch(`${baseURL}/presupuesto/update/`, requestOptions)
        .then((response) => {
            if(response.ok)
                success(response.json());
            else
                response.json().then((data)=>{
                    toast.error(`${response.status} ${data.detail.message}`, { theme: "dark" });
                })
          })
      }
}