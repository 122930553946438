import { Dialog } from "primereact/dialog";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Actions from "../../../Organism/Actions";
import ModalForm from "../../../Organism/ModalForm";
import { Services } from "./Services";
import Grupo from "./Components/Grupos";
import ProcesosComponent from "./Components/Procesos";
export default function Procesos() {
  const [dinamicValues, setDibamicValues] = useState({ actividades: [] });
  const opciones = [
    {
      name: "Procesos",
      path: "Procesos",
      onClick: (opcion) => {
        setCurrentOption(opcion);
      },
      print: "Procesos",
      component: ()=><ProcesosComponent />,
      add: false,
      // Mauricio
      tipo: "tabla",
      //
      seccion: "8",
      modales: [
        {
          name: "Proceso",
          path: "Proceso",
          onClick: (opcion) => {
            setModalesAbiertos([...modalesAbiertos, opcion.name]);
          },
          print: "Agregar proceso",
          add: true,
          isModal: true,
          component: null,
          formData: {
            body: {
              name: "",
              description: "",
              base64:"",
              content_type:"",
              filename:""
            },
            endpoint: "",
            fields: [
              {
                type: "textField",
                name: "name",
                text: "Nombre del proceso",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "textField",
                name: "description",
                text: "Descripción del proceso",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "file",
                name: "base64",
                text: "PDF del proceso",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
           
              {
                type: "button",
                name: "submit",
                text: "Subir archivo",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: (body) => {
                  Services.post(body, (response) => {
                    toast.success("Archivo creado");
                    setModalesAbiertos(
                      modalesAbiertos.filter(
                        (modal_a) => modal_a !== "Procesos"
                      )
                    );
                  },"/proceso/create/");
                },
              },
            ],
          },
          tipo: "tabla",
          seccion: "1",
        },
      ],
      //
    },
    {
      name: "Grupos de acceso",
      path: "Grupos de acceso",
      onClick: (opcion) => {
        setCurrentOption(opcion);
      },
      print: "Grupos de acceso",
      component: ()=><Grupo />,
      add: false,
      // Mauricio
      tipo: "tabla",
      //
      seccion: "8",
      modales: [
        {
          name: "Grupos de acceso",
          path: "Grupos de acceso",
          onClick: (opcion) => {
            setModalesAbiertos([...modalesAbiertos, opcion.name]);
          },
          print: "Agregar grupo",
          add: true,
          isModal: true,
          component: null,
          formData: {
            body: {
              name: "",
              description: "",
              integrantes: [],
              files: []
            },
            endpoint: "",
            fields: [
              {
                type: "textField",
                name: "name",
                text: "Nombre del grupo",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: "textField",
                name: "description",
                text: "Descripción",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: () => {},
              },
              {
                type: 'multiSelect',
                name: "integrantes",
                text: "Integrantes",
                value: [],
                method: "/EquiposSeleccion/getEmpleados/",
                getByEndPont: true,
                onClick: () => {},
              },
              {
                type: 'multiSelect',
                name: "files",
                text: "Archivos",
                value: [],
                method: "/proceso/LOV/",
                getByEndPont: true,
                onClick: () => {},
              },
              {
                type: "button",
                name: "submit",
                text: "Crear grupo",
                value: "",
                method: "",
                getByEndPont: false,
                onClick: (body) => {
                  Services.post(body, (response) => {
                    toast.success("Grupo creado");
                    setModalesAbiertos(
                      modalesAbiertos.filter(
                        (modal_a) => modal_a !== "Grupos de acceso"
                      )
                    );
                  },"/accessGroup/create/");
                },
              },
            ],
          },
          tipo: "tabla",
          seccion: "1",
        },
      ],
      //
    },
    
  ];
  const [currentOption, setCurrentOption] = useState(opciones[0]);
  const [modalesAbiertos, setModalesAbiertos] = useState([]);

  useEffect(() => {
    for (const modal_no_abierto of currentOption.modales.filter(
      (modal) => !modalesAbiertos.includes(modal.name)
    )) {
      for (const field of modal_no_abierto.formData.fields) {
        var dinamicCP = dinamicValues;
        if (dinamicValues[field.name]) {
          dinamicCP[field.name] = field.value;
        }
        setDibamicValues(dinamicCP);
      }
    }
  }, [modalesAbiertos]);
  useEffect(() => {}, [currentOption]);
  return (
    <>
      <Actions
        currentOption={currentOption}
        opciones={opciones}
        modales={currentOption.modales}
      />
      {currentOption.component()}
      {currentOption.modales.map((modal) => (
        <Dialog
          key={modal.name}
          header={`${modal.add ? "Agregar " : ""}${modal.name}`}
          visible={modalesAbiertos.includes(modal.name)}
          onHide={() => {
            setModalesAbiertos(
              modalesAbiertos.filter((modal_a) => modal_a !== modal.name)
            );
          }}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <ModalForm modal={modal} dinamicValues={dinamicValues} />
        </Dialog>
      ))}
    </>
  );
}
