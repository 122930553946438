import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { FileUpload } from 'primereact/fileupload';
import RowPeriodo from "./Formulario/RowPeriodo";
import { ToastContainer, toast } from "react-toastify";
import { axios_instance, baseURL,USER } from "../../App";
import FirmaUploadDocumento from "./FirmaUploadDocumento";
export default function ModalDocumento({
  toggleModal,
  setToggleModal,
  uuid,
  Post,
  setUpdate,
  user
}) {
  const [loader, setLoader] = useState(true);
  const [changeF, setChangeF] = useState(true);
  const [updateFirma,setUpdateFirma]=useState(false)
  useEffect(()=>{},[changeF])

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div className="close absolute pointier">
            {/* <Icon
            style={{marginRight:10}}
              icon={"ic:outline-file-download"}
              onClick={() => {
                toast.loading("Generando responsiva", { toastId: "download",theme:"dark" });
                fetch(`${baseURL}/${uuid["path"]}/download/${uuid["uuid documento"]}`, {
                  credentials: "include",
                })
                  .then((resp) => resp.blob())
                  .then((blob) => {
                    toast.update("download", {
                      render: "Responsiva Generada",
                      type: "success",
                      isLoading: false,
                      autoClose: true,
                      toastId: "download",
                      theme:'dark'
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = "Responsiva.pdf";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    
                  })
                  .catch(() => {
                  toast.update("download", {
                    render: `Error al generar el reporte`,
                    type: "error",
                    isLoading: false,
                    autoClose: true,
                    toastId: `download`,
                  });});
              }}
            /> */}
            <Icon
              icon={"ic:twotone-close"}
              onClick={() => setToggleModal(false)}
            />
          </div>
          <div
            className="row hcenter w100 pad20 vcenter"
            style={{ background: "#000", color: "#fff" }}
          >
            <h2 className="row vcenter">Documento</h2>
          </div>
        </div>

        <div className="row w100 wrap pad20 hcenter fontSizeSm" style={{background:"white"}}>
          {updateFirma?
          <div className="row w100 hcenter pad15 vcenter">
          <ThreeCircles
            height="43"
            width="43"
            color="#0061FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
          : <iframe
            src={`${baseURL}/${uuid["path"]}/${uuid["path documento"]}/${uuid["uuid documento"]}#${changeF}`}
            frameborder="0"
            data={changeF}
            className="w100 v100"
            style={{ height: 500 }}
          ></iframe>}
         
          {user.rol.name!="Capital Humano" && user.rol.name != "admin" && uuid["Estatus"] != "Firmado" && (<FirmaUploadDocumento uuid={uuid} setChangeF={setChangeF} changeF={changeF} setToggleModal={setToggleModal} setUpdateFirma={setUpdateFirma} updateFirma={updateFirma} />)}
         

        </div>
      </div>
    </>
  );
}
