import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from 'primereact/tabview';
import ReactApexChart from "react-apexcharts";

const getUser = () => JSON.parse(localStorage.getItem('user'));

export default function Graphics({setLoader, Post}) {
  
  //Martin Isai Zpt Rms
  const [user, setUser] = useState(getUser());
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState("");
  const [dateGrafica, setDateGrafica] = useState(null);
  const [barChart, setBarChart] = useState(null);
  const [pieChart, setPieChart] = useState(null);
  const [radarChart, setRadarChart] = useState(null);
  const [week, setWeek] = useState(null);
  const [optionWeeks, setOptionWeeks] = useState([]);
  
  const namesRol = [
    {"type" : "module", "nombre": "Tipo de Hora", "tieneFiltro":true, "filtro":"Tipo de Hora"},
    {"type" : "module", "nombre": "Incidencias", "tieneFiltro":true, "filtro":"Incidencias"},
    // {"type" : "module", "nombre": "Cump. Semanal", "tieneFiltro":true, "filtro":"Cump. Semanal"},
    {"type" : "module", "nombre": "Calidad Actividades", "tieneFiltro":true, "filtro":"Calidad Actividades"},
    // {"type" : "module", "nombre": "Satisfacción Clte.", "tieneFiltro":true, "filtro":"Satisfacción Cliente"},
  ]
  const [rolGrafica, setRolGrafica] = useState(namesRol[0]); 

  useEffect(() => {
      // Verificar si es una instancia de la clase Date
      if (dateGrafica instanceof Date && !isNaN(dateGrafica)) {
        var mes = dateGrafica.getMonth()
        var anio = dateGrafica.getFullYear()
        var fechaString = mes + "/" + anio
      } else {
        var fechaString = ""
      }
  
      var params = new FormData();
      params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
      params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
      params.append("tiene_filtro",rolGrafica.tieneFiltro);
      params.append("nombre_rol",rolGrafica.filtro);
      params.append("colaborador_id",user.uuid);
      params.append("fecha", fechaString);
      params.append("week", week);
      params.append("type", rolGrafica.type);
  
      Post(
        `hoja_actividades/graficos-pruebas/get/`,
        (response) => {
          setLoader(false);
          setUsuarios(response.data.usuarios);
          setBarChart(response.data.chart_bar);
          setPieChart(response.data.chart_pie);
          setRadarChart(response.data.chart_radar);
          setOptionWeeks(response.data.periodos);
        },
        (error) => {
          setLoader(false);
        },
        false,
        "Obteniendo datos",
        params
      );
  }, [selectedUsuario, rolGrafica, dateGrafica, week]);

  return (
  <>
    <div className="row w100 hcenter">
      <div className="mxw1000px row vcenter mtop25" style={{flexWrap: "wrap"}}>
        {namesRol.map((r) => (
          <div className={`btn_2 row vcenter pointier relative ${rolGrafica.nombre === r.nombre  ? "active" : ""}`}
            onClick={() => { setRolGrafica(r) }}>
            {r.nombre}
          </div>
        ))}
      </div>
    </div>    
    <div className="row w100 hcenter">
      <div className="mxw1000px row vcenter mtop25">
        <Calendar value={dateGrafica} onChange={(e) => setDateGrafica(e.value)} view="month" dateFormat="mm/yy" placeholder="Periodo" />
          
        <Dropdown value={week} onChange={(e) => setWeek(e.value)}
          options={optionWeeks} optionLabel="label" placeholder="Semana" className="w-full md:w-14rem" style={{marginLeft:"20px"}} />
  
        <div className={`btn_2 row vcenter pointier relative`} onClick={() => { setDateGrafica(""); setWeek(null); }} > RESETEAR FECHA</div>
      </div>
    </div>

    {barChart && pieChart && radarChart &&(
    <div className="row w100 hcenter mtop25">
      <TabView>
          <TabPanel header="Barras">
            <div className={`row w100 hcenter mtop25`}>
              <ReactApexChart options={barChart.options} series={barChart.serie} type="bar" height={500} width={1000} /> 
            </div> 
          </TabPanel>
          <TabPanel header="Pie">
            <div className={`row w100 hcenter mtop25`}>
              <ReactApexChart options={pieChart.options} series={pieChart.serie} type="pie" height={500} width={1000} /> 
            </div> 
          </TabPanel>
          <TabPanel header="Treemap">
            <div className={`row w100 hcenter mtop25`}>
              <ReactApexChart options={radarChart.options} series={radarChart.serie} type="treemap" height={500} width={1000} /> 
            </div> 
          </TabPanel>
      </TabView>
    </div>
    )} 

  </>
  )
}