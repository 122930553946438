import { FileUpload } from "primereact/fileupload";
import { ToastContainer, toast } from "react-toastify";
import { axios_instance, baseURL } from "../../App";
import { Post } from "../../App";

import { Button } from 'primereact/button';

import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; 
        
        

export default function FirmaUploadDocumento({
  uuid,
  setChangeF,
  changeF,
  setUpdateFirma,
  setToggleModal,
  updateFirma,
}) {

  var imagen = ""

  const accept = () => {
    var data = new FormData();
    setUpdateFirma(true);
    data.append("uuid", uuid["uuid documento"]);

    data.append(
      "public_key",
      "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
    );
    data.append(
      "private_key",
      "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    );

    Post(
      `${uuid["path"]}/confirmar_firma/`,
      (response) => {
        setUpdateFirma(false);
      },
      (error) => {
        setUpdateFirma(false);
      },
      true,
      "Subiendo firma",
      data,
      true
    );
    setToggleModal(false)
  };

const reject = () => {
};

const confirm1 = (event) => {
    confirmPopup({
        target: event.currentTarget,
        message: '¿Deseas guardar la firma? Una vez confirmado no se podrá modificar',
        acceptLabel: "Confirmar",
        rejectLabel:"Cancelar",
        icon: 'pi pi-exclamation-triangle',
        accept,
        reject
    });
};



  return (
    <div className="" style={{ marginTop: 20 }}>
      {/* <FileUpload
        mode="basic"
        accept="image/*"
        maxFileSize={3000000}
        onUpload={() => {}}
        onSelect={(e) => {
          var data = new FormData();
          setUpdateFirma(true);
          data.append("firma", e.files[0]);
          data.append("uuid", uuid["uuid documento"]);

          data.append(
            "public_key",
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
          );
          data.append(
            "private_key",
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
          );

          Post(
            `${uuid["path"]}/upload_firma/`,
            (response) => {
              setUpdateFirma(false);
            },
            (error) => {
              setUpdateFirma(false);
            },
            true,
            "Subiendo firma",
            data,
            true
          );
        }}
        chooseLabel="Seleccionar firma"
      /> */}
      {/* <FileUpload
        accept="image/*"
        maxFileSize={3000000}
        onSelect={(e) => {
          imagen = e.files[0];
        }}
        onBeforeUpload={(e) => {
          var data = new FormData();
          setUpdateFirma(true);
          // data.append("firma", e.files[0]);
          data.append("firma", imagen);
          data.append("uuid", uuid["uuid documento"]);

          data.append(
            "public_key",
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
          );
          data.append(
            "private_key",
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
          );

          Post(
            `${uuid["path"]}/upload_firma/`,
            (response) => {
              setUpdateFirma(false);
            },
            (error) => {
              setUpdateFirma(false);
            },
            true,
            "Subiendo firma",
            data,
            true
          );
        }}
        chooseLabel="Seleccionar firma" 
        uploadLabel="Guardar"
        cancelLabel="Cancelar"
      /> */}
      <FileUpload
        mode="basic"
        accept="image/*"
        maxFileSize={3000000}
        onUpload={() => {}}
        onSelect={(e) => {
          var data = new FormData();
          setUpdateFirma(true);
          data.append("firma", e.files[0]);
          data.append("uuid", uuid["uuid documento"]);

          data.append(
            "public_key",
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
          );
          data.append(
            "private_key",
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
          );

          Post(
            `${uuid["path"]}/upload_firma/`,
            (response) => {
              setUpdateFirma(false);
            },
            (error) => {
              setUpdateFirma(false);
            },
            true,
            "Subiendo firma",
            data,
            true
          );
        }}
        chooseLabel="Seleccionar firma"
      />
      {/* <Button label="Guardar" severity="success" onClick={() => {
          var data = new FormData();
          setUpdateFirma(true);
          data.append("uuid", uuid["uuid documento"]);

          data.append(
            "public_key",
            "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
          );
          data.append(
            "private_key",
            "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
          );

          Post(
            `${uuid["path"]}/confirmar_firma/`,
            (response) => {
              setUpdateFirma(false);
            },
            (error) => {
              setUpdateFirma(false);
            },
            true,
            "Subiendo firma",
            data,
            true
          );
        }}/> */}

      <ConfirmPopup />
      <div style={{
        marginTop: '10px',
        alignItems: 'center'
      }}>
            <Button onClick={confirm1} severity="success" icon="pi pi-check" label="Guardar"></Button>
      </div>
    </div>
  );
}
