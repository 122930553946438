import Menu from "./Menu"
import Submenu from "./Submenu"
export default function Inicio({user,menu_,setMenu,setModule,module}){
    return(
        <div>
        <div className="row hcenter">
          <Menu menu_={menu_} user={user} setMenu={setMenu} setModule={setModule} />
        </div>

        <div className="row hcenter">
          <Submenu menu_={menu_} setModule={setModule} module={module} />
        </div>
        </div>
    )
}